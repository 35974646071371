import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import { Button } from "../Button";
import { keyCodes } from "../defines/keyCodes";
import { Digit } from "../Digit";
import { FadingDot } from "../FadingDot";
import { Icon } from "../Icon";
import styles from "./NumberSelector.module.scss";

const DECREMENT = -1;
const DEFAULT_FONT_SIZE = 30;
const DOT_ANIMATION_DURATION = undefined;
const DOT_ANIMATION_DURATION_IMMEDIATE = 0.0001;
const INCREMENT = 1;
const OFF = 0;
const ON = 1;

/**
 * A component that allows the user to enter a numeric value by incrementing / decrementing the value
 * using plus and minus (+ / -) buttons.
 */

export const NumberSelector = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue = 0,
    disableDecrement = false,
    disableIncrement = false,
    maximumValue = 9,
    minimumValue = 0,
    name,
    onBlur,
    onChange,
    onFocus,
    value,
  } = props;
  const [focusedButton, setFocusedButton] = useState(OFF);
  const [hover, setHover] = useState(false);
  const [on, setOn] = useState(OFF);
  const containerRef = ref || useRef();
  const decrementRef = useRef();
  const incrementRef = useRef();

  return (
    <div {...getProps()}>
      <Button {...getButtonProps(DECREMENT)}>
        <FadingDot {...getDotProps(DECREMENT)} />
        <Icon {...getIconProps(DECREMENT)} />
      </Button>
      <Digit {...getDigitProps()} />
      <span aria-live="polite" className={globalRules.hiddenFromScreen}>
        {`${props["aria-label-value"]} ${getValue()}`}
      </span>
      <Button {...getButtonProps(INCREMENT)}>
        <FadingDot {...getDotProps(INCREMENT)} />
        <Icon {...getIconProps(INCREMENT)} />
      </Button>
    </div>
  );

  function getProps() {
    return {
      className: classNames(className, styles.numberSelectorContainer),
      onBlur: handleBlur,
      onFocus: handleFocus,
      onKeyDown: handleKeyDown,
      onMouseEnter: () => setHover(true),
      onMouseLeave: () => setHover(false),
      ref: containerRef,
      tabIndex: 0,
    };
  }

  function getButtonProps(direction) {
    return {
      animate: false,
      "aria-label":
        props[direction === DECREMENT ? "aria-label-decrement" : "aria-label-increment"],
      className: getButtonClass(direction),
      clickFeedback: "none",
      defaultValue,
      disabled: isButtonDisabled(direction),
      name,
      onClick: () => handleClick(direction),
      onFocus: () => containerRef.current.focus(),
      ref: direction === DECREMENT ? decrementRef : incrementRef,
      styleType: "no-style",
      tabIndex: -1,
    };
  }

  function getDotProps(direction) {
    return {
      className: classNames(styles.feedback, { [styles.none]: on !== OFF }),
      color: "number-selector-feedback",
      duration: on === direction ? DOT_ANIMATION_DURATION : DOT_ANIMATION_DURATION_IMMEDIATE,
      fullWidth: true,
      onTransformationEnd: on === direction ? handleTransformationEnd : undefined,
      to: on === direction ? "FADE-GROW" : "SHOW-SHRINK",
    };
  }

  function getIconProps(direction) {
    const isDisabled = isButtonDisabled(direction);

    return {
      className: classNames(styles.icon, { [styles.disabled]: isDisabled }),
      color: isDisabled ? "body-disabled" : "link",
      name: direction === DECREMENT ? "Minus" : "Plus",
      size: "size20",
      transparentBorder: true,
    };
  }

  function getDigitProps() {
    return {
      className: classNames(styles.numberCount, {
        [styles.disabled]: disableDecrement && disableIncrement,
      }),
      fontSize: getFontSize(),
      maxNumber: maximumValue,
      number: getValue(),
    };
  }

  function getButtonClass(direction) {
    return classNames(styles.numberSelectorButton, {
      [styles.disabled]: isButtonDisabled(direction),
      [styles.focus]: focusedButton !== OFF,
      [styles.hover]: hover,
    });
  }

  function handleBlur() {
    setFocusedButton(OFF);
    setHover(false);
    onBlur?.();
  }

  function handleFocus() {
    setFocusedButton(ON);
    onFocus?.();
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_END || key === keyCodes.KEY_PAGE_DOWN) {
      processEnd();
    } else if (key === keyCodes.KEY_HOME || key === keyCodes.KEY_PAGE_UP) {
      processHome();
    } else if (key === keyCodes.KEY_DOWN || key === keyCodes.KEY_LEFT || key === "-") {
      processDecrement(event);
    } else if (key === keyCodes.KEY_UP || key === keyCodes.KEY_RIGHT || key === "+") {
      processIncrement(event);
    } else if (key >= "0" && key <= `${maximumValue}`) {
      processDigit(key);
    }
  }

  function processEnd() {
    onChange(maximumValue);
    setOn(INCREMENT);
  }

  function processHome() {
    onChange(minimumValue);
    setOn(DECREMENT);
  }

  function processDecrement(event) {
    event.preventDefault();
    event.stopPropagation();

    if (getValue() > minimumValue) {
      updateValue(DECREMENT);
    }
  }

  function processIncrement(event) {
    event.preventDefault();
    event.stopPropagation();

    if (getValue() < maximumValue) {
      updateValue(INCREMENT);
    }
  }

  function processDigit(key) {
    const newValue = parseInt(key);

    if (newValue !== value) {
      onChange(newValue);
    }
  }

  function handleClick(direction) {
    updateValue(direction);
  }

  function handleTransformationEnd() {
    setOn(OFF);
  }

  function isButtonDisabled(direction) {
    const newValue = getValue();

    return direction === DECREMENT
      ? disableDecrement || newValue <= minimumValue
      : disableIncrement || newValue >= maximumValue;
  }

  function updateValue(direction) {
    const newValue = getValue() + direction;

    onChange(newValue);
    setOn(direction);
  }

  function getValue() {
    return value ? value : defaultValue;
  }

  function getFontSize() {
    return parseInt(getCustomValue("number-selector-font-size", DEFAULT_FONT_SIZE));
  }

  function getCustomValue(propertyName, customDefaultValue) {
    return (
      window.getComputedStyle(document.body).getPropertyValue(`--${propertyName}`) ||
      customDefaultValue
    );
  }
});

NumberSelector.propTypes = {
  /** aria-label text to provide accessibility description for decrement button. */
  "aria-label-decrement": PropTypes.string.isRequired,

  /** aria-label text to provide accessibility description for increment button. */
  "aria-label-increment": PropTypes.string.isRequired,

  /** aria-label text to be read as the value is decremented/incremented. */
  "aria-label-value": PropTypes.string.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Optional initial default value for number selector. */
  defaultValue: PropTypes.number,

  /**
   * Indicates if the NumberSelector should accept events to decrement the value, and whether disabled
   * styling should be applied to the decrement button. If both decrement and increment buttons are
   * disabled, the digit will also show disabled styling.
   */
  disabledDecrement: PropTypes.bool,

  /**
   * Indicates if the NumberSelector should accept events to increment the value, and whether disabled
   * styling should be applied to the increment button. If both decrement and increment buttons are
   * disabled, the digit will also show disabled styling.
   */
  disabledIncrement: PropTypes.bool,

  /**
   * The maximum number that NumberSelector will allow. NumberSelector only allows for single
   * digits, so the maximum value cannot be greater than nine. Once reached, the increment button
   * will become disabled.
   */
  maximumValue: PropTypes.number,

  /**
   * The minimum number that the number selector will allow. Default is 0.
   * Once reached the decrement button will become disabled.
   */
  minimumValue: PropTypes.number,

  /** Name given to number selector when form is submitted. */
  name: PropTypes.string,

  /** A function that updates the value when a button "group" receives focus. */
  onBlur: PropTypes.func,

  /** A function that updates the value when a value change is made. */
  onChange: PropTypes.func.isRequired,

  /** A function that updates the value when a button "group" loses focus. */
  onFocus: PropTypes.func,

  /** The value that will be displayed and modified. */
  value: PropTypes.number,
};

NumberSelector.displayName = "NumberSelector";

/* eslint-disable sort-keys */
export default {
  light: {
    // accordion
    "primary-accordion-label": "primary-500",
    "primary-accordion-label-background": "neutral-900",
    "primary-accordion-label-border": "neutral-800",
    "primary-accordion-label-focus": "neutral-700",
    "primary-accordion-label-border-focus": "primary-500",

    "secondary-accordion-label": "blue-3",
    "secondary-accordion-label-background": "white",
    "secondary-accordion-label-border": "white",
    "secondary-accordion-label-border2": "gray-3",
    "secondary-accordion-label-focus": "gray-2",
    "secondary-accordion-label-border-focus": "blue-2",

    "tertiary-accordion-label": "blue-1",
    "tertiary-accordion-label-background": "white",
    "tertiary-accordion-label-background-focus": "white",
    "tertiary-accordion-label-border": "gray-4",
    "tertiary-accordion-label-focus": "blue-2",
    "tertiary-accordion-label-border-focus": "blue-2",

    // theme / semantic
    "theme-black": "black",
    "theme-caution": "yellow-1",
    "theme-danger": "red-1",
    "theme-disabled": "gray-1",
    "theme-error": "red-1",
    "theme-information": "blue-1-light",
    "theme-information-foreground": "blue-3",
    "theme-neutral": "gray-4",
    "theme-primary": "blue-1",
    "theme-secondary": "gray-3",
    "theme-success": "green-1",
    "theme-tertiary": "gray-2",
    "theme-transparent": "transparent",
    "theme-warning": "yellow-1",
    "theme-white": "white",

    // theme / illustration
    "theme-height-large": "4rem",
    "theme-height-medium": "3rem",
    "theme-height-small": "2rem",
    "theme-height-xlarge": "6rem",
    "theme-height-xsmall": "1.5rem",
    "theme-border-large": "10px",
    "theme-border-medium": "4px",
    "theme-border-small": "2px",
    "theme-border-xlarge": "6px",
    "theme-border-xsmall": "1px",
    "theme-radius-large": "2rem",
    "theme-radius-medium": "0.5rem",
    "theme-radius-small": "2px",
    "theme-radius-xlarge": "4rem",
    "theme-radius-xsmall": "1px",

    // theme / font
    "theme-font-large": 42,
    "theme-font-medium": 30,
    "theme-font-small": 16,
    "theme-font-xlarge": 115,
    "theme-font-xsmall": 12,

    // banner
    "banner-button-background-focus": "gray-2",
    "banner-button-content": "blue-2",
    "banner-feedback": "gray-4",

    // body
    "body-content": "blue-4",
    "body-disabled": "gray-3",
    "body-disabled-background": "gray-1",
    "body-error": "red-1",
    "body-hover": "gray-1",
    "body-medium": "gray-5",
    "body-primary": "blue-1",
    "body-secondary": "blue-2",
    "body-separator": "gray-3",
    "body-tertiary": "gray-4",

    // button
    "primary-button-background": "yellow-1",
    "primary-button-background-focus": "blue-1",
    "primary-button-border": "yellow-1",
    "primary-button-border-focus": "blue-3",
    "primary-button-foreground": "blue-3",
    "primary-button-loading": "blue-3",
    "primary-button-feedback": "blue-1",

    "secondary-button-background-focus": "rgba(17,27,64,0.2)",
    "secondary-button-loading": "blue-3",
    "secondary-button-feedback": "blue-3",

    "tertiary-button-loading": "white",

    "light-button-foreground": "white",
    "light-button-loading": "blue-3",
    "light-button-feedback": "white",

    "danger-button-loading": "white",
    "danger-button-feedback": "white",

    // caption
    "caption-background": "white",
    "caption-border": "blue-2",
    "caption-shadow": "rgba(0, 0, 0, 0.15)",

    // caption pointer
    "caption-pointer-background": "white",
    "caption-pointer-border": "blue-1",
    "caption-pointer-shadow": "rgb(0 0 0 / 0.25)",

    // checkbox
    "checkbox-background": "white",
    "checkbox-border": "blue-1",
    "checkbox-content": "blue-3",
    "checkbox-disabled": "gray-2",
    "checkbox-focus": "blue-3",
    "checkbox-foreground": "white",
    "checkbox-selected": "blue-1",
    "checkbox-underline-focus": "blue-1",
    "checkbox-underline-hover": "blue-2",

    // check button
    "check-button-background": "white",
    "check-button-background-focus": "blue-3-light",
    "check-button-background-selected-focus": "blue-1",
    "check-button-border": "blue-3",
    "check-button-foreground": "blue-3",
    "check-button-background-icon": "white",
    "check-button-foreground-icon": "blue-2",
    "check-button-background-selected": "blue-2",
    "check-button-foreground-selected": "gray-1",

    // dialog
    "dialog-background": "white",
    "dialog-border": "blue-2",

    // dropdown
    dropdown: "blue-3",
    "dropdown-arrow": "blue-1",
    "dropdown-arrow-focus": "blue-4",
    "dropdown-mask": "black",
    "dropdown-disabled": "gray-3",
    "dropdown-selection-background": "blue-3-light",

    // footer
    "footer-content": "blue-1",

    // flight status
    "flight-status-canceled": "yellow-1",
    "flight-status-delayed": "white",
    "flight-status-on-time": "green-1",

    // form control
    "form-control-caption": "gray-4",
    "form-control-error": "red-1",
    "form-control-label": "blue-3",

    // global header
    "primary-header-background": "white",
    "primary-header-heading-icon": "gray-4",
    "primary-header-logo": "blue-1",
    "primary-header-logo-light": "white",
    "primary-header-search": "blue-1",
    "primary-header-search-open": "white",

    "secondary-header-border": "blue-1",
    "secondary-header-logo": "white",
    "secondary-header-logo-section-background": "blue-1",
    "secondary-header-title-section-background": "blue-2",
    "secondary-header-options-background": "white",

    // heading
    heading: "blue-3",
    "heading-light": "white",

    // icon
    "icon-border-transparent": "transparent",

    // input
    "input-background": "white",
    "input-disabled": "neutral-300",
    "input-error": "red-1",
    "input-foreground": "blue-3",
    "input-icon-disabled": "gray-2",
    "input-placeholder": "gray-3",
    "input-selection": "blue-3-light",
    "primary-input-border": "gray-3",
    "primary-input-top-border": "gray-4",
    "primary-input-focus": "blue-4",
    "secondary-input-border-bottom": "yellow-1",

    // link
    link: "blue-1",
    "link-light-focus": "white",

    "list-scroller-border": "blue-1",
    "list-scroller-border-focus": "blue-3",
    "list-scroller-button-background": "white",
    "list-scroller-button-background-focus": "gray-2",
    "list-scroller-button-content": "blue-2",
    "list-scroller-feedback": "gray-4",

    // message
    "message-background": "white",
    "message-caution": "yellow-1",
    "message-error": "red-1",
    "message-information": "blue-1",
    "message-success": "green-1",
    "message-warning": "blue-1",

    // number selector
    "number-selector-font-size": 30,
    "number-selector-border": "blue-1",
    "number-selector-border-disabled": "gray-3",
    "number-selector-border-focus": "blue-3",
    "number-selector-button-background": "white",
    "number-selector-button-background-focus": "primary-900",
    "number-selector-button-content": "blue-1",
    "number-selector-disabled": "gray-3",
    "number-selector-feedback": "primary-700",

    // password
    "password-validation-message-error": "red-1",

    // progress bar
    "progress-bar-background": "#d8d8d8",
    "progress-bar-content-primary": "blue-3",
    "progress-bar-content-secondary": "#0076a5",
    "progress-bar-label": "gray-4",

    // radio button
    "radio-button-background-focus-secondary": "primary-950",
    "radio-button-background-secondary": "neutral-900",
    "radio-button-border": "blue-1",
    "radio-button-border-focus-secondary": "primary-200",
    "radio-button-border-secondary": "neutral-500",
    "radio-button-content": "blue-3",
    "radio-button-focus": "blue-3",
    "radio-button-selected": "blue-1",
    "radio-button-underline-focus": "blue-1",
    "radio-button-underline-hover": "blue-2",

    // shimmer
    "shimmer-background": "gray-2",
    "shimmer-streak": "white",

    // stops
    "stops-nonstop-background": "yellow-1",
    "stops-nonstop-foreground": "blue-3",
    "stops-stops-background": "gray-2",
    "stops-stops-foreground": "blue-3",

    // tabbed area
    "tabbed-area-background": "white",
    "tabbed-area-border": "gray-3",

    // toggle
    "toggle-background": "gray-2",
    "toggle-background-checked": "green-1",
    "toggle-border": "gray-2",
    "toggle-border-focus": "blue-3",
    "toggle-knob": "blue-1",
    "toggle-knob-checked": "white",
    "toggle-knob-shadow": "gray-5",
    "toggle-knob-shadow-off": "rgba(74, 102, 204, 0.66)",

    // toggle button
    "toggle-button-background": "gray-1",
    "toggle-button-background-selected": "gray-3",
    "toggle-button-border": "gray-2",
    "toggle-button-border-selected": "blue-1",
    "toggle-button-focus": "blue-1",
    "toggle-button-foreground": "blue-3",
    "toggle-button-foreground-selected": "blue-1",

    // vertical list
    "vertical-list-focus-background": "primary-800",
    "vertical-list-hover-background": "rgba(221, 231, 255, 0.75)",

    // SPA
    "spa-primary-background": "white",
    "spa-primary-border": "blue-1",
    "spa-secondary-background": "gray-1",
    "spa-secondary-border": "gray-2",
    "spa-tertiary-background": "gray-2",
    "spa-tertiary-border": "gray-3",

    // Disabled color definitions
    "standard-disabled-background": "gray-3",
    "standard-disabled-foreground": "gray-1",

    // SPA - flight status
    "flight-status-details-equipment-background": "gray-2",
    "flight-status-find-reverse-button": "blue-1",
    "flight-status-find-reverse-button-background": "gray-2",
    "flight-status-summary-background": "white",
    "flight-status-summary-details-option": "blue-1",

    // badge theming
    "primary-badge-active-text": "blue-4",
    "primary-badge-background": "yellow-1",
    "primary-badge-disabled": "gray-4",
    "primary-badge-disabled-text": "white",

    // confirmation number
    "confirmation-number-text": "green-1",

    // flight duration
    "itinerary-flight-duration-primary": "black",
    "itinerary-flight-duration-secondary": "blue-1",

    // flight invalid
    "itinerary-flight-invalid": "gray-4",

    // stop duration
    "stop-duration-line": "gray-3",
    "stop-duration-primary": "gray-4",
  },
  dark: {
    // accordion
    "primary-accordion-label": "gray-1",
    "primary-accordion-label-background": "blue-2",
    "primary-accordion-label-border": "blue-1",
    "primary-accordion-label-background-focus": "blue-1",
    "primary-accordion-label-border-focus": "gray-1",

    "secondary-accordion-label": "gray-1",
    "secondary-accordion-label-background": "blue-2",
    "secondary-accordion-label-border": "blue-1",
    "secondary-accordion-label-border2": "blue-2",
    "secondary-accordion-label-focus": "blue-1",
    "secondary-accordion-label-border-focus": "blue-2",

    // autocomplete
    "autocomplete-border": "gray-3",

    // body
    "body-content": "gray-1",
    "body-disabled": "gray-3",
    "body-disabled-background": "gray-1",
    "body-error": "red-2",
    "body-hover": "gray-4",
    "body-medium": "gray-2",
    "body-primary": "white",
    "body-secondary": "gray-2",
    "body-separator": "gray-1",
    "body-tertiary": "gray-3",

    // button
    "primary-button-background": "yellow-1",
    "primary-button-background-focus": "yellow-1",
    "primary-button-border": "yellow-1",
    "primary-button-border-focus": "gray-1",
    "primary-button-foreground": "blue-3",
    "primary-button-foreground-focus": "blue-3",
    "primary-button-loading": "blue-3",
    "primary-button-ripple": "blue-3",

    "secondary-button-background-focus": "rgba(17,27,64,0.2)",
    "secondary-button-loading": "blue-3",
    "secondary-button-ripple": "blue-2",

    "light-button-foreground": "white",
    "light-button-loading": "blue-3",
    "light-button-ripple": "blue-2",

    "danger-button-loading": "blue-3",
    "danger-button-ripple": "white",

    // caption
    "caption-background": "blue-3",
    "caption-border": "gray-3",
    "caption-shadow": "rgba(0, 0, 0, 0.15)",

    // caption pointer
    "caption-pointer-background": "blue-3",
    "caption-pointer-border": "blue-1",
    "caption-pointer-shadow": "gray-3",

    // checkbox
    "checkbox-background": "blue-2",
    "checkbox-border": "white",
    "checkbox-content": "white",
    "checkbox-focus": "white",
    "checkbox-foreground": "white",
    "checkbox-selected": "blue-1",
    "checkbox-underline-focus": "blue-1-light",
    "checkbox-underline-hover": "blue-2",

    // check button
    "check-button-background": "blue-3",
    "check-button-background-focus": "blue-3-light",
    "check-button-background-selected-focus": "blue-1",
    "check-button-border": "white",
    "check-button-foreground": "white",
    "check-button-background-icon": "gray-1",
    "check-button-foreground-icon": "blue-2",
    "check-button-background-selected": "blue-1",
    "check-button-foreground-selected": "gray-1",

    // dialog
    "dialog-background": "rgba(17,27,64,0.2)",
    "dialog-border": "blue-2",

    // dropdown
    dropdown: "white",
    "dropdown-disabled": "gray-2",
    "dropdown-selection-background": "blue-2",
    "dropdown-arrow-focus": "blue-4",
    "dropdown-arrow": "white",

    // flight status
    "flight-status-canceled": "yellow-1",
    "flight-status-delayed": "white",
    "flight-status-on-time": "green-1",

    // footer
    "footer-content": "white",

    // form control
    "form-control-caption": "white",
    "form-control-error": "red-2",
    "form-control-label": "gray-1",

    // global header
    "primary-header-background": "blue-2",
    "primary-header-heading-icon": "blue-1-light",
    "primary-header-logo": "white",
    "primary-header-logo-light": "white",
    "primary-header-search": "white",
    "primary-header-search-open": "white",

    "secondary-header-border": "gray-2",
    "secondary-header-logo": "white",
    "secondary-header-logo-section-background": "blue-1",
    "secondary-header-title-section-background": "blue-2",
    "secondary-header-options-background": "blue-3",

    // heading
    heading: "white",
    "heading-light": "white",

    // input
    "input-background": "blue-2",
    "input-error": "red-2",
    "input-foreground": "white",
    "input-placeholder": "gray-3",
    "input-selection": "blue-3-light",
    "primary-input-border": "blue-2",
    "primary-input-top-border": "blue-2",
    "primary-input-focus": "blue-4",
    "secondary-input-border-bottom": "yellow-1",

    // link
    link: "blue-3-light",
    "link-light-focus": "blue-3-light",

    // message
    "message-background": "black",
    "message-caution": "yellow-1",
    "message-error": "red-1",
    "message-information": "blue-1",
    "message-success": "green-1",
    "message-warning": "blue-1",

    // password
    "password-validation-message-error": "red-1",

    // radio button
    "radio-button-border": "white",
    "radio-button-content": "white",
    "radio-button-focus": "white",
    "radio-button-selected": "blue-1-light",
    "radio-button-underline-focus": "blue-1-light",
    "radio-button-underline-hover": "blue-1-light",

    // shimmer
    "shimmer-background": "blue-2",
    "shimmer-streak": "white",

    // stop
    "stops-nonstop-background": "yellow-1",
    "stops-nonstop-foreground": "blue-3",
    "stops-stops-background": "blue-2",
    "stops-stops-foreground": "white",

    // tabbed area
    "tabbed-area-background": "blue-4",
    "tabbed-area-border": "blue-2",

    // toggle button
    "toggle-button-background": "blue-4",
    "toggle-button-background-selected": "blue-2",
    "toggle-button-border": "blue-4",
    "toggle-button-border-selected": "gray-1",
    "toggle-button-focus": "gray-1",
    "toggle-button-foreground": "white",
    "toggle-button-foreground-selected": "white",

    // SPA
    "spa-primary-background": "blue-3",
    "spa-primary-border": "blue-2",
    "spa-secondary-primary-border": "blue-1",
    "spa-secondary-background": "blue-3",
    "spa-secondary-border": "blue-1",
    "spa-tertiary-background": "blue-4",
    "spa-tertiary-border": "blue-1",

    // SPA - flight status
    "flight-status-details-equipment-background": "blue-1",
    "flight-status-find-reverse-button": "white",
    "flight-status-find-reverse-button-background": "blue-1",
    "flight-status-summary-background": "blue-2",
    "flight-status-summary-details-option": "yellow-1",

    // Disabled color definitions
    "standard-disabled-background": "gray-4",
    "standard-disabled-foreground": "gray-2",
  },
};

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { Area } from "../Area";
import { ButtonGroup } from "../ButtonGroup";
import { keyCodes } from "../defines/keyCodes";
import { Drawer } from "../Drawer";
import { NumberSelectorGroup, numberSelectorGroupPropTypes } from "../NumberSelectorGroup";
import styles from "./PassengerSelector.module.scss";

const YOFFSET = -12;

/**
 * PassengerSelector renders a modal type flyout with an Input component to provide a way for a
 * traveler to specific passenger count and passenger types.
 */
export const PassengerSelector = (props) => {
  const { footer, footerRevealed, fullScreen, heading, items, maxCount, name, onChange, onUpdate } =
    props;
  const [currentItems, setCurrentItems] = useState([...items]);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(getInitalTotalCount());
  const initialValues = useRef(getValues(items));

  useEffect(() => {
    setCurrentItems([...items]);
  }, [items]);

  return (
    <div role="group">
      <Drawer {...getProps()}>
        <div onKeyDown={handleKeydown}>
          {heading && renderHeading()}
          {open && <NumberSelectorGroup {...getNumberSelectorProps()} />}
          {footer && renderFooter()}
          <ButtonGroup {...getButtonGroupProps()} />
        </div>
      </Drawer>
    </div>
  );

  function renderHeading() {
    return <div className={styles.heading}>{heading}</div>;
  }

  function renderFooter() {
    return (
      <Area revealed={footerRevealed}>
        <div className={styles.footer}>{footer}</div>
      </Area>
    );
  }

  function getProps() {
    return {
      captionProps: { showPointer: false, yOffset: fullScreen ? undefined : YOFFSET },
      closeOnClickOff: false,
      fullScreen,
      onRevealChange: handleRevealChange,
      revealed: open,
      showPointer: true,
      styleType: "no-style",
      triggerComponent: "input",
      triggerProps: getInputProps(),
    };
  }

  function getInputProps() {
    return {
      "aria-label": props["aria-label"],
      name,
      role: "combobox",
      suffixIcon: {
        color: "link",
        name: "Passenger",
      },
      value: `${totalCount}`,
    };
  }

  function getNumberSelectorProps() {
    return {
      className: styles.numberSelectorProps,
      items: currentItems,
      maxCount,
      onChange: handleChange,
    };
  }

  /* TODO -----------------------------------------------------------------------------------------
     Must decide where this component will live and then update labels for i18n.
   */
  function getButtonGroupProps() {
    return {
      buttonList: [
        {
          label: "Cancel",
          props: {
            onClick: handleCancel,
            styleType: "secondary",
          },
        },
        {
          label: "Apply",
          props: {
            onClick: handleApply,
            styleType: "tertiary",
          },
        },
      ],
      className: styles.buttonGroup,
      ensureVisible: false,
      equalSize: true,
      moreContentIndicator: false,
      spaceBetween: "large",
    };
  }

  function handleRevealChange(isOpen) {
    setOpen(isOpen);
  }

  function handleKeydown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_ENTER) {
      applyValues();
    } else if (key === keyCodes.KEY_ESCAPE) {
      cancelValues();
    }
  }

  function handleChange(updatedValues) {
    setCurrentItems(fillIn(updatedValues));
    setTotalCount(getTotalCount(updatedValues));
    onUpdate?.(updatedValues);
  }

  function handleCancel() {
    cancelValues();
  }

  function handleApply() {
    applyValues();
  }

  function applyValues() {
    onChange?.(getValues(currentItems));
    setOpen(false);
  }

  function cancelValues() {
    setCurrentItems(fillIn(initialValues.current));
    setTotalCount(getTotalCount(initialValues.current));
    setOpen(false);
  }

  function getValues(updateItems) {
    return updateItems.map((item) => item.value);
  }

  function fillIn(updateValues) {
    return items.map((item, index) => {
      const { adjunctContent, key, maximumValue, title, titleCaption } = item;

      return {
        adjunctContent,
        adjunctContentRevealed: updateValues[index] !== 0,
        key,
        maximumValue,
        title,
        titleCaption,
        value: updateValues[index],
      };
    });
  }

  function getInitalTotalCount() {
    return currentItems.reduce((partialSum, item) => partialSum + item.value, 0);
  }

  function getTotalCount(values) {
    return values.reduce((partialSum, itemValue) => partialSum + itemValue, 0);
  }
};

PassengerSelector.propTypes = {
  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** Optional footer to display at bottom of Caption, just above ButtonGroup. */
  footer: PropTypes.node,

  /**
   * Flay to indicate if footer should be revealed. When this prop changes, the reveal/conceal will
   * be animated.
   */
  footerRevealed: PropTypes.bool,

  /** When fullScreen is set, Caption will be full screen. */
  fullScreen: PropTypes.bool,

  /** Optional heading to display at top of PassengerSelector Caption. */
  heading: PropTypes.node,

  /**
   * NumberSelectors to be displayed. The title props indicate what will be rendered to the left of
   * the number selectors.
   */
  items: numberSelectorGroupPropTypes.items,

  /**
   * Maximum value that can be selected across all NumberSelector. If the total of values reaches
   * this maximum, then all increase buttons will be disabled.
   */
  maxCount: PropTypes.number,

  /** Name assigned to the select form control. */
  name: PropTypes.string,

  /** A function that informs when the traveler has accepts the value changes. */
  onChange: PropTypes.func,

  /** A function that updates the value when a value change is made. */
  onUpdate: PropTypes.func,
};

import React, { createContext, useContext, useEffect, useState } from "react";

import { useGet } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { useIsBlankRoute } from "@swa-ui/hybrid";
import { usePersistedState } from "@swa-ui/persistence";
import { PersonasContext } from "@swa-ui/personas";

import { AuthContext } from "../AuthProvider";
import { decodeCookie, getCookie } from "../cookie";
import { accountTypes, corporateRoles } from "../defines";

const API_ENDPOINT = "/api/security/v4/security/userinfo";
const ACCOUNT_TYPE_KEY = "customers.UserInformation.accountType";
const CORPORATE_KEYS = [
  "corporate.companyUserInformation.companyId",
  "corporate.companyUserInformation.name",
  "corporate.corporateUserInformation.role",
  "corporate.companyUserInformation.travelPolicies.approvedCars",
  "corporate.companyUserInformation.travelPolicies.carOption",
  "corporate.companyUserInformation.travelPolicies.carOptionAvailable",
  "corporate.companyUserInformation.travelPolicies.hotelOptionAvailable",
];
const CUSTOMER_KEYS = [
  ACCOUNT_TYPE_KEY,
  "customers.UserInformation.accountNumber",
  "customers.UserInformation.chaseVisaRrEnrolled",
  "customers.UserInformation.companionPassInfo.companionDeclared",
  "customers.UserInformation.companionPassInfo.companionPassAchieved",
  "customers.UserInformation.companionPassInfo.companionPassExpirationDate",
  "customers.UserInformation.firstName",
  "customers.UserInformation.preferredName",
  "customers.UserInformation.redeemablePoints",
  "customers.UserInformation.tier",
  "customers.UserInformation.tierStatusPending",
];

export const UserInfoContext = createContext();
export const UserInfoProvider = (props) => {
  const { appendLogoutHandlers, appendPreLogoutHandlers, isLoggedIn, isLoggedInWithCorporateRole } =
    useContext(AuthContext);
  const { isCorporatePersona } = useContext(PersonasContext);
  const { userInfoV4: cacheLifeValue } = getBootstrapData("api-cache-ttl") ?? {};
  const loggedIn = isLoggedIn();
  const [userInfo, setUserInfo] = usePersistedState({
    defaultValue: {},
    key: "useUserInfo-userinfo",
  });
  const isBlankRoute = useIsBlankRoute();
  const isLoggedInNotOnBlankPage = loggedIn && !isBlankRoute;
  const {
    cache,
    data,
    loading: userInfoLoading,
    refresh,
    resetData: resetUserInfo,
  } = useGet(API_ENDPOINT, AuthContext, isLoggedInNotOnBlankPage, {
    ...(typeof cacheLifeValue === "number" && { cacheLife: cacheLifeValue }),
  });

  const [currentAccountNumber, setCurrentAccountNumber] = useState();
  const [currentCompanyId, setCurrentCompanyId] = useState();

  useEffect(() => {
    data && setUserInfo(getValidUserInfo(data));
    appendPreLogoutHandlers(() => {
      setCurrentAccountNumber(null);
      setCurrentCompanyId(null);
    });
    appendLogoutHandlers(() => {
      setUserInfo(null);
      cache.clear();
      resetUserInfo();
    }, API_ENDPOINT);
  }, [data, isBlankRoute]);

  useEffect(() => {
    if (userInfo) {
      const { accountNumber } = getCustomerInfo();

      setCurrentAccountNumber(accountNumber);
    }

    if (userInfo && isCorporatePersona) {
      const { companyId } = getCorporateInfo();

      setCurrentCompanyId(companyId);
    }
  }, [userInfo]);

  return (
    <UserInfoContext.Provider value={getContextValue()}>{props.children}</UserInfoContext.Provider>
  );

  function getContextValue() {
    return {
      currentAccountNumber,
      currentCompanyId,
      getCorporateInfo,
      getCustomerInfo,
      isMemberAccount,
      refreshUserInfo,
      userInfo,
      userInfoLoading,
    };
  }

  function getValidUserInfo(rawData) {
    return Object.keys(rawData || {}).reduce(
      (filteredData, key) => ({
        ...filteredData,
        ...(CUSTOMER_KEYS.concat(CORPORATE_KEYS).includes(key) && {
          [key]: rawData[key],
        }),
      }),
      {}
    );
  }

  function getCustomerInfo() {
    return getFilteredUserInfo(CUSTOMER_KEYS);
  }

  function getCorporateInfo() {
    return isLoggedInWithCorporateRole(corporateRoles.ANONYMOUS)
      ? getCorporateInfoFromCorporateToken()
      : getFilteredUserInfo(CORPORATE_KEYS);
  }

  function getCorporateInfoFromCorporateToken() {
    const corporateTokenCookie = getCookie("corporateToken");
    const corporateInformation = corporateTokenCookie ? decodeCookie(corporateTokenCookie) : {};

    return {
      companyId: corporateInformation.companyId,
      name: corporateInformation.companyName,
    };
  }

  function getFilteredUserInfo(filteredKeys = []) {
    return (
      userInfo &&
      filteredKeys.reduce((filteredData, key) => {
        const shortKey = key.split(".").pop();

        return {
          ...filteredData,
          [shortKey]: userInfo[key],
        };
      }, {})
    );
  }

  function isMemberAccount() {
    return userInfo?.[ACCOUNT_TYPE_KEY] === accountTypes.MEMBER;
  }

  function refreshUserInfo() {
    refresh();
  }
};

UserInfoContext.displayName = "UserInfoContext";

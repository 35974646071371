import React, { useContext, useState } from "react";

import { useAppSetting } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";
import { Grid } from "@swa-ui/core";
import { dateFormats, swaDate } from "@swa-ui/date";
import { CalendarFormField, Form, SubmitFormField, useForm, yupResolver } from "@swa-ui/form";
import { RecentSearchContext } from "@swa-ui/form";
import { useGeolocation } from "@swa-ui/geolocation";
import {
  AutocompleteStationsFormField,
  AutocompleteVacationDestinationsFormField,
  stationMethods,
} from "@swa-ui/itinerary";
import i18n from "@swa-ui/locale";

import { FLIGHT_HOTEL } from "../defines/packageType";
import { base64Encoder } from "../utils";
import { PackageOptions } from "./PackageOptions";
import { PromoCode } from "./PromoCode";
import { TravelerSelector } from "./TravelerSelector";
import styles from "./VacationsBookingForm.module.scss";
import { vacationsBookingFormSchema } from "./vacationsBookingFormSchema";

const DEFAULT_DEPART_DATE_OFFSET = 13;
const DEFAULT_RETURN_DATE_OFFSET = 16;

export const VacationsBookingForm = () => {
  // useGeolocation is a hook and must be done before everything else.
  const geolocation = useGeolocation();
  const { addRecentSearch } = useContext(RecentSearchContext);
  const [destinationName, setDestinationName] = useState("");
  const [formData, setFormData] = useState(getDefaultFormData());
  const airDatesData = getBootstrapData("air/air-dates-data");
  const basePathOJTWebapp = useAppSetting("basePathOJTWebapp");
  const destinationSearchResultsLimit = useAppSetting("destinationSearchResultsLimit");
  const methods = useForm({
    resolver: yupResolver(vacationsBookingFormSchema()),
  });

  const { getValues, reset, setValue, trigger } = methods;

  return (
    <Grid className={styles.layout}>
      <Form methods={methods} onSubmit={handleFormSubmit}>
        <PackageOptions {...getPackageOptionProps()} />
        <TravelerSelector {...getTravelerSelectorProps()} />
        <PromoCode {...getPromoCodeProps()} />
        <Grid className={styles.formInputs}>
          <AutocompleteStationsFormField {...getOriginFormFieldProps()} />
          <AutocompleteVacationDestinationsFormField {...getDestinationFormFieldProps()} />
          <CalendarFormField {...getDepartDateProps()} />
          <CalendarFormField {...getReturnDateProps()} />
        </Grid>
        <div className={styles.submit}>
          <SubmitFormField {...getSubmitButtonProps()}>
            {i18n("VacationsBookingForm__FIND_A_VACATION")}
          </SubmitFormField>
        </div>
      </Form>
    </Grid>
  );

  function renderCalendarAdjunctContent() {
    const calendarDateStringFormat = i18n("VacationsBookingForm__CALENDAR_DATE_STRING_FORMAT");

    const formattedCurrentLastDateString = swaDate(airDatesData.currentLastBookableDate).format(
      calendarDateStringFormat
    );
    const formattedFutureOpenDateString = swaDate(airDatesData.futureBookingOpenDate).format(
      calendarDateStringFormat
    );
    const formattedFutureCloseDateString = swaDate(airDatesData.futureBookingCloseDate).format(
      calendarDateStringFormat
    );

    return (
      <div className={styles.lastBookableDate}>
        {i18n("VacationsBookingForm__LAST_BOOKABLE_DATE", { formattedCurrentLastDateString })}
        <div className={styles.bookableScheduleExtension}>
          {i18n("VacationsBookingForm__ON_OPEN_SCHEDULE", {
            formattedFutureOpenDateString,
            formattedFutureCloseDateString,
          })}
        </div>
        <div className={styles.bookableScheduleExtension}>
          {`${i18n("VacationsBookingForm__SUBJECT_TO_CHANGE")}`}
        </div>
      </div>
    );
  }

  function getPackageOptionProps() {
    return {
      className: styles.package,
      onChange: handlePackageOptionsChange,
      value: formData.packageType,
    };
  }

  function getTravelerSelectorProps() {
    const { packageType, travelers } = formData;

    return {
      className: styles.travelers,
      onChange: handleTravelerSelectorChange,
      packageType,
      travelers,
    };
  }

  function getPromoCodeProps() {
    return {
      className: styles.promo,
      onChange: handlePromoCodeChange,
      value: formData.promoCode,
    };
  }

  function getOriginFormFieldProps() {
    const { originationAirportCode } = formData;

    return {
      caption: originationAirportCode
        ? stationMethods.getStationName(getValues("originationAirportCode"))
        : undefined,
      className: styles.originationAirportCode,
      componentProps: {
        defaultValue: originationAirportCode,
        invalidRouteText: i18n("VacationsBookingForm__INVALID_ROUTE"),
        labelText: i18n("VacationsBookingForm__FROM"),
        noMatchFoundText: i18n("VacationsBookingForm__NO_MATCH_FOUND"),
        required: true,
        softEdge: true,
      },
      label: i18n("VacationsBookingForm__FROM"),
      name: "originationAirportCode",
    };
  }

  function getDestinationFormFieldProps() {
    const { destinationAirportCode } = formData;

    return {
      className: styles.destinationAirportCode,
      componentProps: {
        defaultValue: destinationAirportCode,
        labelText: i18n("VacationsBookingForm__TO"),
        maxItemsToDisplay: 10,
        noMatchFoundText: i18n("VacationsBookingForm__NO_MATCH_FOUND"),
        onBlur: handleDestinationOnBlur,
        onChange: handleDestinationChange,
        required: true,
        searchResultsLimit: Number(destinationSearchResultsLimit),
        softEdge: true,
      },
      label: i18n("VacationsBookingForm__TO"),
      name: "destinationAirportCode",
    };
  }

  function getDepartDateProps() {
    const { departureDate } = formData;

    return {
      caption: getDateCaption(departureDate),
      className: styles.departureDate,
      componentProps: getCalendarProps(i18n("VacationsBookingForm__DEPART_DATE_ARIA"), "start"),
      label: i18n("VacationsBookingForm__DEPART_DATE"),
      name: "departureDate",
    };
  }

  function getReturnDateProps() {
    const { returnDate } = formData;

    return {
      caption: getDateCaption(returnDate),
      className: styles.returnDate,
      componentProps: getCalendarProps(i18n("VacationsBookingForm__RETURN_DATE_ARIA"), "end"),
      label: i18n("VacationsBookingForm__RETURN_DATE"),
      name: "returnDate",
    };
  }

  function getCalendarProps(ariaLabel, focusField) {
    const { departureDate, returnDate } = formData;

    return {
      adjunctContent: renderCalendarAdjunctContent(),
      "aria-label": ariaLabel,
      closeContent: i18n("VacationsBookingForm__CLOSE"),
      dateEnd: returnDate,
      dateStart: departureDate,
      daysOfWeekInitials: i18n("VacationsBookingForm__DAYS_OF_WEEK_INITIALS"),
      defaultValue: focusField === "end" ? returnDate : departureDate,
      firstBookableDate: swaDate().add(1, "day").format(dateFormats.default),
      focusField,
      format: i18n("VacationsBookingForm__CALENDAR_INPUT_FORMAT"),
      lastBookableDate: swaDate(airDatesData.currentLastBookableDate).format(dateFormats.default),
      numberCalendars: 2,
      numberDates: 2,
      onSelectEnd: handleSelectEnd,
      onSelectStart: handleSelectStart,
      required: true,
      suffixIcon: { color: "trip-selection-calendar-suffix-icon", name: "Calendar" },
      title: i18n("VacationsBookingForm__DATE_SELECTOR_TITLE"),
    };
  }

  function getSubmitButtonProps() {
    return {
      className: styles.submitButton,
      componentProps: {
        fullWidth: true,
        styleType: "primary",
      },
      name: "submitVacationsBookingForm",
    };
  }

  function handleDestinationOnBlur(event) {
    if (event.target.isValid) {
      setDestinationName(event.target.name);
    } else {
      validateToField();
    }
  }

  function handleDestinationChange(event) {
    setDestinationName(event.target.name);
  }

  function getDefaultDepartDate() {
    return swaDate()
      .set("date", swaDate().get("date") + DEFAULT_DEPART_DATE_OFFSET)
      .format(dateFormats.default);
  }

  function getDefaultReturnDate() {
    return swaDate()
      .set("date", swaDate().get("date") + DEFAULT_RETURN_DATE_OFFSET)
      .format(dateFormats.default);
  }

  function validateToField() {
    setValue("destinationAirportCode", "");
    trigger("destinationAirportCode");
  }

  function getDateCaption(dateString) {
    return dateString
      ? swaDate(dateString).format(i18n("VacationsBookingForm__CALENDAR_CAPTION_FORMAT"))
      : "";
  }

  function getDefaultFormData() {
    return {
      originationAirportCode: geolocation?.nearestStation || "",
      destinationAirportCode: "",
      departureDate: getDefaultDepartDate(),
      packageType: FLIGHT_HOTEL,
      returnDate: getDefaultReturnDate(),
      travelers: [
        {
          adults: 2,
          children: 0,
          childrenAge: [],
          lapChildren: 0,
        },
      ],
    };
  }

  function handlePackageOptionsChange(packageType) {
    const defaultFormData = getDefaultFormData();

    reset(defaultFormData);
    setFormData({ ...defaultFormData, packageType });
    setValue("packageType", packageType);
  }

  function handleTravelerSelectorChange(value) {
    setFormData({ ...formData, travelers: value });
    setValue("travelers", value);
  }

  function handlePromoCodeChange(value) {
    setFormData({ ...formData, promoCode: value });
    setValue("promoCode", value);
  }

  function handleSelectEnd(value) {
    const { departureDate } = formData;
    const newFormData = { ...formData };
    let selectionError = departureDate === value;

    if (swaDate(departureDate).isSameOrAfter(value, "day")) {
      newFormData.departureDate = value;
      newFormData.returnDate = "";
      setValue("departureDate", value);
      setValue("returnDate", "");
      selectionError = true;
    } else {
      newFormData.returnDate = value;
      setValue("returnDate", value);
    }

    setFormData(newFormData);

    return selectionError;
  }

  function handleSelectStart(value) {
    const { returnDate } = formData;
    const newFormData = { ...formData };
    let selectionError = returnDate === value;

    if (swaDate(value).isAfter(returnDate, "day")) {
      newFormData.returnDate = "";
      setValue("returnDate", "");
      selectionError = true;
    }

    setValue("departureDate", value);
    newFormData.departureDate = value;

    setFormData(newFormData);

    return selectionError;
  }

  function handleFormSubmit(submittedFormData) {
    const encodedURL = getEncodedURL(submittedFormData);

    if (destinationName) {
      updateRecentSearches(submittedFormData, encodedURL);
      window.open(encodedURL, "_blank");
    } else {
      validateToField();
    }
  }

  function getEncodedURL(submittedFormData) {
    return `${basePathOJTWebapp}/package/dl/results?productType=package&search=${base64Encoder(
      submittedFormData
    )}`;
  }

  function updateRecentSearches(submittedFormData, encodedURL) {
    const { departureDate, originationAirportCode, packageType, returnDate, travelers } =
      submittedFormData;
    let adults = 0;
    let children = 0;

    travelers.forEach((room) => {
      adults += room.adults;
      children += room.children + room.lapChildren;
    });

    addRecentSearch(
      {
        adults,
        children,
        departureDate,
        destinationAirportCode: destinationName,
        originationAirportCode,
        packageType,
        returnDate,
        rooms: travelers.length,
        searchVacationImageUrl:
          "https://swav.openjawtech.com/external/hotel/images/FNA33T6B/15_Renaissance%20Orlando%20at%20SeaWorld%20exterior.jpeg",
        searchVacationRedirectPageUrl: encodedURL,
      },
      packageType
    );
  }
};

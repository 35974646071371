/* eslint-disable sort-keys */
import { colors } from "../assets/styles/globalDefines";

export default {
  // semantic palette
  "primary-100": colors.primary100,
  "primary-200": colors.primary200,
  "primary-300": colors.primary300,
  "primary-400": colors.primary400,
  "primary-500": colors.primary500,
  "primary-600": colors.primary600,
  "primary-700": colors.primary700,
  "primary-800": colors.primary800,
  "primary-900": colors.primary900,
  "primary-950": colors.primary950,

  "secondary-100": colors.secondary100,
  "secondary-200": colors.secondary200,
  "secondary-300": colors.secondary300,
  "secondary-400": colors.secondary400,
  "secondary-500": colors.secondary500,
  "secondary-600": colors.secondary600,
  "secondary-700": colors.secondary700,
  "secondary-800": colors.secondary800,
  "secondary-900": colors.secondary900,

  "secondary-vacations-100": colors.secondaryVacations100,
  "secondary-vacations-200": colors.secondaryVacations200,
  "secondary-vacations-300": colors.secondaryVacations300,
  "secondary-vacations-400": colors.secondaryVacations400,
  "secondary-vacations-500": colors.secondaryVacations500,
  "secondary-vacations-600": colors.secondaryVacations600,
  "secondary-vacations-700": colors.secondaryVacations700,
  "secondary-vacations-800": colors.secondaryVacations800,
  "secondary-vacations-900": colors.secondaryVacations900,

  "tertiary-100": colors.tertiary100,
  "tertiary-200": colors.tertiary200,
  "tertiary-300": colors.tertiary300,
  "tertiary-400": colors.tertiary400,
  "tertiary-500": colors.tertiary500,
  "tertiary-600": colors.tertiary600,
  "tertiary-700": colors.tertiary700,
  "tertiary-800": colors.tertiary800,
  "tertiary-900": colors.tertiary900,

  "tertiary-vacations-100": colors.tertiaryVacations100,
  "tertiary-vacations-200": colors.tertiaryVacations200,
  "tertiary-vacations-300": colors.tertiaryVacations300,
  "tertiary-vacations-400": colors.tertiaryVacations400,
  "tertiary-vacations-500": colors.tertiaryVacations500,
  "tertiary-vacations-600": colors.tertiaryVacations600,
  "tertiary-vacations-700": colors.tertiaryVacations700,
  "tertiary-vacations-800": colors.tertiaryVacations800,
  "tertiary-vacations-900": colors.tertiaryVacations900,

  "success-100": colors.success100,
  "success-200": colors.success200,
  "success-300": colors.success300,
  "success-400": colors.success400,
  "success-500": colors.success500,
  "success-600": colors.success600,
  "success-700": colors.success700,
  "success-800": colors.success800,
  "success-900": colors.success900,

  "warning-100": colors.warning100,
  "warning-200": colors.warning200,
  "warning-300": colors.warning300,
  "warning-400": colors.warning400,
  "warning-500": colors.warning500,
  "warning-600": colors.warning600,
  "warning-700": colors.warning700,
  "warning-800": colors.warning800,
  "warning-900": colors.warning900,

  "danger-100": colors.danger100,
  "danger-200": colors.danger200,
  "danger-300": colors.danger300,
  "danger-400": colors.danger400,
  "danger-500": colors.danger500,
  "danger-600": colors.danger600,
  "danger-700": colors.danger700,
  "danger-800": colors.danger800,
  "danger-900": colors.danger900,

  "neutral-100": colors.neutral100,
  "neutral-200": colors.neutral200,
  "neutral-300": colors.neutral300,
  "neutral-400": colors.neutral400,
  "neutral-500": colors.neutral500,
  "neutral-600": colors.neutral600,
  "neutral-700": colors.neutral700,
  "neutral-750": colors.neutral750,
  "neutral-800": colors.neutral800,
  "neutral-900": colors.neutral900,

  // brand colors - deprecated in favor of semantic palette
  black: colors.black,
  "blue-1": colors.blue1,
  "blue-1-light": colors.blue1Light,
  "blue-2": colors.blue2,
  "blue-3": colors.blue3,
  "blue-3-light": colors.blue3Light,
  "blue-4": colors.blue4,
  "gray-1": colors.gray1,
  "gray-2": colors.gray2,
  "gray-3": colors.gray3,
  "gray-4": colors.gray4,
  "gray-5": colors.gray5,
  "gray-5-dark": colors.gray5Dark,
  "green-1": colors.green1,
  "green-2": colors.green2,
  "red-1": colors.red1,
  "red-2": colors.red2,
  "yellow-1": colors.yellow1,
  white: colors.white,

  // social colors
  "primary-facebook": colors.primaryFacebook,
  "secondary-facebook": colors.secondaryFacebook,
  "primary-instagram": colors.primaryInstagram,
  "secondary-instagram": colors.secondaryInstagram,
  "primary-twitter": colors.primaryTwitter,
  "secondary-twitter": colors.secondaryTwitter,
  "primary-youtube": colors.primaryYoutube,
  "secondary-youtube": colors.secondaryYoutube,
  "primary-linkedin": colors.primaryLinkedin,
  "secondary-linkedin": colors.secondaryLinkedin,
  "primary-pinterest": colors.primaryPinterest,
  "secondary-pinterest": colors.secondaryPinterest,

  // gradients
  "gradient-bold-blue-stop-1": colors.gradientBoldBlueStop1,
  "gradient-bold-blue-stop-2": colors.gradientBoldBlueStop2,
  "gradient-bold-blue-stop-3": colors.gradientBoldBlueStop3,
  "gradient-loading-light-stop-1": colors.gradientLoadingLightStop1,
  "gradient-loading-light-stop-2": colors.gradientLoadingLightStop2,
  "gradient-loading-medium-stop-1": colors.gradientLoadingMediumStop1,
  "gradient-loading-medium-stop-2": colors.gradientLoadingMediumStop2,
  "gradient-summit-silver-stop-1": colors.gradientSummitSilverStop1,
  "gradient-summit-silver-stop-2": colors.gradientSummitSilverStop2,
  "gradient-sunrise-yellow-stop-1": colors.gradientSunriseYellowStop1,
  "gradient-sunrise-yellow-stop-2": colors.gradientSunriseYellowStop2,
  "gradient-turquoise-stop-1": colors.gradientTurquoiseStop1,
  "gradient-turquoise-stop-2": colors.gradientTurquoiseStop2,
  "gradient-warm-red-stop-1": colors.gradientWarmRedStop1,
  "gradient-warm-red-stop-2": colors.gradientWarmRedStop2,
};

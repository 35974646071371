import PropTypes from "prop-types";
import React, { useContext } from "react";

import { window } from "@swa-ui/browser";
import { Button } from "@swa-ui/core";
import { encryptData, EncryptionContext } from "@swa-ui/encryption";

import { getPersistedVacationsBookingData } from "../vacationsPersistence";

export const UpdatePassengerInfoCallToAction = (props) => {
  const { ariaLabel, buttonType, children } = props;
  const { encrypt } = useContext(EncryptionContext);

  return <Button {...getProps()}>{children}</Button>;

  function getProps() {
    return {
      "aria-label": ariaLabel,
      onClick: handleClick,
      styleType: buttonType,
    };
  }

  async function handleClick() {
    const { airConfirmationNumber, passengerFirstName, passengerLastName } =
      getPersistedVacationsBookingData();
    const formDataToEncrypt = {
      confirmationNumber: airConfirmationNumber,
      passengerFirstName,
      passengerLastName,
    };

    // TODO: Replace this URL with WCM urls definition (PEGMON-27)
    const url = await encryptData(
      encrypt,
      formDataToEncrypt,
      "/air/manage-reservation/view",
      "ManageMyVacations",
      "vacationUpdatePassengerInfo"
    );

    window.location.assign(url);
  }
};

UpdatePassengerInfoCallToAction.propTypes = {
  /** Mapped to aria-label of Button or Link component. */
  ariaLabel: PropTypes.string,

  /**
   * Mapped to styleType of Button component.
   * Standard Buttons will have 'primary' or 'button-primary' semantic values, while
   * buttonType with link values 'bright'/'dark'/'light' should render a Link.
   */
  buttonType: PropTypes.string,

  /** Content that will be rendered on the Button or Link component. */
  children: PropTypes.node,
};

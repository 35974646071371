import { useContext, useRef } from "react";

import { isResponseOk, useFetch } from "@swa-ui/fetch";

import { AuthContext } from "../../AuthProvider";

const API_BASE_PATH = "/api/mfa/v1/mfa";
// TODO: Replace these values with the actual values from WCM
const PING_ONE_ENVIRONMENT_ID = "b344fba3-aba7-4f8c-b78c-d74737fb1efa";
const PING_ONE_CLIENT_ID = "ec55d089-65d4-4993-b25d-dfc894370c33";
const CIAM_TIER_CODE = "080";

export const useMfaServices = () => {
  const {
    get,
    loading: initializeLoading,
    response: initializeResponse,
  } = useFetch("", { cachePolicy: "no-cache" });
  const { loading, post, response } = useFetch("", { cachePolicy: "no-cache" });
  const {
    auth: { idToken },
  } = useContext(AuthContext);
  const ids = useRef({});

  return {
    getMfaDevices,
    loading: loading || initializeLoading,
    resendMfaOtp,
    restartMfa,
    sendMfaOtp,
    validateMfaOtp,
  };

  async function getMfaDevices() {
    let devices = [];
    let errorCode;
    const initializeBody = await get(getInitializationPath());

    if (isResponseOk(initializeResponse)) {
      ids.current = { connectionId: initializeBody.connectionId, id: initializeBody.id };

      const devicesBody = await post(getPath(), getRequestBody({ id_token: idToken }));

      if (isResponseOk(response)) {
        const { devices: responseDevices = [] } =
          devicesBody?.screen?.properties?.customHTML?.value?.deviceDetails;

        updateCurrentId(response, devicesBody);

        devices = responseDevices;
      } else {
        errorCode = getErrorCode(response, devicesBody);
      }
    } else {
      errorCode = getErrorCode(initializeResponse, initializeBody);
    }

    return { devices, errorCode };
  }

  async function sendMfaOtp(deviceType) {
    const body = await post(getPath(), getRequestBody({ method: deviceType }));

    updateCurrentId(response, body);

    return { errorCode: getErrorCode(response, body) };
  }

  async function resendMfaOtp() {
    const body = await post(getPath(), getRequestBody({ action: "resend" }));

    updateCurrentId(response, body);

    return { errorCode: getErrorCode(response, body) };
  }

  async function restartMfa() {
    const body = await post(getPath(), getRequestBody({ action: "restart" }));

    updateCurrentId(response, body);

    return { errorCode: getErrorCode(response, body) };
  }

  async function validateMfaOtp(otp) {
    const body = await post(
      getPath(),
      getRequestBody({
        action: "verify",
        otp,
      })
    );

    updateCurrentId(response, body);

    return { errorCode: getErrorCode(response, body) };
  }

  function getInitializationPath() {
    return `${API_BASE_PATH}/${PING_ONE_ENVIRONMENT_ID}/as/authorize?client_id=${PING_ONE_CLIENT_ID}&response_type=code&response_mode=pi.flow&scope=openid`;
  }

  function getPath() {
    return `${API_BASE_PATH}/${PING_ONE_ENVIRONMENT_ID}/davinci/connections/${ids.current.connectionId}/capabilities/customHTMLTemplate`;
  }

  function getRequestBody(parameters) {
    return {
      eventName: "continue",
      id: ids.current.id,
      nextEvent: {
        constructType: "skEvent",
        eventName: "continue",
        eventType: "post",
        params: [],
        postProcess: {},
      },
      parameters: {
        buttonType: "form-submit",
        buttonValue: "submit",
        ...parameters,
      },
    };
  }

  function getErrorCode(serviceResponse, body) {
    let errorCode;

    if (!isResponseOk(serviceResponse)) {
      errorCode = (body && body.code) ?? `${serviceResponse.status ?? "000"}${CIAM_TIER_CODE}000`;
    }

    return errorCode;
  }

  function updateCurrentId(postResponse, body) {
    if (isResponseOk(postResponse)) {
      ids.current.id = body.id;
    }
  }
};

import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { MessageContext } from "@swa-ui/application";
import { getResponseErrorKey } from "@swa-ui/fetch";

import { MfaContext } from "../../MfaProvider";
import { useUserInfo } from "../../UserInfoProvider";
import { MfaContactSelectForm } from "../MfaContactSelectForm";
import { MfaEnterPasscodeForm } from "../MfaEnterPasscodeForm";
import { MfaSuccess } from "../MfaSuccess";
import { useMfaServices } from "./useMfaServices";

const MFA_API_ERROR_MAPPING_URL =
  "/api/mfa/v1/mfa/davinci/connections/capabilities/customHTMLTemplate";
const MFA_STEP_CONTACT_SELECT = "MFA_STEP_CONTACT_SELECT";
const MFA_STEP_ENTER_PASSCODE = "MFA_STEP_ENTER_PASSCODE";
const MFA_STEP_SUCCESS = "MFA_STEP_SUCCESS";

/**
 * MfaAuthorizer is a component that handles the MFA authorization process. It will prompt the
 * user to select a contact method, enter a passcode, and then display a success message.
 * Once the user is authorized, it will call the onAuthorized callback.
 */
export const MfaAuthorizer = (props) => {
  const { mfaServices, onAuthorized, onError } = props;
  const [currentStep, setCurrentStep] = useState(MFA_STEP_CONTACT_SELECT);
  const [selectedContact, setSelectedContact] = useState();
  const [contacts, setContacts] = useState();
  const { getMfaDevices, resendMfaOtp, sendMfaOtp, restartMfa, validateMfaOtp } =
    mfaServices ?? useMfaServices();
  const [validationErrorKey, setValidationErrorKey] = useState();
  const [loading, setLoading] = useState(false);
  const [passwordResendSuccess, setPasswordResendSuccess] = useState(false);
  const { displayServiceNotifications } = useContext(MessageContext);
  const { getCustomerInfo } = useUserInfo();
  const { handleMfaAuthenticated } = useContext(MfaContext);
  const { firstName, preferredName } = getCustomerInfo() ?? {};
  const name = preferredName || firstName;

  useEffect(() => {
    getMfaDevices().then((devicesResponse) => {
      const { devices, errorCode } = devicesResponse;

      if (errorCode) {
        handleError(errorCode);
      } else {
        setContacts(devices);
      }
    });
  }, []);

  return (
    <>
      {currentStep === MFA_STEP_CONTACT_SELECT && (
        <MfaContactSelectForm
          contacts={contacts}
          loading={loading}
          onSubmit={handleContactSelectSubmit}
        />
      )}
      {currentStep === MFA_STEP_ENTER_PASSCODE && (
        <MfaEnterPasscodeForm
          contact={selectedContact}
          loading={loading}
          onResend={handleResend}
          onRestart={handleRestart}
          onSubmit={handleEnterPasscodeSubmit}
          passwordResendSuccess={passwordResendSuccess}
          validationErrorKey={validationErrorKey}
        />
      )}
      {currentStep === MFA_STEP_SUCCESS && (
        <MfaSuccess loading={loading} onContinue={handleContinue} name={name} />
      )}
    </>
  );

  async function handleContactSelectSubmit(formData) {
    setLoading(true);
    setSelectedContact(formData.contact);

    const { errorCode } = await sendMfaOtp(formData.contact.deviceType);

    if (errorCode) {
      handleError(errorCode);
    } else {
      setLoading(false);
      setCurrentStep(MFA_STEP_ENTER_PASSCODE);
    }
  }

  async function handleResend() {
    setPasswordResendSuccess(false);

    const { errorCode } = await resendMfaOtp();

    if (errorCode) {
      handleError(errorCode);
    } else {
      setPasswordResendSuccess(true);
    }
  }

  async function handleRestart() {
    setLoading(true);
    setPasswordResendSuccess(false);

    const { errorCode } = await restartMfa();

    if (errorCode) {
      handleError(errorCode);
    } else {
      setCurrentStep(MFA_STEP_CONTACT_SELECT);
      setLoading(false);
    }
  }

  async function handleEnterPasscodeSubmit(passcodeFormData) {
    setLoading(true);
    setValidationErrorKey(undefined);

    const { errorCode } = await validateMfaOtp(passcodeFormData.passcode);

    if (errorCode) {
      const errorKey = getErrorKey(errorCode);

      if (errorKey && errorKey.includes?.("FIELD_ERROR")) {
        setValidationErrorKey(errorKey);
      } else {
        handleError(errorCode);
      }
    } else {
      await handleMfaAuthenticated();
      setCurrentStep(MFA_STEP_SUCCESS);
    }

    setLoading(false);
  }

  function getErrorKey(errorCode) {
    return getResponseErrorKey(getErrorDetails(errorCode));
  }

  function handleError(errorCode) {
    displayServiceNotifications(getErrorDetails(errorCode));
    onError && onError(errorCode);
  }

  function getErrorDetails(errorCode) {
    return {
      data: { code: errorCode },
      url: MFA_API_ERROR_MAPPING_URL,
    };
  }

  function handleContinue() {
    handleMfaAuthenticated();
    setLoading(true);
    onAuthorized();
  }
};

MfaAuthorizer.propTypes = {
  /**
   * Object containing MFA services.
   */
  mfaServices: PropTypes.shape({
    getMfaDevices: PropTypes.func,
    resendMfaOtp: PropTypes.func,
    restartMfaOtp: PropTypes.func,
    sendMfaOtp: PropTypes.func,
    validateMfaOtp: PropTypes.func,
  }),

  /**
   * Function to call when the user is authorized
   */
  onAuthorized: PropTypes.func,
};

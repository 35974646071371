import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { useDeviceInfo, XLARGE } from "@swa-ui/browser";
import { Heading, Layout, ListScroller, Shimmer } from "@swa-ui/core";
import { useFeaturedDealsAnalytics } from "@swa-ui/dotcom-analytics";
import i18n from "@swa-ui/locale";
import { CallToAction, usePlacement } from "@swa-ui/placements";

import { PackageCard } from "./PackageCard";
import { usePackageData } from "./PackagesService";
import styles from "./VacationsPackagesPlacement.module.scss";

export const VacationsPackagesPlacement = (props) => {
  const { placement, placementData = {} } = props;
  const { callToAction, heading, carouselContent } = placement;
  const { analyticsValue = "" } = placementData;
  const { isLoading, packagesDataList } = usePackageData(carouselContent);
  const { screenSize } = useDeviceInfo();
  const { setPlacementsAnalytics } = usePlacement();
  const memoizedItems = useMemo(() => getListScrollerItems(), [packagesDataList]);

  useFeaturedDealsAnalytics(packagesDataList);

  return (
    (packagesDataList.length > 0 || isLoading) && (
      <>
        <Layout {...getTopLayoutProps()}>
          <Heading {...getHeadingProps()}>{heading}</Heading>
        </Layout>
        {isLoading ? renderLoading() : <ListScroller {...getListScrollerProps()} />}
        {renderCallToAction()}
      </>
    )
  );

  function renderLoading() {
    return (
      <Layout center={screenSize === XLARGE} sides>
        <Shimmer />
      </Layout>
    );
  }

  function renderCallToAction() {
    return (
      callToAction && (
        <Layout {...getBottomLayoutProps()}>
          <CallToAction {...callToAction} />
        </Layout>
      )
    );
  }

  function getTopLayoutProps() {
    return {
      bottom: "medium",
      center: true,
      sides: true,
    };
  }

  function getHeadingProps() {
    return {
      className: styles.heading,
      children: heading,
      headingLevel: 2,
    };
  }

  function getListScrollerProps() {
    return {
      "aria-label-current-page": i18n("PackagesPlacement__ARIA_LABEL__CURRENT_PAGE"),
      "aria-label-next": i18n("PackagesPlacement__ARIA_LABEL__NEXT"),
      "aria-label-page-count": i18n("PackagesPlacement__ARIA_LABEL__PAGE_COUNT"),
      "aria-label-previous": i18n("PackagesPlacement__ARIA_LABEL__PREVIOUS"),
      className: styles.scroller,
      center: packagesDataList.length < 4,
      items: memoizedItems,
      layout: "none",
      onClick: handleListScrollerClick,
    };
  }

  function getBottomLayoutProps() {
    return {
      center: true,
      className: styles.callToAction,
      sides: true,
      top: "medium",
    };
  }

  function getListScrollerItems() {
    return packagesDataList.map((item) => (
      <PackageCard
        key={item.resultSetId}
        navigationLink={item.navigationLink}
        packageData={item.packageData}
      />
    ));
  }

  function handleListScrollerClick() {
    if (analyticsValue) {
      setPlacementsAnalytics({
        eventType: "scroll",
        eventDescription: analyticsValue,
      });
    }
  }
};

VacationsPackagesPlacement.propTypes = {
  /** Placement object for packages section. */
  placement: PropTypes.shape({
    callToAction: PropTypes.object,
    heading: PropTypes.string,
    carouselContent: PropTypes.shape({
      packageIds: PropTypes.arrayOf(
        PropTypes.shape({
          tripProfileId: PropTypes.string,
          searchProfileId: PropTypes.string,
        })
      ),
    }),
  }),
  placementData: PropTypes.shape({
    /** The dynamic value that will be used as an event description for analytics. */
    analyticsValue: PropTypes.string,
  }),
};

import PropTypes from "prop-types";
import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";

import { MessageProvider, Version } from "@swa-ui/application";
import { window } from "@swa-ui/browser";
import { Grid } from "@swa-ui/core";
import { globalDefines } from "@swa-ui/core/defines";
import { BusinessLightTheme, CustomerLightTheme, VacationsLightTheme } from "@swa-ui/design-tokens";
import { DotcomApp, DotcomMessages, DotcomSystemMessages } from "@swa-ui/dotcom";
import { PlacementFactory } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";
import { useColorTheme } from "@swa-ui/theme";
import {
  ManageMyVacationsForm,
  VacationsBookingWidget,
  VacationsPackagesPlacement,
} from "@swa-ui/vacations";

import { bootstrapDependencies } from "../../configure/configureBootstrap";
import { getMktgExportSchemas } from "../../schema/getMktgExportSchemas";
import vacationsTheme from "../../vacations-theme";
import { LandingPageFooter } from "../LandingPageFooter";
import { LandingPageHeader } from "../LandingPageHeader";
import { MboxScripts } from "../MboxScripts";
import "./App.module.scss";
import styles from "./App.module.scss";

/**
 * App is responsible for rendering the header, footer, placements and any other forms into any static html page.
 */
const DOCUMENT = window.document;
const FOOTER = DOCUMENT.getElementById("footer");
const PLACEMENT_ELEMENTS = DOCUMENT.getElementsByClassName("placement");

const App = (props) => {
  const { placementParameters = "", themeName } = props;
  const { media } = globalDefines;
  const themeSettings = getThemeSettings(themeName);
  const { HeaderTheme, PageTheme } = themeSettings;
  const { addThemeEntries } = useColorTheme();

  useLayoutEffect(() => {
    if (themeName === "vacations") {
      addThemeEntries(vacationsTheme);
    }
  }, []);

  return (
    <BrowserRouter>
      <DotcomApp {...getDotcomAppProps()}>
        <MboxScripts />
        <MessageProvider>
          <Version basePath={"/swa-ui/fm/dotcom-landing-page/v1/"} />
          <div className={getContainerClassName()}>
            <HeaderTheme>
              <LandingPageHeader {...themeSettings} />
            </HeaderTheme>
            <Grid>
              <div className={styles.messages}>
                <DotcomSystemMessages />
                <DotcomMessages className={styles.messages} />
              </div>
            </Grid>
          </div>
          {Array.from(PLACEMENT_ELEMENTS).map((placementElement) =>
            ReactDOM.createPortal(
              <PageTheme>
                <PlacementFactory {...getPlacementFactoryProps(placementElement)} />
              </PageTheme>,
              placementElement
            )
          )}
          {FOOTER &&
            ReactDOM.createPortal(
              <PageTheme>
                <LandingPageFooter className={getContainerClassName()} />
              </PageTheme>,
              FOOTER
            )}
        </MessageProvider>
      </DotcomApp>
    </BrowserRouter>
  );

  function getDotcomAppProps() {
    return {
      additionalContentParameters: { placementParameters },
      appId: "dotcom-landing-page",
      appPlacementComponents: {
        "manage-my-vacations": ManageMyVacationsForm,
        packages: VacationsPackagesPlacement,
      },
      basePath: "/dotcom-landing-page/",
      bootstrapDependencies,
      defaultAppSettings: {
        default: {
          loginAvailable: true,
          mktgExportSchemas: { ...getMktgExportSchemas() },
          searchAvailable: true,
          themeSelector: false,
        },
      },
      maintenanceCode: "503319996",
    };
  }

  function getPlacementFactoryProps(placementElement) {
    return {
      componentRenderers: {
        vacationsBookingWidget: VacationsBookingWidget,
      },
      id: placementElement.attributes["data-placement-id"].value,
    };
  }

  function getThemeSettings(theme) {
    const themeConfig = {
      business: {
        HeaderTheme: BusinessLightTheme,
        light: true,
        PageTheme: BusinessLightTheme,
      },
      customer: {
        HeaderTheme: CustomerLightTheme,
        PageTheme: CustomerLightTheme,
      },
      vacations: {
        HeaderTheme: VacationsLightTheme,
        light: true,
        menuItemSeparator: "|",
        PageTheme: VacationsLightTheme,
        uppercaseMenuItems: false,
      },
    };

    return themeConfig[theme];
  }

  function getContainerClassName() {
    return classNames(styles.container, {
      [styles.desktopContainer]: isDesktopOnly(),
    });
  }

  function isDesktopOnly() {
    return media.mediumMax === "0px";
  }
};

App.prototype = {
  /** Additional placement parameters to be passed. */
  placementParameters: PropTypes.string,

  /** The name of the theme to use.  */
  themeName: PropTypes.oneOf(["business", "customer", "vacations"]),
};

App.defaultProps = {
  themeName: "customer",
};

export { App };

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useDeviceInfo, window } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import { Transform } from "../Transform";
import styles from "./Adjoin.module.scss";

const DELAY_FOR_HIDE = 100;
const DELAY_FOR_SCROLL = 333;

/**
 * Adjoin provides a way to physically locate two elements so they are adjacent, typically to one
 * side or above/below.
 */

export const Adjoin = (props) => {
  const {
    adjoinedClassName,
    adjoinedContentClassName,
    adjoiningContent,
    adjoinRef,
    adjustToFitCallback,
    alignment,
    children,
    className,
    duration,
    id,
    location,
    mainClassName,
    xOffset,
    yOffset,
  } = props;
  const { gridPadding } = useDeviceInfo();
  const [adjoiningContentPosition, setAdjoiningContentPosition] = useState([0, 0]);
  const [currentLocation, setCurrentLocation] = useState();
  const [previousSize, setPreviousSize] = useState([]);
  const adjoiningContentContainer = useRef(null);
  const mainContentContainer = useRef(null);
  const scrollPosition = useRef();
  const uniqueId = useRef(getUniqueId("adjoin-id"));

  useEffect(() => {
    if (currentLocation === "full-screen") {
      scrollPosition.current = window.scrollY;
      window.scrollTo(0, 0);
    } else if (scrollPosition.current) {
      window.scrollTo(0, scrollPosition.current);
    }
  }, [currentLocation]);

  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  useEffect(() => {
    let timeout;

    // TODO temporary solution until positioning logic can be fortified
    // TODO story to refactor this logic: ECOM-57968
    if (currentLocation === "full-screen") {
      window.scrollTo(0, 0);

      timeout = window.setTimeout(() => {
        setAdjoiningContentPosition(getAdjoiningContentLocation());
      }, DELAY_FOR_SCROLL);
    } else {
      setAdjoiningContentPosition(getAdjoiningContentLocation());
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [alignment, currentLocation, xOffset, yOffset]);

  useEffect(() => {
    if (currentLocation && currentLocation !== "hidden") {
      setPreviousSize([
        getMainContentContainerWidth(),
        getMainContentContainerHeight(),
        getAdjoinContentContainerWidth(),
        getAdjoinContentContainerHeight(),
      ]);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (
      mainContentContainer?.current &&
      (getMainContentContainerWidth() !== previousSize[0] ||
        getMainContentContainerHeight() !== previousSize[1])
    ) {
      setPreviousSize([
        getMainContentContainerWidth(),
        getMainContentContainerHeight(),
        getAdjoinContentContainerWidth(),
        getAdjoinContentContainerHeight(),
      ]);
    }
  });

  useEffect(() => {
    if (mainContentContainer?.current) {
      setAdjoiningContentPosition(getAdjoiningContentLocation());
    }
  }, [previousSize[0], previousSize[1], previousSize[2], previousSize[3]]);

  return (
    <div className={classNames(className, styles.adjoin)} ref={adjoinRef}>
      <div {...getMainContentProps()}>{children}</div>
      <div className={classNames(adjoinedClassName)} id={id}>
        <Transform {...getTransformProps()}>{adjoiningContent}</Transform>
      </div>
    </div>
  );

  function getMainContentProps() {
    return {
      className: classNames(styles.mainContainer, mainClassName),
      ref: mainContentContainer,
    };
  }

  function getTransformProps() {
    return {
      "aria-hidden": currentLocation === "hidden",
      className: classNames(adjoinedContentClassName, styles.adjoiningContent, {
        [styles.adjacentContent]: isAdjacent(),
        [styles.fullScreen]: currentLocation === "full-screen",
        [styles.fullScreenFixed]: currentLocation === "full-screen-fixed",
      }),
      delay: location === "hidden" ? DELAY_FOR_HIDE : 0,
      duration,
      id: uniqueId.current,
      ref: adjoiningContentContainer,
      transformations: getPositionTransformation(),
    };
  }

  function getAdjoiningContentLocation() {
    let locationPosition = [0, 0];

    if (mainContentContainer.current && adjoiningContentContainer.current) {
      const horizontalAlignment = getHorizontalAlignment();
      const verticalAlignment = getVerticalAlignment();
      const locationPositions = {
        above: [
          horizontalAlignment,
          -(getMainContentContainerHeight() + getAdjoinContentContainerHeight()),
        ],
        below: [horizontalAlignment, 0],
        center: [getCenterX(), getCenterY()],
        "center-to-window": [getHorizontalCenterToWindow(), getVerticalCenterToWindow()],
        "center-vertically": [0, getVerticalCenterToWindow()],
        "full-screen": [getFullScreenX(), getFullScreenY()],
        "full-screen-fixed": [0, 0],
        left: [-getAdjoinContentContainerWidth(), verticalAlignment],
        right: [getMainContentContainerWidth(), verticalAlignment],
        "window-top": [0, getVerticalTopOfWindow()],
      };

      if (locationPositions[currentLocation]) {
        locationPosition = locationPositions[currentLocation];
      }
    }

    locationPosition[0] = getFitAdjustment(locationPosition[0]);
    locationPosition[0] += xOffset;
    locationPosition[1] += yOffset;

    return locationPosition;
  }

  function getPositionTransformation() {
    return [getTranslateX(adjoiningContentPosition[0]), getTranslateY(adjoiningContentPosition[1])];
  }

  function getFitAdjustment(x) {
    const mainContainerX = getMainContentContainerX();
    let adjustedX = x;

    if (
      mainContentContainer.current &&
      adjoiningContentContainer.current &&
      (currentLocation === "above" || currentLocation === "below")
    ) {
      if (alignment !== "right" && isRightEdgeOutsideViewport(x)) {
        const rightEdgeOfWindow = getViewportWidth() - gridPadding;
        const newX = -(mainContainerX - (rightEdgeOfWindow - getAdjoinContentContainerWidth()));

        adjustedX = newX;
        adjustToFitCallback && adjustToFitCallback({ x: x - newX, y: 0 });
      } else if (alignment !== "left" && isLeftEdgeOutsideViewport(x)) {
        adjustedX = mainContainerX - gridPadding + x;
        adjustToFitCallback && adjustToFitCallback({ x: adjustedX, y: 0 });
        adjustedX = x - adjustedX;
      }
    }

    return adjustedX;
  }

  function isRightEdgeOutsideViewport(newX) {
    const rightEdgeOfWindow = getViewportWidth() - gridPadding;

    return (
      newX +
        getAdjoiningContentContainerX() +
        getCumulativeTransformations().left +
        getAdjoinContentContainerWidth() >
      rightEdgeOfWindow
    );
  }

  function isAdjacent() {
    return (
      currentLocation !== "center" &&
      currentLocation !== "center-to-window" &&
      currentLocation !== "center-vertically" &&
      currentLocation !== "full-screen" &&
      currentLocation !== "full-screen-fixed" &&
      currentLocation !== "window-top"
    );
  }

  function isLeftEdgeOutsideViewport(newX) {
    return newX + getAdjoiningContentContainerX() < gridPadding;
  }

  function getViewportWidth() {
    return window.document.documentElement.clientWidth;
  }

  function getHorizontalAlignment() {
    let transformation = getCenterX();

    if (alignment === "left") {
      transformation = 0;
    } else if (alignment === "right") {
      transformation = getMainContentContainerWidth() - getAdjoinContentContainerWidth();
    }

    return transformation;
  }

  function getVerticalAlignment() {
    let transformation = getCenterY();

    if (alignment === "bottom") {
      transformation = -getAdjoinContentContainerHeight();
    } else if (alignment === "top") {
      transformation = -getMainContentContainerHeight();
    }

    return transformation;
  }

  function getCenterX() {
    return getMainContentContainerWidth() / 2 - getAdjoinContentContainerWidth() / 2;
  }

  function getCenterY() {
    return -getMainContentContainerHeight() / 2 - getAdjoinContentContainerHeight() / 2;
  }

  function getHorizontalCenterToWindow() {
    const centerOfWindow = window.innerWidth / 2;

    return centerOfWindow - getMainContentContainerX() - getAdjoinContentContainerWidth() / 2;
  }

  function getVerticalCenterToWindow() {
    const adjoinContentY = cumulativeOffset(adjoiningContentContainer.current).top;
    const topOfWindow = getTopOfWindow();
    const targetY = topOfWindow + window.innerHeight / 3;
    const gapAboveAdjoiningContent = window.innerHeight / 3;
    const gapBelowAdjoiningContent =
      window.innerHeight - getAdjoinContentContainerHeight() - gapAboveAdjoiningContent;
    let newY = adjoinContentY - targetY;

    if (gapBelowAdjoiningContent < gapAboveAdjoiningContent) {
      newY =
        adjoinContentY -
        (topOfWindow + (window.innerHeight - getAdjoinContentContainerHeight()) / 2);
    }

    if (adjoinContentY - newY < topOfWindow) {
      newY = adjoinContentY - topOfWindow - gridPadding;
    }

    return -newY;
  }

  function getTopOfWindow() {
    return window.scrollY;
  }

  function getVerticalTopOfWindow() {
    return -(
      cumulativeOffset(mainContentContainer.current).top +
      getMainContentContainerHeight() -
      window.scrollY
    );
  }

  // TODO move this to swa-ui-browser PHX-388
  function cumulativeOffset(element) {
    let left = 0;
    let top = 0;
    let workingElement = element;

    do {
      top += workingElement.offsetTop || 0;
      left += workingElement.offsetLeft || 0;
      workingElement = workingElement.offsetParent;
    } while (workingElement);

    return {
      left,
      top,
    };
  }

  function getFullScreenX() {
    return -(cumulativeOffset(adjoiningContentContainer.current).left - gridPadding);
  }

  function getFullScreenY() {
    const offsetY = cumulativeOffset(adjoiningContentContainer.current).top;
    const transformY = getCumulativeTransformations().top;

    return -(offsetY + transformY - gridPadding) + window.scrollY;
  }

  function getCumulativeTransformations() {
    let element = adjoiningContentContainer.current;
    let transformX = 0;
    let transformY = 0;

    if (element) {
      do {
        element = element.parentNode;

        if (element) {
          try {
            const computedStyle = window.getComputedStyle(element);
            const transform = computedStyle.getPropertyValue("transform");

            if (transform.indexOf("matrix(") !== -1) {
              const transformValues = transform.split(",");

              transformX += parseInt(transformValues[4]);
              transformY += parseInt(transformValues[5]);
            }
            // eslint-disable-next-line no-empty
          } catch {}
        }
      } while (element);
    }

    return {
      left: transformX,
      top: transformY,
    };
  }

  function getAdjoinContentContainerHeight() {
    return adjoiningContentContainer.current.getBoundingClientRect().height;
  }

  function getAdjoinContentContainerWidth() {
    return adjoiningContentContainer.current.getBoundingClientRect().width;
  }

  function getMainContentContainerHeight() {
    return mainContentContainer.current.getBoundingClientRect().height;
  }

  function getMainContentContainerWidth() {
    return mainContentContainer.current.getBoundingClientRect().width;
  }

  function getMainContentContainerX() {
    return mainContentContainer.current.getBoundingClientRect().x;
  }

  function getAdjoiningContentContainerX() {
    const offsetX = cumulativeOffset(adjoiningContentContainer.current).left;

    return Math.max(offsetX, gridPadding);
  }

  function getTranslateX(amount) {
    return {
      action: "translateX",
      amount: `${amount}px`,
    };
  }

  function getTranslateY(amount) {
    return {
      action: "translateY",
      amount: `${amount}px`,
    };
  }
};

export const adjoinPropTypes = {
  /** className to be given to adjoining content container. */
  adjoinedClassName: PropTypes.string,

  /** className to be given to adjoining content. */
  adjoinedContentClassName: PropTypes.string,

  /** Content rendered adjacent to "main" content. */
  adjoiningContent: PropTypes.node.isRequired,

  /** Ref passed to allow useDismiss to close Adjoin on clicking outside of component. */
  adjoinRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),

  /**
   * When the adjoined content is located above or below the main element, and the content cannot
   * fit in window without getting outside the page margins, Adjoin will inform the caller how much
   * the content has to move to fit. When this callback is made, it will receive one object
   * parameter in this format: { x: number, y: number }. x will be negative if the content had to be
   * moved left to fit in the viewport. Currently, y will always be zero.
   */
  adjustToFitCallback: PropTypes.func,

  /** Where adjoiningContent will be placed. */
  alignment: PropTypes.oneOf(["bottom", "left", "center", "right", "top"]),

  /** Content for "main" element. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Length of animation. This value is simply passed through to Transform. For no animation, specify
   * zero. If no value is specified, then the default Spring easing will be used.
   */
  duration: PropTypes.number,

  /** Unique identifier which is passed to container element of flyout. */
  id: PropTypes.string,

  /**
   * Where adjoiningContent will be placed. If last given, adjoinedContent will be render in the same
   *  place it was rendered lastly, or centered if it was not previously rendered.
   */
  location: PropTypes.oneOf([
    "above",
    "below",
    "center",
    "center-to-window",
    "center-vertically",
    "full-screen",
    "full-screen-fixed",
    "hidden",
    "left",
    "right",
    "window-top",
  ]),

  /** className to be given to main content container. */
  mainClassName: PropTypes.string,

  /** Positive or negative value to nudge the adjoining element in the horizontal direction. */
  xOffset: PropTypes.number,

  /** Positive or negative value to nudge the adjoining element vertically. */
  yOffset: PropTypes.number,
};

Adjoin.propTypes = adjoinPropTypes;
Adjoin.defaultProps = {
  duration: 20,
  location: "hidden",
  xOffset: 0,
  yOffset: 0,
};

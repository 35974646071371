import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { getFocusableItems, window } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import styles from "./focusContainer.module.scss";

const COUNT_FROM_END_BEFORE_GUARDRAIL = 2;
const DELAY_FOR_ANIMATION = 500;
const INDEX_AFTER_GUARDRAIL = 1;
const MIN_ELEMENT_COUNT = 3;

/**
 * Component that "constraints" the tabOrder so that tabbing will not cause focus to go outside
 * FocusContainer's children.
 */
export const FocusContainer = (props) => {
  const { children, className, doc, id, revealed } = props;
  const uniqueId = useRef(id || getUniqueId("focusContainer-id"));
  const scopeSelector = `[id="${uniqueId.current}"]`;
  let timer;

  useEffect(() => {
    if (revealed) {
      timer = window.setTimeout(() => {
        focusSecondItem();
      }, DELAY_FOR_ANIMATION);
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [revealed]);

  return (
    <div className={classNames(className, styles.focusContainer)} id={uniqueId.current}>
      <div {...getGuardrailProps(handleFirstFocus)}>first</div>
      {children}
      <div {...getGuardrailProps(handleLastFocus)}>last</div>
    </div>
  );

  function getGuardrailProps(focusHandler) {
    return {
      "aria-hidden": props["aria-hidden"] || !revealed,
      className: globalRules.hiddenFromScreen,
      onFocus: focusHandler,
      tabIndex: "0",
    };
  }

  function handleFirstFocus(event) {
    const focusableItems = getFocusableItems(scopeSelector, doc);
    const itemCount = focusableItems?.length || 0;

    if (focusableItems?.length >= MIN_ELEMENT_COUNT) {
      if (event.relatedTarget === focusableItems[1]) {
        focusableItems[itemCount - COUNT_FROM_END_BEFORE_GUARDRAIL].focus();
      } else {
        focusableItems[1].focus();
      }
    }
  }

  function handleLastFocus(event) {
    const focusableItems = getFocusableItems(scopeSelector, doc);
    const itemCount = focusableItems?.length || 0;

    if (event.relatedTarget === focusableItems[itemCount - COUNT_FROM_END_BEFORE_GUARDRAIL]) {
      focusSecondItem();
    }
  }

  function focusSecondItem() {
    const focusableItems = getFocusableItems(scopeSelector, doc);

    if (focusableItems?.length >= MIN_ELEMENT_COUNT) {
      focusableItems[INDEX_AFTER_GUARDRAIL].focus();
    }
  }
};

FocusContainer.propTypes = {
  /** Controls the aria-hidden attribute to be applied to the guardrail element and set the tab index. */
  "aria-hidden": PropTypes.bool,

  /** Content that will be rendered in heading. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * By default this will the global document object, but can be overridden to support iFrames. This
   * is used by Caption to get a reference to a DOM element.
   */
  doc: PropTypes.object,

  /** Prop tells FocusContainer to use focus constraint when reveal is set. */
  revealed: PropTypes.bool,
};

import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { isResponseOk, timeouts, useFetch } from "@swa-ui/fetch";
import { usePersonas } from "@swa-ui/personas";

import { applyOverrides } from "../applyOverrides";
import { commonPlacementComponents } from "../defines";
import { PlacementsContext } from "../PlacementsContext";

/**
 * PlacementsProvider makes a network call to bootstrap-content to load placements.
 */

export const PlacementsProvider = (props) => {
  const {
    additionalContentParameters = {},
    appId,
    channelId,
    children,
    defaultOnly,
    defaultPlacements,
    globalPlacementIds,
    lang,
    pageId,
    placementsConfig,
    placementComponents,
    screenSize,
    site,
    updateOnPageLoad,
  } = props;
  const [globalPlacements, setGlobalPlacements] = useState({});
  const [loading, setLoading] = useState({ current: {}, last: {} });
  const [complete, setComplete] = useState({ current: { ok: false } });
  const [placementsAnalytics, setPlacementsAnalytics] = useState({});
  const [pathPlacements, setPathPlacements] = useState({});
  const appContextRef = useRef({});
  const pageSegments = useRef([]);
  const { pathname: currentPathname } = useLocation();
  const { persona } = usePersonas();
  const { error, post, response } = useFetch("", {
    cacheLife: 0,
    cachePolicy: "no-cache",
    timeout: timeouts.noncritical,
  });
  const placements = useMemo(getPlacements, [
    currentPathname,
    defaultPlacements,
    globalPlacements,
    pathPlacements,
    screenSize,
  ]);
  const lastAppContextPathname = useRef(currentPathname);
  const { loginState } = additionalContentParameters;
  const loginStateRef = useRef(loginState) ?? "false";
  const previousLoginState = loginStateRef?.current;

  // TODO: Refactor once rules engine is available (ECOM-57531).
  useEffect(() => {
    if ((!loginState || loginState === "false") && previousLoginState !== loginState) {
      appContextRef.current = {};
    }
    resetSegments();
    loginStateRef.current = loginState;
  }, [loginState]);

  useEffect(() => {
    resetSegments();

    if (updateOnPageLoad && currentPathname !== loading.last.pathname) {
      loadPlacements(currentPathname);
    }
  }, [currentPathname]);

  useEffect(() => {
    if (loading.current.pathname) {
      if (!reloadingForTheSamePath()) {
        clearPlacementsForPath(loading.current.pathname);
      }
      retrievePlacements();
    }
  }, [loading]);

  return (
    <PlacementsContext.Provider value={getContextValue()}>{children}</PlacementsContext.Provider>
  );

  function getContextValue() {
    return {
      appContextMap: appContextRef?.current,
      complete,
      error,
      loading,
      loadPlacements,
      placementComponents: {
        ...commonPlacementComponents,
        ...placementComponents,
      },
      placements,
      placementsAnalytics,
      placementsConfig,
      setPlacementsAppContext,
      setPlacementsAnalytics: handlePlacementsAnalytics,
    };
  }

  /**
   * Sets the placementAnalytics correctly based on the app ID
   *
   * @param {Object} placementAnalyticsData - Will set contextvalue for placement specific analytics correctly
   *
   */

  function handlePlacementsAnalytics(placementAnalyticsData) {
    setPlacementsAnalytics(placementAnalyticsData);
  }

  function getPlacements() {
    const allPlacements = {
      ...defaultPlacements?.[appId]?.results,
      ...defaultPlacements?.[pageId]?.results,
      ...globalPlacements,
      ...pathPlacements[currentPathname],
    };

    return applyOverrides(allPlacements, screenSize);
  }

  function resetSegments() {
    pageSegments.current = [];
    setLoading((loadingState) => ({
      ...loadingState,
      current: { context: { ...loadingState.current.context, segment: pageSegments.current } },
    }));
  }

  /**
   * Loads placements for pathname
   *
   * @param {string} pathname - path of current page
   * @param {Object} context - additional context to be sent in placements request
   */
  function loadPlacements(pathname, context = {}) {
    setPageSegments(context.segment, pathname);

    setLoading({
      ...loading,
      current: {
        context: {
          ...additionalContentParameters,
          segment: pageSegments.current,
          ...context,
        },
        pathname,
      },
    });
  }

  /**
   * Sets appContext to be sent in placements request
   * @param {Object} appContext - will be sent in placements request when set
   * @example {promotions:["123", "456"]}
   */
  function setPlacementsAppContext(appContext) {
    if (currentPathname !== lastAppContextPathname.current) {
      appContextRef.current = {};
      lastAppContextPathname.current = currentPathname;
    }
    appContextRef.current = {
      ...appContextRef.current,
      ...appContext,
    };
  }

  /**
   * Sets & Concates the segments for the current page
   *
   * @param {Array} segment - will be sent in placements request when set
   */

  function setPageSegments(segment, segmentPathname) {
    if (segment && currentPathname === segmentPathname) {
      pageSegments.current = [...new Set([...pageSegments.current, ...segment])];
    }
  }

  async function retrievePlacements() {
    if (!defaultOnly) {
      const { context } = loading.current;
      const body = await post("/api/content-delivery/v1/content-delivery/query/placements", {
        ...context,
        ...(Object.keys(appContextRef?.current)?.length && {
          appContext: getPlacementsAppContext(),
        }),
        appId,
        channelId,
        lang,
        pageId,
        persona,
        screenSize,
        site,
        uiType: "nextgen",
      });

      if (isResponseOk(response) && body.success) {
        const { newGlobalPlacements, newPathPlacements } = splitPlacements(body.results);

        setGlobalPlacements({
          ...globalPlacements,
          ...newGlobalPlacements,
        });
        setPathPlacements({
          ...pathPlacements,
          [loading.current.pathname]: newPathPlacements,
        });
      }
    }

    setLoading({
      current: {},
      last: loading.current,
    });

    setComplete({
      current: { ok: isResponseOk(response) || !!defaultPlacements },
    });
  }

  function getPlacementsAppContext() {
    return Array.from(new Set(Object.values(appContextRef.current).flat()));
  }

  function reloadingForTheSamePath() {
    return (
      loading.current.pathname === currentPathname &&
      loading.current.pathname === loading.last.pathname
    );
  }

  function clearPlacementsForPath(path) {
    const newPathPlacements = {
      ...pathPlacements,
    };

    delete newPathPlacements[path];
    setPathPlacements(newPathPlacements);
  }

  function splitPlacements(newPlacements) {
    let newGlobalPlacements = {};
    let newPathPlacements;

    if (globalPlacementIds.length === 0) {
      newPathPlacements = newPlacements;
    } else {
      newGlobalPlacements = pickBy(newPlacements, ([id]) => globalPlacementIds.includes(id));
      newPathPlacements = pickBy(newPlacements, ([id]) => !globalPlacementIds.includes(id));
    }

    return { newGlobalPlacements, newPathPlacements };
  }

  function pickBy(object, predicate) {
    return Object.fromEntries(Object.entries(object).filter(predicate));
  }
};

PlacementsProvider.propTypes = {
  /**
   * String that identifies the app for when making requests. The value, something like: 'gift-card', is added to
   * requests.
   */
  appId: PropTypes.string.isRequired,

  /**
   * String that identifies the channel for when making requests. The value, something like: 'southwest' or 'ios', is
   * added to requests.
   */
  channelId: PropTypes.string,

  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,

  /** Suppresses the provider's ability to make a network call to retrieve placement data. */
  defaultOnly: PropTypes.bool,

  /**
   * Default placements objects used if override placements are not retrieved from the placements call.
   */
  defaultPlacements: PropTypes.object,

  /**
   * placementIds that should be persisted between placement calls
   */
  globalPlacementIds: PropTypes.arrayOf(PropTypes.string),

  /**
   * Current language the application is running in.  Usually one of 'en' or 'es'.
   */
  lang: PropTypes.string,

  /**
   * Page ID of current route, used to make proper network call to placements service
   */
  pageId: PropTypes.string,

  /**
   * Components used to render placement data based on the the displayType.
   */
  placementComponents: PropTypes.object,

  /**
   * Current screen size the application is running in.  Usually one of 'small', 'medium', 'large' or 'xlarge'.
   */
  screenSize: PropTypes.string,

  /**
   * Current site the application is running on.  Sample values like 'southwest', 'corporate' or 'nonrev'
   */
  site: PropTypes.string,

  /**
   * Should placements be updated automatically on each page load
   */
  updateOnPageLoad: PropTypes.bool,
};

PlacementsProvider.defaultProps = {
  globalPlacementIds: [],
  pageIdPrefix: "",
  updateOnPageLoad: true,
};

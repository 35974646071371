import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { useResizeObserver } from "@swa-ui/browser";
import { useDeviceInfo } from "@swa-ui/browser";
import { ListMatrix } from "@swa-ui/core";

import { PlacementFactory } from "../PlacementFactory";
import styles from "./ContentSection.module.scss";

export const ContentSection = (props) => {
  const { componentRenderers, placement, settings } = props;
  const { background, items } = placement ?? {};
  const { numberOfColumns } = settings ?? {};
  const childRef = useRef(null);
  const [childHeight, setChildHeight] = useState(0);

  useResizeObserver({ callback: updateHeight, element: childRef });

  return (
    <div className={styles.container} style={getBackgroundStyle()}>
      <div ref={childRef}>
        <ListMatrix {...getListMatrixProps()} />
      </div>
    </div>
  );

  function renderItem(item) {
    const { fullWidth, id, type } = item;

    const content =
      type === "placement" ? (
        <PlacementFactory additionalProps={{ fullWidth }} id={id} shouldWait={false} />
      ) : (
        componentRenderers?.[id]?.()
      );

    return content && <>{content}</>;
  }

  function getListMatrixProps() {
    return {
      center: true,
      items: items?.map((item) => ({
        content: renderItem(item),
      })),
      numberOfColumns: numberOfColumns,
      rowClassName: styles.listRow,
    };
  }

  function getBackgroundStyle() {
    const { color, imagePath, minContentSectionHeight, type } = background;
    const minHeight = childHeight < minContentSectionHeight ? minContentSectionHeight : childHeight;

    return {
      ...(type === "image" && { backgroundImage: `url(${imagePath})` }),
      ...(type === "color" && { backgroundColor: color }),
      minHeight: `${minHeight}px`,
    };
  }

  function updateHeight(value) {
    setChildHeight(value[0].contentRect.height);
  }
};

ContentSection.propTypes = {
  /** Callback functions with custom registered components. */
  componentRenderers: PropTypes.object,

  /** Placement object for child placement ids and components. */
  placement: PropTypes.shape({
    /** Background image/color/none content for the section. */
    background: PropTypes.shape({
      color: PropTypes.string,
      imagePath: PropTypes.string,
      minContentSectionHeight: PropTypes.number,
      overrides: PropTypes.object,
      type: PropTypes.oneOf(["color", "image", "none"]),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),

  /** Additional control over layout of placements and components. */
  settings: PropTypes.shape({
    numberOfColumns: PropTypes.number.isRequired,
    overrides: PropTypes.object,
  }),
};

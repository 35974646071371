import PropTypes from "prop-types";
import React, { useContext } from "react";

import { classNames } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import { ConfigurationContext } from "../ConfigurationContext";
import styles from "./Heading.module.scss";

const DEFAULT_HEADING_LEVEL = 2;

/**
 * Heading creates a heading element for the specified heading level (h1-h6). Using this component
 * to create headings will provide consistent styling across applications. An optional prop may be
 * given to specify one of the six predefined style treatments, or the default style for the given
 * level will be used.
 */

export const Heading = (props) => {
  const {
    bottomSpacing,
    children,
    className,
    ellipsis,
    headingLevel,
    light,
    styleLevel,
    topSpacing,
  } = props;
  const { nestedHeadingLevel } = useContext(ConfigurationContext) ?? {};
  const Element = `h${headingLevel || nestedHeadingLevel || DEFAULT_HEADING_LEVEL}`;

  return <Element className={getClass()}>{children}</Element>;

  function getClass() {
    const styleProminence =
      styleLevel || headingLevel || nestedHeadingLevel || DEFAULT_HEADING_LEVEL;

    return classNames(className, styles.heading, {
      [globalRules.bottomLarge]: bottomSpacing === "large",
      [globalRules.bottomMedium]: bottomSpacing === "medium",
      [globalRules.bottomNone]: bottomSpacing === "none",
      [globalRules.bottomSmall]: bottomSpacing === "small",
      [globalRules.bottomXlarge]: bottomSpacing === "xLarge",
      [globalRules.bottomXsmall]: bottomSpacing === "xSmall",
      [globalRules.topLarge]: topSpacing === "large",
      [globalRules.topMedium]: topSpacing === "medium",
      [globalRules.topNone]: topSpacing === "none",
      [globalRules.topSmall]: topSpacing === "small",
      [globalRules.topXlarge]: topSpacing === "xLarge",
      [globalRules.topXsmall]: topSpacing === "xSmall",
      // TODO: Update styleProminence to match new typography sizes and ingest into apps
      [styles.xLarge]: styleProminence === 1,
      [styles.large]: styleProminence === 2,
      [styles.medium]: false,
      [styles.small]: styleProminence === 3,
      [styles.xsmall]: styleProminence === 4,
      [styles.ellipsis]: ellipsis,
      [styles.light]: light,
    });
  }
};

export const headingPropTypes = {
  /** The amount of space after the heading can be set to one of the predefined values. */
  bottomSpacing: PropTypes.oneOf(["large", "medium", "none", "small", "xLarge", "xSmall"]),

  /** Content that will be rendered in heading. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Indicates if Heading should use ellipsis, when Heading extends past the width of the container.
   */
  ellipsis: PropTypes.bool,

  /** Indicates which heading to create: h1, h2, h3... */
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),

  /**
   * Light prop indicates if the Heading will be rendered on a dark background, and is not affected by the theme --
   * that is, the background will always be dark regardless of the theme background.
   */
  light: PropTypes.bool,

  /**
   * Optional "override" of default styling that will be applied to the heading. The caller can specify how prominent
   * the style of the heading will be, or allow the style to be defaulted. styleLevel 1 is the most prominent.
   */
  styleLevel: PropTypes.oneOf([1, 2, 3, 4]),

  /** The amount of space before the heading can be set to one of the predefined values. */
  topSpacing: PropTypes.oneOf(["large", "medium", "none", "small", "xLarge", "xSmall"]),
};

Heading.propTypes = headingPropTypes;

Heading.defaultProps = {
  bottomSpacing: "none",
  light: false,
  topSpacing: "none",
};

import PropTypes from "prop-types";
import React, { createContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { sendToAdobeDataLayer, sendToDataLayer } from "@swa-ui/analytics";
import { merge } from "@swa-ui/object";

import { useAppSetting } from "../useAppSetting";

/**
 * Data layer responsible for maintaining data that will be used by analytics, Chase, mbox and placements solutions.
 * This data will not be directly accessed by Chase, mbox and placements solutions.  It will be accessed by the
 * MktgMediator and the data will be passed to the load functions, thus further reducing coupling and allowing use
 * to easy change the data layer at any time.
 * */
export const MktgDataContext = createContext();
export const MktgDataProvider = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const [adobeGlobalData, setAdobeGlobalData] = useState({});
  const [adobePageData, setAdobeDataState] = useState({ impressions: [] });
  const [mktgEventData, setMktgEventDataState] = useState({});
  const [mktgGlobalData, setMktgGlobalData] = useState({});
  const [mktgPageData, setMktgPageDataState] = useState({});
  const mktgExport = useAppSetting("mktgExportSchemas");

  useLayoutEffect(() => {
    setAdobeDataState({ impressions: [] });
    setMktgEventData({});
    setMktgPageDataState({});
  }, [pathname]);

  useEffect(() => {
    sendToDataLayer(getMktgData());
  }, [mktgGlobalData, mktgPageData]);

  return (
    <MktgDataContext.Provider
      value={{
        adobeData: getAdobeData(),
        mktgData: getMktgData(),
        sendAdobeEventData,
        setAdobePageData,
        setMktgEventData,
        setMktgPageData,
        updateAdobeGlobalData,
        updateMktgGlobalData,
      }}
    >
      {children}
    </MktgDataContext.Provider>
  );

  function getMktgData() {
    return { ...mktgGlobalData, ...mktgPageData, ...mktgEventData };
  }

  function updateMktgGlobalData(globalData, exportSchemaName = null) {
    setMktgGlobalData((prevMktgGlobalData) => ({
      ...prevMktgGlobalData,
      ...castData(globalData, exportSchemaName),
    }));
  }

  function updateAdobeGlobalData(globalData, exportSchemaName = null) {
    const castedData = castData(globalData, exportSchemaName);

    setAdobeGlobalData((prevAdobeGlobalData) => ({
      ...prevAdobeGlobalData,
      ...castedData,
    }));
  }

  function setMktgPageData(pageData, exportSchemaName = null) {
    setMktgPageDataState((prevMktgPageData) => ({
      ...prevMktgPageData,
      ...castData(pageData, exportSchemaName),
    }));
  }

  function setMktgEventData(pageData, exportSchemaName = null) {
    setMktgEventDataState(castData(pageData, exportSchemaName));
  }

  function castData(data, exportSchemaName) {
    return shouldCastData(exportSchemaName)
      ? getExportSchema(exportSchemaName).noUnknown().cast(data)
      : data;
  }

  function shouldCastData(exportSchemaName) {
    const exportSchema = getExportSchema(exportSchemaName);

    return exportSchemaName && exportSchema?.noUnknown && exportSchema?.cast;
  }

  function getAdobeData() {
    return { ...adobePageData, ...adobeGlobalData };
  }

  function sendAdobeEventData(eventData, exportSchemaName = null) {
    const castedData = castData(eventData, exportSchemaName);

    sendToAdobeDataLayer({ ...castedData, ...adobeGlobalData });
  }

  function setAdobePageData(pageData, exportSchemaName = null, impressions = []) {
    const castedData = castData({ ...pageData, impressions: impressions }, exportSchemaName);

    setAdobeDataState((prevAdobeDataState) => merge(prevAdobeDataState, castedData));
  }

  function getExportSchema(exportSchemaName) {
    return mktgExport?.[exportSchemaName];
  }
};

MktgDataContext.displayName = "MktgDataContext";
MktgDataProvider.propTypes = {
  /** Children that will have access to update and read mktg data. */
  children: PropTypes.node.isRequired,
};

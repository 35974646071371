import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Transform } from "../Transform";
import styles from "./Digit.module.scss";

const FONT_SIZES = ["large", "medium", "small", "xlarge", "xsmall"];

/**
 * Digit is a helper component to display a single digit for Timer and NumberSelector.
 */
export const Digit = React.memo((props) => {
  const { className, emphasis, fontSize, maxNumber, number } = props;
  const [forceUpdate, setForceUpdate] = useState(false);
  const currentNumber = useRef(number);
  const resetInProgress = useRef(false);

  useEffect(() => {
    currentNumber.current = Math.min(number, maxNumber);
  });

  return (
    <div {...getProps()}>
      <Transform {...getTransformProps()}>
        <ul className={styles.numberList}>
          {renderNumbers()}
          <li>0</li>
        </ul>
      </Transform>
    </div>
  );

  function renderNumbers() {
    return Array.from({ length: maxNumber + 1 }, (_, index) => <li key={index}>{index}</li>);
  }

  function getProps() {
    return {
      className: classNames(className, styles.container, { [styles.emphasis]: emphasis }),
      style: getStyle(),
    };
  }

  function getTransformProps() {
    const lineHeight = fontSize * 1.5;
    const normalizedNumber = Math.min(number, maxNumber);
    let offset = normalizedNumber * lineHeight;

    if (normalizedNumber === maxNumber && currentNumber.current === 0) {
      offset = (maxNumber + 1) * lineHeight;
      resetInProgress.current = true;
    }

    return {
      duration: resetInProgress.current ? 0.00001 : undefined,
      onTransformationEnd: resetInProgress.current ? handleTransformationEnd : undefined,
      precision: 0.1,
      transformations: [{ action: "translateY", amount: `-${offset}px` }],
    };
  }

  function handleTransformationEnd() {
    if (resetInProgress.current) {
      resetInProgress.current = false;
      setForceUpdate(!forceUpdate);
    }
  }

  function getStyle() {
    return { "--font-size": fontSize };
  }
});

Digit.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Defines if the number will be bold or normal weight. */
  emphasis: PropTypes.bool,

  /** Size for numbers. */
  fontSize: PropTypes.oneOfType([PropTypes.oneOf(FONT_SIZES), PropTypes.number]),

  /**
   * Last possible number that will be displayed for the digit. This is needed because some "digit columns" will show
   * numbers 0-9 while others will only display 0-5.
   */
  maxNumber: PropTypes.number,

  /** Number that should be shown. This number is used to calculate the translateY position for the "digit column". */
  number: PropTypes.number,
};

Digit.defaultProps = {
  emphasis: true,
  fontSize: 30,
  maxNumber: 9,
  number: 0,
};

import { useContext, useEffect } from "react";

import { MboxContext } from "@swa-ui/application";
import { window } from "@swa-ui/browser";

const DOCUMENT = window.document;

export const MboxScripts = () => {
  const { mboxes } = useContext(MboxContext);

  useEffect(() => {
    mboxes?.scripts?.forEach((mboxScript) => {
      const script = DOCUMENT.createElement("script");

      script.text = mboxScript.replace(/<\/?script>/g, "");
      DOCUMENT.body.appendChild(script);
    });
  }, [mboxes]);

  return null;
};

import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Transform } from "../Transform";
import styles from "./SelectionMarker.module.scss";

const HIGHLIGHT_MOVEMENT_DURATION = 150;

/**
 * SelectionMarker renders a div with a color treatment to indicate a highlighted area. Currently
 * only used internally by the fore for DropDown and NumberSelectorGroup.
 */
export const SelectionMarker = (props) => {
  const { className, height, yOffset } = props;

  return <Transform {...getSelectionMarkerProps()} />;

  function getSelectionMarkerProps() {
    return {
      className: classNames(styles.selectionMarker, className),
      duration: HIGHLIGHT_MOVEMENT_DURATION,
      transformations: getMovementLocation(),
    };
  }

  function getMovementLocation() {
    return [
      {
        action: "translateY",
        amount: `${yOffset}px`,
      },
      {
        action: "height",
        amount: `${height}px`,
      },
    ];
  }
};

SelectionMarker.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Height of selected item that will be highlighted. */
  height: PropTypes.number,

  /** Index of where highlight will be displayed. */
  yOffset: PropTypes.number.isRequired,
};

SelectionMarker.defaultProps = {
  height: 24,
  yOffset: 0,
};

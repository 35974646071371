export const defaultRules = [
  {
    rule: "fireOtterWhenContentUpdatesOrUserLogout",
  },
  {
    rule: "loadMboxesAndPlacementsWhenPathnameChangesOrUserLoginOrUserLogout",
  },
  {
    rule: "adobeDataLayerPageLoadEvent",
  },
  {
    rule: "adobeDataLayerEvent",
  },
];

export const defaultRulesDictionary = {
  accountNumberChanged: {
    fact: "mktg_data",
    operator: "notEqual",
    path: "$.member_number",
    value: {
      fact: "last_mktg_data",
      path: "$.member_number",
    },
  },
  adobeDataEventNameUpdated: {
    fact: "adobeDataLayer",
    operator: "notEqual",
    path: "$.event",
    value: {
      fact: "last_adobeDataLayer",
      path: "$.event",
    },
  },
  adobeDataLayerEvent: {
    conditions: {
      all: [
        {
          condition: "adobeDataPresent",
        },
        {
          condition: "pathNotBlankRoute",
        },
        {
          condition: "adobeDataEventNameUpdated",
        },
      ],
    },
    event: {
      params: {
        delay: 250,
        fact: "adobeDataLayer",
        path: "event",
      },
      type: "fireAdobeDataLayerEvent",
    },
  },
  adobeDataLayerPageLoadEvent: {
    conditions: {
      all: [
        {
          condition: "adobeDataPresent",
        },
        {
          condition: "updateCycleDone",
        },
      ],
    },
    event: {
      params: {
        delay: 200,
        value: "pageLoad",
      },
      type: "fireAdobeDataLayerEvent",
    },
  },
  adobeDataPresent: {
    fact: "adobeDataLayer",
    operator: "isNotEmpty",
    value: null,
  },
  fireOtterWhenContentUpdatesOrUserLogout: {
    conditions: {
      all: [
        {
          condition: "mktgDataPresent",
        },
        {
          condition: "pathNotBlankRoute",
        },
        {
          any: [
            {
              condition: "updateCycleDone",
            },
          ],
        },
      ],
    },
    event: {
      params: {
        delay: 200,
      },
      type: "fireOtterCall",
    },
  },
  lastLoginStateIsNotUndefined: {
    fact: "last_mktg_data",
    operator: "isNotUndefined",
    path: "$.user_loginstate",
    value: null,
  },
  lastPathIsBlank: {
    fact: "last_pathname",
    operator: "matches",
    value: "/blank$",
  },
  loadMboxesAndPlacementsWhenPathnameChangesOrUserLoginOrUserLogout: {
    conditions: {
      all: [
        {
          condition: "mktgDataPresent",
        },
        {
          condition: "pathNotBlankRoute",
        },
        {
          condition: "placementsContextReady",
        },
        {
          not: {
            condition: "placementsBlocked",
          },
        },
        {
          any: [
            {
              all: [
                {
                  condition: "notLoadingNorLoadedForCurrentPathname",
                },
                {
                  not: {
                    condition: "pathChanged", // prevents duplicate call on route change
                  },
                },
              ],
            },
            {
              condition: "userLoggedIn",
            },
            {
              condition: "userLoggedOut",
            },
            {
              condition: "lastPathIsBlank",
            },
          ],
        },
      ],
    },
    event: {
      type: "loadMboxesAndPlacements",
    },
  },
  loadMboxesWhenPathnameChangesOrUserLoginOrUserLogout: {
    conditions: {
      all: [
        {
          condition: "mktgDataPresent",
        },
        {
          condition: "pathNotBlankRoute",
        },
        {
          condition: "placementsBlocked",
        },
        {
          any: [
            {
              condition: "notLoadingNorLoadedForCurrentPathname",
            },
            {
              condition: "userLoggedIn",
            },
            {
              condition: "userLoggedOut",
            },
            {
              condition: "lastPathIsBlank",
            },
          ],
        },
      ],
    },
    event: {
      type: "loadMboxes",
    },
  },
  loginStateCold: {
    fact: "mktg_data",
    operator: "equal",
    path: "$.user_loginstate",
    value: "cold",
  },
  loginStateHot: {
    fact: "mktg_data",
    operator: "equal",
    path: "$.user_loginstate",
    value: "hot",
  },
  mktgDataPresent: {
    fact: "mktg_data",
    operator: "isNotEmpty",
    value: null,
  },
  notLoadingNorLoadedForCurrentPathname: {
    all: [
      {
        fact: "pathname",
        operator: "notEqual",
        value: {
          fact: "loadedPathname",
        },
      },
      {
        fact: "pathname",
        operator: "notEqual",
        value: {
          fact: "loadingPathname",
        },
      },
    ],
  },
  pathChanged: {
    fact: "pathname",
    operator: "notEqual",
    value: {
      fact: "last_pathname",
    },
  },
  pathNotBlankRoute: {
    fact: "pathname",
    operator: "doesNotMatch",
    value: "/blank$",
  },
  placementsBlocked: {
    fact: "placementsBlocked",
    operator: "equal",
    value: true,
  },
  placementsContextReady: {
    fact: "isDefault",
    operator: "equal",
    value: true,
  },
  recordPathnameChangedTime: {
    conditions: {
      any: [
        {
          fact: "pathname",
          operator: "notEqual",
          value: {
            fact: "last_pathname",
          },
        },
      ],
    },
    event: {
      params: {
        eventName: "pathnameChangedTime",
      },
      type: "recordEventTime",
    },
  },
  recordTwoSecondsAfterPathnameChangedTime: {
    conditions: {
      any: [
        {
          fact: "pathname",
          operator: "notEqual",
          value: {
            fact: "last_pathname",
          },
        },
      ],
    },
    event: {
      params: {
        delay: 2000,
        eventName: "twoSecondsAfterThePathnameChangedTime",
      },
      type: "recordEventTime",
    },
  },
  reloadPageWhenUserLoginOrUserLogout: {
    conditions: {
      any: [
        {
          condition: "userLoggedIn",
        },
        {
          condition: "userLoggedOut",
        },
      ],
    },
    event: {
      type: "reloadPage",
    },
  },
  updateCycleDone: {
    all: [
      {
        fact: "loadingPathname",
        operator: "notEqual",
        value: {
          fact: "last_loadingPathname",
        },
      },
      {
        fact: "loadingPathname",
        operator: "isUndefined",
        value: null,
      },
    ],
  },
  userLoggedIn: {
    all: [
      {
        condition: "accountNumberChanged",
      },
      {
        condition: "lastLoginStateIsNotUndefined",
      },
      {
        condition: "loginStateHot",
      },
    ],
  },
  userLoggedOut: {
    all: [
      {
        condition: "accountNumberChanged",
      },
      {
        condition: "lastLoginStateIsNotUndefined",
      },
      {
        condition: "loginStateCold",
      },
    ],
  },
};

import PropTypes from "prop-types";
import React from "react";

import { Drawer } from "../Drawer";
import { Layout } from "../Layout";
import styles from "./LineClamp.module.scss";

const POINTER_X_OFFSET = -13;
const Y_OFFSET = -15;

/**
 * Provides a way to restrict number of lines contained in a div element. Content that won't fit
 * in given number of lines will be truncated and represented by ellipsis.
 */
export const LineClamp = React.forwardRef((props, ref) => {
  const { children, className, lineCount, textContent } = props;

  return (
    <Drawer {...getProps()}>
      <Layout bottom="medium" sides top="medium">
        {textContent}
      </Layout>
    </Drawer>
  );

  function renderContent() {
    return <span className={styles.content}>{children}</span>;
  }

  function getProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      captionProps: {
        pointerAlignment: "right",
        pointerXOffset: POINTER_X_OFFSET,
        yOffset: Y_OFFSET,
      },
      className,
      ref,
      showPointer: true,
      style: getStyle(),
      triggerClass: styles.lineClamp,
      triggerComponent: "div",
      triggerContent: renderContent(),
      triggerOnHover: true,
    };
  }

  function getStyle() {
    return { "--line-clamp": lineCount, "--word-break": lineCount === 1 ? "break-all" : "normal" };
  }
});

LineClamp.propTypes = {
  /**
   * aria-describedby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label": PropTypes.string,

  /**
   * aria-labelledby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-labelledby": PropTypes.string,

  /** Content that will be rendered on the button. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Number of lines to restrict content. */
  lineCount: PropTypes.number,
};

LineClamp.defaultProps = {
  lineCount: 3,
};

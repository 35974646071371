import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useAppSetting } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { AriaLive, CardGrid, Link } from "@swa-ui/core";
import { swaDate } from "@swa-ui/date";
import { Form, SubmitFormField, TextFormField, useForm, yupResolver } from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { MfaHeading } from "../MfaHeading";
import styles from "./MfaEnterPasscodeForm.module.scss";
import { fieldMaxLengths, getMfaEnterPasscodeFormSchema } from "./MfaEnterPasscodeForm.schema";

/**
 * MfaEnterPasscodeForm component is used to collect the passcode from the user.
 */
export const MfaEnterPasscodeForm = (props) => {
  const { contact, invalidPasscode, loading, onResend, onSubmit, passwordResendSuccess } = props;
  const mfaResendDelayInSeconds = useAppSetting("mfaResendDelayInSeconds", 60);
  const [secondsUntilResend, setSecondsUntilResend] = useState(mfaResendDelayInSeconds);
  const formMethods = useForm({ resolver: yupResolver(getMfaEnterPasscodeFormSchema()) });
  const { isValid } = formMethods.formState;
  const { MFA_HELP } = getBootstrapData("urls");

  useEffect(() => {
    if (secondsUntilResend > 0) {
      setTimeout(() => {
        setSecondsUntilResend(secondsUntilResend - 1);
      }, 1000);
    }
  }, [secondsUntilResend]);

  useEffect(() => {
    if (passwordResendSuccess) {
      setSecondsUntilResend(mfaResendDelayInSeconds);
      formMethods.clearErrors("passcode");
      formMethods.setFocus("passcode");
      formMethods.setValue("passcode", "");
    }
  }, [passwordResendSuccess]);

  useEffect(() => {
    if (invalidPasscode) {
      formMethods.setError("passcode", {
        message: i18n("MfaEnterPasscodeForm__INVALID_PASSCODE"),
      });
    }
  }, [invalidPasscode]);

  return (
    <CardGrid>
      <MfaHeading
        heading={i18n("MfaEnterPasscodeForm__HEADING")}
        subheading={
          <div>
            <span>{i18n(`MfaEnterPasscodeForm__PROMPT_${contact.deviceType}`)}</span>{" "}
            <span className={styles.contactValue}>{contact.value}</span>
          </div>
        }
      />
      <Form methods={formMethods} onSubmit={onSubmit} noValidate>
        <TextFormField {...getPasscodeProps()} />
        <div className={styles.fullWidth}>{i18n("MfaEnterPasscodeForm__EXPIRATION_NOTICE")}</div>
        <div className={styles.fullWidth}>
          {i18n("MfaEnterPasscodeForm__RESEND_PROMPT")} {renderResendLink()}
        </div>
        {passwordResendSuccess && secondsUntilResend > 0 && (
          <div className={styles.fullWidth}>
            <AriaLive hiddenFromScreen={false}>
              {i18n(`MfaEnterPasscodeForm__RESEND_SUCCESS_${contact.deviceType}`)}
            </AriaLive>
          </div>
        )}
        <Link {...getNeedHelpLinkProps()}>{i18n("MfaContactSelectForm__NEED_HELP")}</Link>
        <SubmitFormField {...getSubmitProps()}>
          {i18n("MfaEnterPasscodeForm__SUBMIT")}
        </SubmitFormField>
      </Form>
    </CardGrid>
  );

  function renderResendLink() {
    let resendLink = (
      <span>
        {i18n("MfaEnterPasscodeForm__RESEND_PASSCODE")} (
        {swaDate.duration(secondsUntilResend, "seconds").format("m:ss")})
      </span>
    );

    if (secondsUntilResend <= 0) {
      resendLink = <Link onClick={onResend}>{i18n("MfaEnterPasscodeForm__RESEND_PASSCODE")}</Link>;
    }

    return resendLink;
  }

  function getPasscodeProps() {
    return {
      className: styles.fullWidth,
      componentProps: {
        acceptableCharacters: "0123456789",
        maxLength: fieldMaxLengths.passcode,
      },
      disabled: loading,
      label: i18n("MfaEnterPasscodeForm__PASSCODE"),
      name: "passcode",
    };
  }

  function getNeedHelpLinkProps() {
    return {
      className: classNames(styles.fullWidth, styles.needHelp),
      disabled: loading,
      href: MFA_HELP,
    };
  }

  function getSubmitProps() {
    return {
      className: styles.fullWidth,
      componentProps: {
        fullWidth: true,
        submitInProgress: loading,
      },
      disabled: loading || !isValid,
      name: "submit",
    };
  }
};

MfaEnterPasscodeForm.propTypes = {
  /**
   * The contact information for the user.
   */
  contact: PropTypes.shape({
    deviceType: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,

  /**
   * Indicates if the passcode entered is invalid.
   */
  invalidPasscode: PropTypes.bool,

  /**
   * Indicates if the form is in a loading state.
   */
  loading: PropTypes.bool,

  /**
   * Callback function to handle resending the passcode.
   */
  onResend: PropTypes.func.isRequired,

  /**
   * Callback function to handle form submission.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Indicates if the passcode resend was successful.
   */
  passwordResendSuccess: PropTypes.bool,
};

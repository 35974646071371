import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { swaDate } from "@swa-ui/date";

import { decodeCookie, getCookie } from "../../cookie";
import { MfaContext } from "../../MfaProvider";
import { useUserInfo } from "../../UserInfoProvider";
import { MfaContactSelectForm } from "../MfaContactSelectForm";
import { MfaEnterPasscodeForm } from "../MfaEnterPasscodeForm";
import { MfaSuccess } from "../MfaSuccess";
import { useMfaServices } from "./useMfaServices";

const MFA_STEP_CONTACT_SELECT = "MFA_STEP_CONTACT_SELECT";
const MFA_STEP_ENTER_PASSCODE = "MFA_STEP_ENTER_PASSCODE";
const MFA_STEP_SUCCESS = "MFA_STEP_SUCCESS";

/**
 * MfaAuthorizer is a component that handles the MFA authorization process. It will prompt the
 * user to select a contact method, enter a passcode, and then display a success message.
 * Once the user is authorized, it will call the onAuthorized callback.
 */
export const MfaAuthorizer = (props) => {
  const { mfaServices, onAuthorized } = props;
  const [currentStep, setCurrentStep] = useState(MFA_STEP_CONTACT_SELECT);
  const [selectedContact, setSelectedContact] = useState();
  const [contacts, setContacts] = useState();
  const { getMfaDevices, resendMfaOtp, sendMfaOtp, validateMfaOtp } =
    mfaServices ?? useMfaServices();
  const [invalidPasscode, setInvalidPasscode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordResendSuccess, setPasswordResendSuccess] = useState(false);
  const { getCustomerInfo } = useUserInfo();
  const { handleMfaAuthenticated } = useContext(MfaContext);
  const { firstName, preferredName } = getCustomerInfo() ?? {};
  const name = preferredName || firstName;

  useEffect(() => {
    getMfaDevices().then((response) => {
      setContacts(response);
    });
  }, []);

  return (
    <>
      {currentStep === MFA_STEP_CONTACT_SELECT && (
        <MfaContactSelectForm
          contacts={contacts}
          loading={loading}
          onSubmit={handleContactSelectSubmit}
        />
      )}
      {currentStep === MFA_STEP_ENTER_PASSCODE && (
        <MfaEnterPasscodeForm
          contact={selectedContact}
          invalidPasscode={invalidPasscode}
          loading={loading}
          onResend={handleResend}
          onSubmit={handleEnterPasscodeSubmit}
          passwordResendSuccess={passwordResendSuccess}
        />
      )}
      {currentStep === MFA_STEP_SUCCESS && (
        <MfaSuccess loading={loading} onContinue={handleContinue} name={name} />
      )}
    </>
  );

  async function handleContactSelectSubmit(formData) {
    setLoading(true);
    setSelectedContact(formData.contact);

    await sendMfaOtp(formData.contact.deviceType);

    setLoading(false);
    setCurrentStep(MFA_STEP_ENTER_PASSCODE);
  }

  async function handleResend() {
    setPasswordResendSuccess(false);

    await resendMfaOtp();

    setPasswordResendSuccess(true);
  }

  async function handleEnterPasscodeSubmit(passcodeFormData) {
    setLoading(true);
    setInvalidPasscode(false);

    const isValidOtp = await validateMfaOtp(passcodeFormData.passcode);

    if (isValidOtp) {
      setLoading(false);
      setCurrentStep(MFA_STEP_SUCCESS);
    }
  }

  function handleContinue() {
    // TODO: Remove FAKE cookie updates here with expiration
    updateIdTokenWithFakeMfaExpiration();

    handleMfaAuthenticated();
    setLoading(true);
    onAuthorized();
  }

  function updateIdTokenWithFakeMfaExpiration() {
    const idTokenData = getIdTokenData();
    const now = swaDate().unix();

    idTokenData.mfa_expiration = now + 10;

    document.cookie = `id_token_fake=${encodeJwt(idTokenData)}; path=/; secure; samesite=strict;`;
  }

  function getIdTokenData() {
    const idToken = getCookie("id_token");

    return decodeCookie(idToken);
  }

  function encodeJwt(tokenData) {
    const idToken = getCookie("id_token");
    const base64Payload = idToken?.split(".")?.[0];

    return `${base64Payload}.${btoa(JSON.stringify(tokenData))}`;
  }
};

MfaAuthorizer.propTypes = {
  /**
   * Object containing MFA services.
   */
  mfaServices: PropTypes.shape({
    getMfaDevices: PropTypes.func,
    resendMfaOtp: PropTypes.func,
    sendMfaOtp: PropTypes.func,
    validateMfaOtp: PropTypes.func,
  }),

  /**
   * Function to call when the user is authorized
   */
  onAuthorized: PropTypes.func,
};

import React from "react";

import { Heading } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import styles from "./MfaHeading.module.scss";

/**
 * MfaHeading renders a div with children.
 */
export const MfaHeading = (props) => {
  const {
    heading = i18n("MfaHeading__HEADING"),
    subheading = i18n("MfaHeading__SUBHEADING"),
    prompt,
  } = props;

  return (
    <>
      <Heading className={styles.heading} styleLevel={3}>
        {heading}
      </Heading>
      <div className={styles.subheading}>{subheading}</div>
      {prompt && <div className={styles.prompt}>{prompt}</div>}
    </>
  );
};

MfaHeading.propTypes = {};

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { NumberSelector } from "../NumberSelector";
import { SelectionMarker } from "../SelectionMarker";
import styles from "./NumberSelectorGroup.module.scss";

const PADDING = 0;

/**
 * NumberSelectorGroup renders a list of NumberSelectors, and provides additional logic like
 * accumulating totals around all NumberSelectors in group.
 */
export const NumberSelectorGroup = (props) => {
  const { className, items, maxCount, onChange } = props;
  const [itemDimensions, setItemDimensions] = useState([]);
  const [readyToInit, setReadyToInit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [workingItems, setWorkingItems] = useState([...items]);
  const containerRef = useRef();
  const itemContainerRefs = [...Array(items.length)].map(() => useRef());
  const numberSelectorRefs = [...Array(items.length)].map(() => useRef());

  useEffect(() => {
    setReadyToInit(true);
  }, []);

  useEffect(() => {
    readyToInit && setItemDimensions(getItemDimensions());
  }, [readyToInit]);

  useEffect(() => {
    setWorkingItems([...items]);
  }, [items]);

  return (
    <div {...getProps()}>
      <SelectionMarker {...getSelectionMarkerProps()} />
      <ul>{workingItems.map(renderNumberSelector)}</ul>
    </div>
  );

  function renderNumberSelector(item, index) {
    const { adjunctContent, key } = item;

    return (
      <li {...getContainerProps(index)} key={key}>
        <div className={styles.container} ref={itemContainerRefs[index]}>
          <div className={styles.content}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.titleCaption}>{item.titleCaption}</div>
          </div>
          <NumberSelector {...getNumberSelectorProps(item, index)} />
        </div>
        <div className={styles.break} />
        {adjunctContent && renderAdjunct(item)}
      </li>
    );
  }

  function renderAdjunct(item) {
    const { adjunctContent, adjunctContentRevealed } = item;

    return <Area {...getAreaProps(adjunctContentRevealed)}>{adjunctContent}</Area>;
  }

  function getProps() {
    return {
      className: classNames(styles.numberSelectorGroup, className),
      ref: containerRef,
    };
  }

  function getContainerProps(index) {
    return {
      onClick: () => {
        setSelectedIndex(index);
        numberSelectorRefs[index].current.focus();
      },
      onMouseEnter: () => setSelectedIndex(index),
    };
  }

  function getAreaProps(adjunctContentRevealed) {
    return {
      className: styles.adjunct,
      onTransitionEnd: () => setItemDimensions(getItemDimensions()),
      revealed: adjunctContentRevealed,
    };
  }

  function getSelectionMarkerProps() {
    const item = itemDimensions?.[selectedIndex];
    const height = item?.height || 0;
    const yOffset = item ? item?.y - getYoffsetContainer() : 0;

    return {
      className: styles.selectionMarker,
      height,
      yOffset: yOffset + PADDING - window.scrollY,
    };
  }

  function getYoffsetContainer() {
    return containerRef.current.getBoundingClientRect().y;
  }

  function getNumberSelectorProps(item, index) {
    const { maximumValue, value: itemValue } = item;

    return {
      "aria-label-decrement": "dec",
      "aria-label-increment": "inc",
      "aria-label-value": "value",
      disableIncrement: getTotalCount() >= maxCount,
      maximumValue: Math.min(maximumValue, itemValue + maxCount - getTotalCount()),
      onChange: handleChange.bind(this, index),
      onFocus: () => setSelectedIndex(index),
      ref: numberSelectorRefs[index],
      value: itemValue,
    };
  }

  function handleChange(index, value) {
    const working = [...workingItems];

    working[index].value = value;
    setWorkingItems(working);
    onChange?.(working.map((item) => item.value));
  }

  function getItemDimensions() {
    return itemContainerRefs.map((item) => {
      const dimensions = item.current.getBoundingClientRect();

      return {
        height: dimensions.height,
        y: dimensions.y,
      };
    });
  }

  function getTotalCount() {
    return workingItems.reduce((partialSum, item) => partialSum + item.value, 0);
  }
};

export const numberSelectorGroupPropTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * NumberSelectors to be displayed. The title props indicate what will be rendered to the left of
   * the number selectors.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      adjunctContent: PropTypes.node,
      adjunctContentRevealed: PropTypes.bool,
      key: PropTypes.string.isRequired,
      title: PropTypes.node,
      titleCaption: PropTypes.node,
    })
  ),

  /**
   * Maximum value that can be selected across all NumberSelector. If the total of values reaches
   * this maximum, then all increase buttons will be disabled.
   */
  maxCount: PropTypes.number,

  /** A function that updates the value when a value change is made. */
  onChange: PropTypes.func,
};

NumberSelectorGroup.propTypes = numberSelectorGroupPropTypes;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useViewPageAnalytics = useViewPageAnalytics;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function useViewPageAnalytics(passengers) {
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setMktgPageData = _useContext.setMktgPageData;
  (0, _react.useEffect)(function () {
    if (pathname === "/v2/air/manage-reservation/view" && passengers !== null && passengers !== void 0 && passengers.length) {
      setMktgPageData(_objectSpread({}, getPassengersObject()), "viewPage");
    }
  }, [pathname, passengers]);
  function getPassengersObject() {
    var passengerAges = {
      ADULT: 0,
      INFANT_IN_SEAT: 0,
      LAP_CHILD: 0,
      MINOR: 0,
      YOUTH: 0
    };
    passengers === null || passengers === void 0 || passengers.forEach(function (passenger) {
      var passengerAgeType = passenger === null || passenger === void 0 ? void 0 : passenger.age_type;
      passengerAges[passengerAgeType] = passengerAges[passengerAgeType] + 1;
      if (passenger !== null && passenger !== void 0 && passenger.lap_child_name) {
        passengerAges.LAP_CHILD = passengerAges.LAP_CHILD + 1;
      }
    });
    return {
      pax_adult: "".concat(passengerAges.ADULT),
      pax_child_0_4: "".concat(passengerAges.INFANT_IN_SEAT),
      pax_child_5_11: "".concat(passengerAges.MINOR),
      pax_lapchild: "".concat(passengerAges.LAP_CHILD),
      pax_teen: "".concat(passengerAges.YOUTH)
    };
  }
}
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { AriaLive, Button, Heading, Link } from "@swa-ui/core";
import {
  CheckboxFormField,
  Form,
  PasswordFormField,
  TextFormField,
  useForm,
  yupResolver,
} from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { usePersonas } from "@swa-ui/personas";

import { AuthContext } from "../../AuthProvider";
import { fieldMaxLengths, fieldMinLengths, getLoginSchema } from "../../schema/loginSchema";
import { useUserInfo } from "../../UserInfoProvider";
import { ActionSection } from "./ActionSection";
import styles from "./BaseAuthenticationForm.module.scss";
import { EnrollSection } from "./EnrollSection";
import { LoginSubmitButton } from "./LoginSubmitButton";

export const BaseAuthenticationForm = (props) => {
  const {
    additionalButtonProps,
    canEnroll,
    canSaveCredentials,
    className,
    errorMessage,
    fullWidthSubmitButton,
    heading,
    onSubmit,
    shouldPreventUserNameOrCompanyIdChange,
    showLoginHelp,
    submitButtonText,
  } = props;
  const {
    LOGIN__ENROLL_MEMBER,
    LOGIN__ENROLL_MEMBER_FROM_SWABIZ,
    LOGIN__FORGOT_PASSWORD,
    LOGIN__FORGOT_USERNAME,
  } = getBootstrapData("urls") ?? {};
  const { isCorporatePersona } = usePersonas();
  const { storedLoginInfo } = useContext(AuthContext);
  const { currentAccountNumber, currentCompanyId } = useUserInfo();
  const formMethods = useForm({
    resolver: yupResolver(
      getLoginSchema(isCorporatePersona, shouldPreventUserNameOrCompanyIdChange)
    ),
  });

  return (
    <Form className={className} methods={formMethods} onSubmit={onSubmit}>
      {heading && <Heading {...getHeadingProps()}>{heading}</Heading>}
      {shouldPreventUserNameOrCompanyIdChange && (
        <Heading {...getSecondaryHeadingProps()}>
          {i18n("BaseAuthenticationForm__REAUTH_MESSAGE")}
        </Heading>
      )}
      {isCorporatePersona && <TextFormField {...getCompanyIdProps()} />}
      <TextFormField {...getUsernameProps()} />
      <PasswordFormField {...getPasswordProps()} />
      {errorMessage && renderErrorMessage()}
      {canSaveCredentials && <CheckboxFormField {...getCheckboxProps()} />}
      {fullWidthSubmitButton ? (
        <LoginSubmitButton fullWidth={fullWidthSubmitButton} submitButtonText={submitButtonText} />
      ) : (
        <div className={styles.action}>
          {canEnroll && <EnrollSection {...getEnrollSectionProps()} />}
          <div className={styles.spacing} />
          <ActionSection className={styles.submit} additionalButtonProps={additionalButtonProps} />
        </div>
      )}
    </Form>
  );

  function renderErrorMessage() {
    return (
      <AriaLive hiddenFromScreen={false}>
        <div className={styles.errorMessage}>{i18n(errorMessage)}</div>
      </AriaLive>
    );
  }

  function getHeadingProps() {
    return {
      headingLevel: 3,
      styleLevel: shouldPreventUserNameOrCompanyIdChange ? 3 : 2,
    };
  }

  function getSecondaryHeadingProps() {
    return {
      className: styles.secondaryHeading,
      headingLevel: 4,
    };
  }

  function getCompanyIdProps() {
    return {
      className: styles.companyId,
      componentProps: {
        acceptableCharacters: "0123456789",
        maxLength: fieldMaxLengths.companyId,
        minLength: fieldMinLengths.companyId,
        ...(shouldPreventUserNameOrCompanyIdChange && { defaultValue: currentCompanyId }),
      },
      disabled: shouldPreventUserNameOrCompanyIdChange,
      label: i18n("BaseAuthenticationForm__COMPANY_ID"),
      name: "companyId",
    };
  }

  function getUsernameProps() {
    const { rememberMe, username } = storedLoginInfo;

    return {
      className: styles.username,
      componentProps: {
        "aria-label": i18n("BaseAuthenticationForm__ACCOUNT_NUMBER_OR_USERNAME"),
        maxLength: fieldMaxLengths.username,
        minLength: fieldMinLengths.username,
        ...(rememberMe && { defaultValue: username }),
        ...(shouldPreventUserNameOrCompanyIdChange && { defaultValue: currentAccountNumber }),
      },
      description: showLoginHelp && (
        <Link {...getForgotUsernameLinkProps()}>
          {i18n("BaseAuthenticationForm__FORGOT_USERNAME")}
        </Link>
      ),
      disabled: shouldPreventUserNameOrCompanyIdChange,
      label: i18n("BaseAuthenticationForm__ACCOUNT_NUMBER_OR_USERNAME"),
      name: "username",
    };
  }

  function getPasswordProps() {
    return {
      className: styles.password,
      componentProps: {
        "aria-label": i18n("BaseAuthenticationForm__PASSWORD"),
        maxLength: fieldMaxLengths.password,
        minLength: fieldMinLengths.password,
      },
      description: showLoginHelp && (
        <Link {...getForgotPasswordLinkProps()}>
          {i18n("BaseAuthenticationForm__FORGOT_PASSWORD")}
        </Link>
      ),
      label: i18n("BaseAuthenticationForm__PASSWORD"),
      name: "password",
    };
  }

  function getCheckboxProps() {
    return {
      className: styles.rememberMe,
      componentProps: {
        defaultValue: storedLoginInfo?.rememberMe,
        label: i18n("BaseAuthenticationForm__REMEMBER_ME"),
        name: "rememberMe",
        right: true,
      },
      hideBottomSection: true,
      name: "rememberMe",
    };
  }

  function getEnrollSectionProps() {
    const href = isCorporatePersona ? LOGIN__ENROLL_MEMBER_FROM_SWABIZ : LOGIN__ENROLL_MEMBER;

    return {
      className: styles.enrollNow,
      href,
    };
  }

  function getForgotUsernameLinkProps() {
    return {
      "aria-label": i18n("BaseAuthenticationForm__FORGOT_USERNAME_ARIA_LABEL"),
      dark: false,
      emphasis: false,
      fullWidth: true,
      href: LOGIN__FORGOT_USERNAME,
      size: "fontSize12",
    };
  }

  function getForgotPasswordLinkProps() {
    return {
      "aria-label": i18n("BaseAuthenticationForm__FORGOT_PASSWORD_ARIA_LABEL"),
      dark: false,
      emphasis: false,
      fullWidth: true,
      href: LOGIN__FORGOT_PASSWORD,
      size: "fontSize12",
    };
  }
};

BaseAuthenticationForm.propTypes = {
  /** Props used for the additional optional button. */
  additionalButtonProps: PropTypes.shape(Button.propTypes),

  /** True when 'enroll' section should be shown. */
  canEnroll: PropTypes.bool,

  /** True when 'remember me' should be shown. */
  canSaveCredentials: PropTypes.bool,

  /** Message that corresponds with gateway error. */
  errorMessage: PropTypes.string,

  /** True when submit button should be full width. */
  fullWidthSubmitButton: PropTypes.bool,

  /** Dialog heading. */
  heading: PropTypes.string,

  /** Triggered when login button is clicked. */
  onSubmit: PropTypes.func,

  /** True when username or company id fields should be disabled. */
  shouldPreventUserNameOrCompanyIdChange: PropTypes.bool,

  /** Should the login help links be displayed. */
  showLoginHelp: PropTypes.bool,

  /** Text to be displayed on the submit button. */
  submitButtonText: PropTypes.string,
};

BaseAuthenticationForm.defaultProps = {
  fullWidthSubmitButton: false,
  showLoginHelp: true,
};

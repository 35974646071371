"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fireStopRememberingLinkAnalytics = exports.fireClearSearchesLinkAnalytics = exports.fireBookingWidgetAnalytics = void 0;
var _analytics = require("@swa-ui/analytics");
var fireBookingWidgetAnalytics = exports.fireBookingWidgetAnalytics = function fireBookingWidgetAnalytics(tabName) {
  (0, _analytics.fireTrack)("squid", {
    page_description: "paneldisplayed:".concat(tabName)
  });
};
var fireClearSearchesLinkAnalytics = exports.fireClearSearchesLinkAnalytics = function fireClearSearchesLinkAnalytics() {
  (0, _analytics.fireTrack)("squid", {
    page_description: "link:clear searches"
  });
};
var fireStopRememberingLinkAnalytics = exports.fireStopRememberingLinkAnalytics = function fireStopRememberingLinkAnalytics() {
  (0, _analytics.fireTrack)("squid", {
    page_description: "link:stop remembering"
  });
};
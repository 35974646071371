import PropTypes from "prop-types";
import React, { useRef } from "react";

import { getFocusableItems } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import { Caption, captionPropTypes } from "../Caption";
import { keyCodes } from "../defines/keyCodes";
import { Icon } from "../Icon";
import { useCaption } from "../useCaption";
import styles from "./InformationTip.module.scss";

const INFORMATION_TIP_BG_COLOR_DESIGN_TOKEN = "cmp-core-color-information-tip-bg";
const INFORMATION_TIP_ICON_COLOR_DESIGN_TOKEN = "cmp-core-color-information-tip-icon";

/**
 * InformationTip provide a icon, which when clicked, displays an adjacent flyout (Caption) with informational content.
 */

export const InformationTip = (props) => {
  const { className, content, location, size, width } = props;
  const { captionRef, captionLocation, hideCaption, isCaptionVisible, showCaption } =
    useCaption(location);
  const dateId = getUniqueId("informationTip");
  const informationTipId = useRef(`informationTipId-${dateId}`);

  return (
    <div data-unstable {...getProps()} ref={captionRef}>
      <Caption {...getCaptionProps()}>
        <div {...getFocusContainerProps()}>
          <div className={styles.paddingContainer}>
            <Icon {...getIconProps()} />
          </div>
        </div>
      </Caption>
    </div>
  );

  function getProps() {
    return {
      "aria-describedby": informationTipId.current,
      className: classNames(className, styles.informationTip, {
        [styles.small]: size === "small",
      }),
      onClick: handleClick,
      onKeyDown: handleKeyDown,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: (
        <div className={styles.content} role="tooltip">
          {content}
        </div>
      ),
      alignment: "center",
      "aria-hidden": !isCaptionVisible,
      location: captionLocation,
      pointerAlignment: "center",
      width,
    };
  }

  function getFocusContainerProps() {
    return {
      className: styles.focusContainer,
      tabIndex: `${isCaptionVisible ? -1 : 0}`,
    };
  }

  function getIconProps() {
    return {
      "aria-label": props["aria-label"],
      background: {
        color: INFORMATION_TIP_BG_COLOR_DESIGN_TOKEN,
        type: "circle",
      },
      color: INFORMATION_TIP_ICON_COLOR_DESIGN_TOKEN,
      id: informationTipId.current,
      name: "Info",
      size: size === "auto" ? "size20" : "size14",
      transparentBorder: false,
    };
  }

  function handleClick(event) {
    event.stopPropagation();
    isCaptionVisible ? hideCaption() : showCaption();
  }

  function handleKeyDown(event) {
    const { key } = event;
    const focusableItems = getFocusableItems(`[id="${informationTipId.current}"]`);

    if (captionLocation === "hidden") {
      if (key === keyCodes.KEY_ENTER || key === keyCodes.KEY_SPACE) {
        showCaption();
      }
    } else {
      if (key === keyCodes.KEY_ESCAPE) {
        hideCaption();
        // If InfoTip is displayed, disable Tab key to force focus to stay within InfoTip.
      } else if (
        key !== keyCodes.KEY_ENTER &&
        key !== keyCodes.KEY_SPACE &&
        key === keyCodes.KEY_TAB &&
        (!focusableItems || !focusableItems?.length)
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
};

export const informationTipPropTypes = {
  /** accessibility label applied to the Icon used. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Content that will appear in the flyout of Caption. */
  content: PropTypes.node,

  /** Location to display flyout. */
  location: captionPropTypes.location,

  /** Size of information icon. */
  size: PropTypes.oneOf(["auto", "small"]),

  /**
   * Width for InformationTip content. If a width is not given, the width will be governed by content.
   */
  width: captionPropTypes.width,
};

InformationTip.propTypes = informationTipPropTypes;

InformationTip.defaultProps = {
  location: "right",
  size: "auto",
  width: "medium",
};

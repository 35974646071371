import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";

import { useDeviceInfo, window } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { Adjoin, adjoinPropTypes } from "../Adjoin";
import { CaptionPointer } from "../CaptionPointer";
import { CloseButton } from "../CloseButton";
import { ConfigurationContext } from "../ConfigurationContext";
import { FocusContainer } from "../FocusContainer";
import { Show } from "../Show";
import styles from "./Caption.module.scss";

const CARET_CENTER = 12;
const CARET_WIDTH = 24;

/**
 * Caption uses Adjoin to display a styled overlay next to another element which contains Caption's
 * child content. Caption typically displays a "pointer" to show the correlation to the main content,
 * but can be turned off. The child content will be invisible and will ignore mouse events when
 * location is hidden.
 */

export const Caption = (props) => {
  const {
    adjoinedContentClassName,
    adjoiningContent,
    alignment,
    bestFit,
    captionRef,
    children,
    className,
    constrainFocus,
    doc,
    duration,
    durationPointer,
    id,
    location,
    mainClassName,
    onClick,
    onClose,
    onTransformationEnd,
    pointerAlignment,
    pointerXOffset,
    pointerYOffset,
    showClose,
    showPointer,
    stackingContext,
    width,
    xOffset,
    yOffset,
  } = props;
  const { gridPadding } = useDeviceInfo();
  const [adjoiningContentHeight, setAdjoiningContentHeight] = useState();
  const [adjoiningContentWidth, setAdjoiningContentWidth] = useState();
  const [adjustments, setAdjustments] = useState({ x: 0, y: 0 });
  const [currentAlignment, setCurrentAlignment] = useState(alignment);
  const [currentPointerAlignment, setCurrentPointerAlignment] = useState(pointerAlignment);
  const [prevLocation, setPrevLocation] = useState();
  const [previousSize, setPreviousSize] = useState([]);
  const adjoiningContentContainer = useRef(null);
  const configurationContext = useContext(ConfigurationContext) ?? {};
  const currentLocation = useRef(location);
  const mainContentContainer = useRef(null);

  useEffect(() => {
    setPrevLocation(location);
    setAdjustments({ x: 0, y: 0 });
  }, [location]);

  useEffect(() => {
    if (isTooWideToFit() || currentLocation.current === "full-screen") {
      setAdjoiningContentWidth(getViewportWidth() - gridPadding * 2);
    }

    if (currentLocation.current === "full-screen") {
      setAdjoiningContentHeight(getViewportHeight() - gridPadding * 2);
    }

    if (currentAlignment !== alignment) {
      setCurrentAlignment(alignment);
    }

    if (currentPointerAlignment !== pointerAlignment) {
      setCurrentPointerAlignment(pointerAlignment);
    }
  });

  useEffect(() => {
    if (
      mainContentContainer?.current &&
      (getMainContentContainerWidth() !== previousSize[0] ||
        getMainContentContainerHeight() !== previousSize[1])
    ) {
      setPreviousSize([getMainContentContainerWidth(), getMainContentContainerHeight()]);
    }
  });

  useEffect(() => {
    const { handleCaption } = configurationContext ?? {};

    if (Array.isArray(handleCaption) && handleCaption.length) {
      handleCaption.forEach((handler) => {
        handler({
          adjoinedContent: adjoiningContentContainer.current,
          mainContent: mainContentContainer.current,
          open: currentLocation.current !== "hidden",
        });
      });
    }
  }, [prevLocation]);

  currentLocation.current = flipLocation();

  return (
    <div {...getProps()}>
      <Adjoin {...getAdjoinProps()} adjoinRef={captionRef}>
        <div className={getCaptionTriggerClass()} ref={mainContentContainer}>
          {children}
        </div>
      </Adjoin>
    </div>
  );

  function renderCaption() {
    const content = constrainFocus ? (
      <FocusContainer {...getFocusContainerProps()}>{renderAdjoinedContent()}</FocusContainer>
    ) : (
      renderAdjoinedContent()
    );

    return <Show {...getShowProps()}>{content}</Show>;
  }

  function renderAdjoinedContent() {
    return showPointer && canPointerBeShown() ? (
      <Adjoin {...getPointerAdjoinProps()}>{renderContent()}</Adjoin>
    ) : (
      renderContent()
    );
  }

  function renderContent() {
    return (
      <div {...getContentProps()}>
        {showClose && <CloseButton {...getCloseButtonProps()} />}
        {adjoiningContent}
      </div>
    );
  }

  function renderPointer() {
    return <CaptionPointer direction={getPointerDirection()} />;
  }

  function getContentProps() {
    return {
      className: getAdjoiningClassName(),
      style: { height: adjoiningContentHeight, width: adjoiningContentWidth },
    };
  }

  function getProps() {
    return {
      className: classNames(className, {
        [styles.hidden]: !children && currentLocation.current === "hidden",
      }),
      onClick,
    };
  }

  function getAdjoinProps() {
    return {
      adjoinedClassName: classNames({
        [styles.hidden]: currentLocation.current === "hidden",
        [styles.stackingContext]: stackingContext,
      }),
      adjoinedContentClassName,
      adjoiningContent: renderCaption(),
      adjustToFitCallback,
      alignment: currentAlignment,
      id,
      location: currentLocation.current,
      mainClassName,
      xOffset,
      yOffset: getYoffset(),
    };
  }

  function getFocusContainerProps() {
    return {
      "aria-hidden": props["aria-hidden"] || currentLocation.current === "hidden",
      className: styles.adjoinContainer,
      doc,
      revealed: currentLocation.current !== "hidden",
    };
  }

  function getAdjoiningClassName() {
    return classNames(styles.mainContent, {
      [styles.widthExtraLarge]: width === "xlarge",
      [styles.widthExtraSmall]: width === "xsmall",
      [styles.widthLarge]: width === "large",
      [styles.widthMedium]: width === "medium",
      [styles.widthSmall]: width === "small",
    });
  }

  function getCaptionTriggerClass() {
    return classNames({
      [styles.captionTrigger]: currentLocation.current !== "hidden",
    });
  }

  function getPointerAdjoinProps() {
    return {
      adjoinedClassName: styles.pointer,
      adjoiningContent: renderPointer(),
      alignment: getPointerAlignment(),
      className: getPointerClass(),
      duration: durationPointer,
      location: getPointerLocation(),
      xOffset: getPointerXoffset(),
      yOffset: getPointerYoffset(),
    };
  }

  function getShowProps() {
    return {
      className: classNames({
        [styles.fullWidth]: currentLocation.current === "full-screen",
      }),
      duration,
      id,
      location: currentLocation.current,
      onTransformationEnd,
      ref: adjoiningContentContainer,
    };
  }

  function getCloseButtonProps() {
    return {
      "aria-label": props["aria-label-close"],
      className: styles.closeButton,
      onClick: onClose,
    };
  }

  function getPointerClass() {
    const normalizedLocation = getNormalizedLocation();

    return classNames({
      [styles.pointerAbove]: normalizedLocation === "above" && showPointer,
      [styles.left]: normalizedLocation === "left" && showPointer,
      [styles.right]: normalizedLocation === "right" && showPointer,
      [styles.pointerLeftRight]:
        (normalizedLocation === "left" || normalizedLocation === "right") && showPointer,
    });
  }

  function isTooWideToFit() {
    const viewPortWidth = getViewportWidth() - gridPadding * 2;

    return adjoiningContentContainer.current.getBoundingClientRect().width > viewPortWidth;
  }

  function getViewportWidth() {
    return window.document.documentElement.clientWidth;
  }

  function getViewportHeight() {
    return window.document.documentElement.clientHeight;
  }

  function adjustToFitCallback(deltas) {
    setAdjustments(deltas);
  }

  function flipLocation() {
    let bestLocation = location;

    if (bestFit && adjoiningContentContainer?.current && mainContentContainer?.current) {
      const adjoiningContentHeight =
        getAdjoinContentContainerHeight() +
        (canPointerBeShown() && (location === "above" || location === "below") ? CARET_WIDTH : 0);
      const adjoiningContentWidth = getAdjoinContentContainerWidth();
      const sizeAbove = getMainContentContainerY();
      const sizeBelow =
        window.document.documentElement.clientHeight -
        getMainContentContainerY() -
        getMainContentContainerHeight();
      const sizeLeft = getMainContentContainerX();
      const sizeRight =
        window.document.documentElement.clientWidth -
        getMainContentContainerX() -
        getMainContentContainerWidth();

      if (location === "above") {
        if (adjoiningContentHeight > sizeAbove && sizeAbove < sizeBelow) {
          bestLocation = "below";
        }
      } else if (location === "below") {
        if (adjoiningContentHeight > sizeBelow && sizeAbove > sizeBelow) {
          bestLocation = "above";
        }
      } else if (location === "left") {
        if (adjoiningContentWidth > sizeLeft && sizeLeft < sizeRight) {
          bestLocation = "right";
        }
      } else if (location === "right") {
        if (adjoiningContentWidth > sizeRight && sizeLeft > sizeRight) {
          bestLocation = "left";
        }
      }
    }

    return bestLocation;
  }

  function getYoffset() {
    let offset = yOffset;

    if (currentLocation.current === "left" || currentLocation.current === "right") {
      if (currentAlignment === "bottom") {
        offset += CARET_WIDTH;
      } else if (currentAlignment === "center") {
        offset += CARET_CENTER;
      }
    } else if (currentLocation.current === "below") {
      offset += CARET_CENTER;
    }

    return offset;
  }

  function getPointerAlignment() {
    let caretAlignment = currentPointerAlignment;

    if (currentPointerAlignment === "center") {
      if (currentLocation.current === "above" || currentLocation.current === "below") {
        caretAlignment = "left";
      } else if (currentLocation.current === "left" || currentLocation.current === "right") {
        caretAlignment = "top";
      } else {
        caretAlignment = undefined;
      }
    }

    return caretAlignment;
  }

  function getPointerLocation() {
    const normalizedLocation = getNormalizedLocation();
    let pointerLocation;

    if (normalizedLocation === "below") {
      pointerLocation = "above";
    } else if (normalizedLocation === "left") {
      pointerLocation = "right";
    } else if (normalizedLocation === "right") {
      pointerLocation = "left";
    } else if (normalizedLocation === "above") {
      pointerLocation = "below";
    }

    if (location === "hidden") {
      pointerLocation = "hidden";
    }

    return pointerLocation;
  }

  function getPointerDirection() {
    const normalizedLocation = getNormalizedLocation();
    let direction = "hidden";

    if (normalizedLocation === "below") {
      direction = "up";
    } else if (normalizedLocation === "left") {
      direction = "right";
    } else if (normalizedLocation === "right") {
      direction = "left";
    } else if (normalizedLocation === "above") {
      direction = "down";
    }

    return direction;
  }

  function getNormalizedLocation() {
    return currentLocation.current !== "hidden" ? currentLocation.current : prevLocation;
  }

  function getPointerXoffset() {
    let pointerX = pointerXOffset + adjustments.x;

    if (
      adjoiningContentContainer?.current &&
      mainContentContainer?.current &&
      (currentLocation.current === "above" || currentLocation.current === "below")
    ) {
      const adjoinContentWidth = getAdjoinContentContainerWidth();
      const mainContentWidth = getMainContentContainerWidth();

      if (currentAlignment === "left") {
        if (currentPointerAlignment === "right") {
          const offset = mainContentWidth - adjoinContentWidth;

          pointerX += offset;
        } else if (currentPointerAlignment === "center") {
          const offset = mainContentWidth / 2;

          pointerX += offset;
        }
      } else if (currentAlignment === "center") {
        if (currentPointerAlignment === "left") {
          const offset = (mainContentWidth - adjoinContentWidth) / 2;

          pointerX -= offset;
        } else if (currentPointerAlignment === "right") {
          if (mainContentWidth <= adjoinContentWidth) {
            const offset = (mainContentWidth - adjoinContentWidth) / 2;

            pointerX += offset;
          }
        } else if (currentPointerAlignment === "center") {
          const offset =
            mainContentWidth / 2 - (mainContentWidth - adjoinContentWidth) / 2 - CARET_CENTER;

          pointerX += offset;
        }
      } else {
        if (currentPointerAlignment === "left") {
          const offset = mainContentWidth - adjoinContentWidth;

          pointerX -= offset;
        } else if (currentPointerAlignment === "center") {
          const offset =
            mainContentWidth / 2 - (mainContentWidth - adjoinContentWidth) - CARET_CENTER;

          pointerX += offset;
        }
      }
    }

    if (currentLocation.current === "left") {
      pointerX -= 1;
    } else if (currentLocation.current === "right") {
      pointerX += 1;
    }

    return pointerX;
  }

  function getPointerYoffset() {
    let pointerY = pointerYOffset;

    if (
      adjoiningContentContainer?.current &&
      mainContentContainer?.current &&
      (currentLocation.current === "left" || currentLocation.current === "right")
    ) {
      const adjoinContentHeight = getAdjoinContentContainerHeight();
      const mainContentHeight = getMainContentContainerHeight();

      if (currentAlignment === "top") {
        if (currentPointerAlignment === "bottom") {
          const offset = mainContentHeight - adjoinContentHeight;

          pointerY += offset;
        } else if (currentPointerAlignment === "center") {
          const offset = mainContentHeight / 2 - CARET_CENTER;

          pointerY += offset;
        }
      } else if (currentAlignment === "center") {
        if (currentPointerAlignment === "top") {
          const offset = (mainContentHeight - adjoinContentHeight) / 2;

          pointerY -= offset;
        } else if (currentPointerAlignment === "bottom") {
          const offset = (mainContentHeight - adjoinContentHeight) / 2;

          pointerY += offset;
        } else if (currentPointerAlignment === "center") {
          const offset =
            mainContentHeight / 2 - (mainContentHeight - adjoinContentHeight) / 2 - CARET_WIDTH;

          pointerY += offset;
        }
      } else {
        if (currentPointerAlignment === "top") {
          const offset = mainContentHeight - adjoinContentHeight;

          pointerY -= offset;
        } else if (currentPointerAlignment === "center") {
          const offset = adjoinContentHeight - mainContentHeight / 2 - CARET_CENTER;

          pointerY += offset;
        }
      }
    }

    if (currentLocation.current === "above") {
      pointerY -= 1;
    } else if (currentLocation.current === "below") {
      pointerY += 1;
    }

    return pointerY;
  }

  function getAdjoinContentContainerHeight() {
    return adjoiningContentContainer.current.getBoundingClientRect().height;
  }

  function getAdjoinContentContainerWidth() {
    return adjoiningContentContainer.current.getBoundingClientRect().width;
  }

  function getMainContentContainerHeight() {
    return mainContentContainer.current.getBoundingClientRect().height;
  }

  function getMainContentContainerWidth() {
    return mainContentContainer.current.getBoundingClientRect().width;
  }

  function getMainContentContainerX() {
    return mainContentContainer.current.getBoundingClientRect().x;
  }

  function getMainContentContainerY() {
    return mainContentContainer.current.getBoundingClientRect().y;
  }

  function canPointerBeShown() {
    return (
      showPointer ||
      location === "above" ||
      location === "below" ||
      location === "left" ||
      location === "right"
    );
  }
};

export const captionPropTypes = {
  /** Content that will be rendered adjacent to "main" content. */
  adjoiningContent: PropTypes.node,

  /** Where adjoiningContent will be placed. */
  alignment: adjoinPropTypes.alignment,

  /** Set the aria-hidden attribute to be passed down the container. */
  "aria-hidden": PropTypes.bool,

  /** aria-label text to provide additional accessibility description of close button. */
  "aria-label-close": PropTypes.string,

  /**
   * Caption will attempt to place the caption content where directed by the location prop, but if
   * the content will not fit, Caption will move it to the other size. For example, if location is
   * defined as below and there's not enough room underneath, and there's more more on the screen
   * above the child content, the caption will be placed above.
   */
  bestFit: PropTypes.bool,

  /** Ref passed to allow useCaption to close Caption on clicking outside of component. */
  captionRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),

  /** Content that will be rendered when Caption is displayed. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Caption is able to constrain focus to the flyout if desired. When set, the traveler, cannot set
   * focus on an element outside the flyout using tab and shift-tab.
   */
  constrainFocus: PropTypes.bool,

  /**
   * By default this will the global document object, but can be overridden to support iFrames. This
   * is used by Caption to get a reference to a DOM element.
   */
  doc: PropTypes.object,

  /** Length of duration for the "main" content to be positioned. */
  duration: PropTypes.number,

  /**
   * Length of duration for the pointer to be positioned. This will typically be zero which is
   * without animation.
   */
  durationPointer: PropTypes.number,

  /** Unique identifier which is passed to Show when it's necessary to force a re-render. */
  id: PropTypes.string,

  /** Defines which where the caption will be displayed in relation to the "main" child content. */
  location: adjoinPropTypes.location,

  /** className to be given to main content container. */
  mainClassName: PropTypes.string,

  /** Callback that will be called when caption is clicked. */
  onClick: PropTypes.func,

  /** Callback to receive notification of click to dismiss caption. */
  onClose: PropTypes.func,

  /** Option callback to inform when caption has been placed. */
  onTransformationEnd: PropTypes.func,

  /** Where adjoiningContent will be placed. */
  pointerAlignment: PropTypes.oneOf(["bottom", "left", "center", "right", "top"]),

  /** Positive or negative value to nudge the pointer horizontally. */
  pointerXOffset: adjoinPropTypes.xOffset,

  /** Positive or negative value to nudge the pointer in the vertical direction. */
  pointerYOffset: adjoinPropTypes.yOffset,

  /**
   * Present close button to dismiss caption. Caption will not close itself. The caller must
   * respond to the onClose event to set Caption's location prop to "hidden".
   */
  showClose: PropTypes.bool,

  /** Optional caret will be displayed to "tie" the caption to the main content. */
  showPointer: PropTypes.bool,

  /**
   * Option allows the caller to indicate if a new stacking order should be defined, which changes
   * z-index's effect.
   */
  stackingContext: PropTypes.bool,

  /** Width for adjoining content. If a width is not given, the width will be governed by content. */
  width: PropTypes.oneOf(["large", "medium", "small", "xlarge", "xsmall"]),

  /** Positive or negative value to nudge the adjoining element horizontally. */
  xOffset: adjoinPropTypes.xOffset,

  /** Positive or negative value to nudge the adjoining element in the vertical direction. */
  yOffset: adjoinPropTypes.yOffset,
};

Caption.propTypes = captionPropTypes;

Caption.defaultProps = {
  bestFit: true,
  constrainFocus: true,
  location: "hidden",
  pointerAlignment: "center",
  pointerXOffset: 0,
  pointerYOffset: 0,
  showClose: false,
  showPointer: true,
  stackingContext: true,
  xOffset: 0,
  yOffset: 0,
};

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import { Area, areaPropTypes } from "../Area";
import { Button } from "../Button";
import { Caption, captionPropTypes } from "../Caption";
import { keyCodes } from "../defines/keyCodes";
import { Icon } from "../Icon";
import { Input } from "../Input";
import { Link } from "../Link";
import styles from "./Drawer.module.scss";

const DELAY_FOR_BROWSER = 16;
const DELAY_BEFORE_CAPTION = 1600;

/**
 * Drawer shows and hides content when a "trigger button" is selected". The content can be revealed
 * as a simple expanding element using Area, or can be rendered as Caption, depending on the
 * showPointer prop.
 */
export const Drawer = React.forwardRef((props, ref) => {
  const {
    alignedRight = true,
    animate,
    captionProps,
    children,
    className,
    closeOnClickOff = true,
    concealOnTriggerClick = true,
    fullScreen = false,
    id,
    onRevealChange,
    onTransitionEnd,
    revealed,
    showPointer = false,
    style,
    styleType = "primary",
    triggerClass,
    triggerComponent = "button",
    triggerContent,
    triggerOnHover,
    triggerProps,
  } = props;
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(revealed);
  const containerRef = useRef();
  const hoverTimer = useRef();
  const triggerRef = ref || useRef();
  const uniqueId = id || getUniqueId("icon-id");

  useEffect(() => {
    setOpen(revealed);
  }, [revealed]);

  useEffect(() => {
    if (showPointer && triggerOnHover) {
      if (hover && !hoverTimer.current && !open) {
        hoverTimer.current = window.setTimeout(() => {
          hover && setOpen(true);
        }, DELAY_BEFORE_CAPTION);
      } else {
        window.clearTimeout(hoverTimer.current);
        setOpen(false);
        hoverTimer.current = undefined;
      }
    }
  }, [hover]);

  // TODO this logic is repeated across several hooks and should be refactored PHX-1115
  useEffect(() => {
    if (open) {
      addEventListeners();
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [open]);

  return (
    <div {...getProps()}>
      {showPointer ? <Caption {...getCaptionProps()}>{renderTrigger()}</Caption> : renderArea()}
    </div>
  );

  function renderArea() {
    return (
      <>
        {renderTrigger()}
        <Area animate={animate} id={uniqueId} onTransitionEnd={onTransitionEnd} revealed={open}>
          {children}
        </Area>
      </>
    );
  }

  function renderTrigger() {
    const components = {
      button: Button,
      div: "div",
      input: Input,
      link: Link,
      span: "span",
    };
    const Component = components[triggerComponent];

    return (
      <Component {...getTriggerProps()}>
        {triggerContent}
        {styleType !== "no-style" && <Icon {...getIconProps()} />}
      </Component>
    );
  }

  function getProps() {
    return {
      className: classNames(className),
      "data-test": props["data-test"],
      onMouseEnter: showPointer && triggerOnHover ? handleMouseEnter : undefined,
      onMouseLeave: showPointer && triggerOnHover ? handleMouseLeave : undefined,
      ref: containerRef,
      style,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: children,
      alignment: "center",
      bestFit: false,
      id: uniqueId,
      location: open ? (fullScreen ? "full-screen" : "below") : "hidden",
      onTransformationEnd: onTransitionEnd,
      ...captionProps,
    };
  }

  function getTriggerProps() {
    return {
      "aria-controls": uniqueId,
      "aria-expanded": !!open,
      className: getButtonClass(),
      onClick: handleClick,
      ref: triggerRef,
      role: triggerComponent === "input" ? "combobox" : "button",
      tabIndex: 0,
      ...(triggerComponent === "button" && { styleType: "no-style" }),
      ...triggerProps,
    };
  }

  function getIconProps() {
    return {
      className: styles.indicator,
      color: "link",
      custom: { type: open ? "inactive" : "active" },
      name: "ArrowThin",
    };
  }

  function getButtonClass() {
    const noStyle = styleType === "no-style";

    return classNames(triggerClass, {
      [styles.alignedRight]: alignedRight,
      [styles.noStyle]: noStyle,
      [styles.trigger]: !noStyle,
    });
  }

  function handleMouseEnter() {
    setHover(true);
  }

  function handleMouseLeave() {
    setHover(false);
  }

  function handleMouseDown(event) {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      event.stopImmediatePropagation();

      if (closeOnClickOff) {
        setOpen(false);
        onRevealChange?.(false);
        window.setTimeout(() => triggerRef.current.focus(), DELAY_FOR_BROWSER);
      }
    }
  }

  function handleKeyDown(event) {
    if (event.key === keyCodes.KEY_ESCAPE) {
      setOpen(false);
      onRevealChange?.(false);
      window.setTimeout(() => triggerRef.current.focus(), DELAY_FOR_BROWSER);
    }
  }

  function handleClick(event) {
    if (event.currentTarget === triggerRef.current) {
      event.stopPropagation();

      if (!open || concealOnTriggerClick) {
        setOpen(!open);
        onRevealChange?.(!open);
      }
    }
  }

  function addEventListeners() {
    window.addEventListener("mousedown", handleMouseDown, true); // NOSONAR
    window.addEventListener("keydown", handleKeyDown); // NOSONAR
  }

  function removeEventListeners() {
    window.removeEventListener("mousedown", handleMouseDown, true);
    window.removeEventListener("keydown", handleKeyDown);
  }
});

Drawer.propTypes = {
  /**
   * Drawer is set to 100% width. triggerContent will be aligned to the right edge of drawer when this props is true.
   */
  alignedRight: PropTypes.bool,

  /** The transition to and from concealed/revealed states will be animated by default. */
  animate: areaPropTypes.animate,

  /** Options to be passed to Caption when showPointer prop is set. */
  captionProps: PropTypes.shape(captionPropTypes),

  /** Content to be rendered. This is the content that Transform will act upon. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Drawer typically closes when clicked off. There are times, such as when Caption is rendered
   * (showPointer is true), it may be undesirable to auto close and can be turn off.
   */
  closeOnClickOff: PropTypes.bool,

  /** Option to prevent closing drawer when clicking trigger. */
  concealOnTriggerClick: PropTypes.bool,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /** Indicator that Caption, when showPointer is true, should use full-screen. */
  fullScreen: PropTypes.bool,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /** Function called when drawer open/close animation is complete. */
  onTransitionEnd: PropTypes.func,

  /** Indicates if the area is initially opened or closed. */
  revealed: areaPropTypes.revealed,

  /**
   * When showPointer is set, then Drawer will show the child content as a Caption. When false, the
   * content will be revealed using Area. The animate prop has no effect when showPointer is true.
   */
  showPointer: PropTypes.bool,

  /** Object for additional CSS styles. */
  style: PropTypes.object,

  /** Styling, if any, that is applied to Drawer's container. */
  styleType: PropTypes.oneOf(["no-style", "primary"]),

  /** Class name to be applied to trigger element. */
  triggerClass: PropTypes.string,

  /**
   * Component to be rendered as trigger. Typically this element would be a button, but it could be
   * another element to accommodate other use cases.
   */
  triggerComponent: PropTypes.oneOf(["button", "div", "input", "link", "span"]),

  /** Text or JSX content, when clicked, will open/close the drawer. */
  triggerContent: PropTypes.node,

  /** Indicates that flyout should be revealed on mouse hover in addition to click event. */
  triggerOnHover: PropTypes.bool,

  /** Additional props that be given to the trigger component. */
  triggerProps: PropTypes.object,
};

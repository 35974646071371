import PropTypes from "prop-types";
import React from "react";

import { Button, Drawer, Heading, Message, Section } from "@swa-ui/core";
import { swaDate } from "@swa-ui/date";
import {
  isHybridEnabled,
  NATIVE_APP_INTERFACE_NAMES,
  sendMessageToNativeApp,
} from "@swa-ui/hybrid";
import i18n from "@swa-ui/locale";
import { clearStorage } from "@swa-ui/persistence";
import { PlacementFactory } from "@swa-ui/placements";

import { PageTransition } from "../../PageTransition";
import { ErrorDetails } from "../ErrorDetails";
import styles from "./ErrorPage.module.scss";

const ERROR_CODE_UI = "900000000";

export const ErrorPage = (props) => {
  const { basePath, error, errorInfo, experienceId } = props;

  return (
    <>
      <PageTransition>
        <Section className={styles.layout} grid={true}>
          <Message {...getMessageProps()}>{getErrorMessageBody()}</Message>
          <div className={styles.body}>
            <Heading headingLevel={2} spacing="small" styleLevel={2}>
              {i18n("ErrorPage__OOPS_PAGE")}
            </Heading>
            <div className={styles.placementContainer}>
              <PlacementFactory
                alwaysVisible
                className={styles.placement}
                id="errorContentModule1"
              />
              <PlacementFactory
                alwaysVisible
                className={styles.placement}
                id="errorContentModule2"
              />
              <PlacementFactory
                alwaysVisible
                className={styles.placement}
                id="errorContentModule3"
              />
            </div>
          </div>
          <div className={styles.navigationContainer}>
            <Button {...getFlowResetButtonProps()}>{i18n("ErrorPage__RESTART_FLOW")}</Button>
            {!isHybridEnabled() && (
              <Button {...getHomepageButtonProps()}>{i18n("ErrorPage__RETURN_HOME")}</Button>
            )}
          </div>
        </Section>
      </PageTransition>
      <ErrorDetails error={error} errorInfo={errorInfo} />
    </>
  );

  function getMessageProps() {
    return {
      className: styles.heading,
      closeAvailable: false,
      headingProps: {
        children: i18n("ErrorPage__SOMETHING_WENT_WRONG"),
        headingLevel: 1,
      },
      styleType: "error",
    };
  }

  function getErrorMessageBody() {
    const dateTimeNow = new swaDate().format(i18n("ErrorPage__DATE_TIME_24_HOUR_FORMAT"));

    return (
      <>
        {i18n("ErrorPage__ERROR_UI")}
        <Drawer {...getDrawerProps()}>
          <div className={styles.displayLabel}>
            {i18n("ErrorPage__ERROR_LEGEND")}:
            <span className={styles.displayValue}>{` ${ERROR_CODE_UI} : ${dateTimeNow}`}</span>
          </div>
          <div className={styles.displayLabel}>
            {i18n("ErrorPage__ERROR_INCIDENT_IDENTIFIER")}:
            <span className={styles.displayValue}>{` ${experienceId}`}</span>
          </div>
        </Drawer>
      </>
    );
  }

  function getDrawerProps() {
    return {
      alignedRight: true,
      className: styles.errorDetails,
      triggerContent: i18n("ErrorPage__ERROR_DETAILS"),
    };
  }

  function getHomepageButtonProps() {
    return {
      fullWidth: true,
      ...(!isHybridEnabled() && { href: "/" }),
      onClick: handleNavigationClick,
    };
  }

  function getFlowResetButtonProps() {
    return {
      fullWidth: true,
      ...(!isHybridEnabled() && { href: `${basePath}/` }),
      onClick: handleNavigationClick,
      styleType: isHybridEnabled() ? "primary" : "secondary",
    };
  }

  function handleNavigationClick() {
    clearStorage();
    isHybridEnabled() && sendMessageToNativeApp(NATIVE_APP_INTERFACE_NAMES.EXIT);
  }
};

ErrorPage.propTypes = {
  basePath: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorInfo: PropTypes.object,
  experienceId: PropTypes.string.isRequired,
};

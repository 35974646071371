import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { classNames } from "@swa-ui/string";

import { adjoinPropTypes } from "../Adjoin";
import { PlayList } from "../PlayList";
import styles from "./Show.module.scss";

/**
 * Show will render content, typically an overlay, by transitioning it with an animation. Show should be provided a
 * location from where the overlay will expand from. For example, when Show is rendered "below", the content will grow
 * slightly down when its rendered.
 */

export const Show = React.forwardRef((props, ref) => {
  const { children, className, duration, id, location, onTransformationEnd } = props;
  const prevLocation = useRef();

  useEffect(() => {
    prevLocation.current = location;
  }, [location]);

  return (
    <div className={getClass()} ref={ref}>
      <PlayList {...getProps()}>{children}</PlayList>
    </div>
  );

  function getProps() {
    return {
      className: getPlayListClass(),
      id,
      onPlayListComplete: onTransformationEnd,
      playList:
        location === "hidden" ? getOuterCloseTransformations() : getOuterOpenTransformations(),
    };
  }

  function getClass() {
    return classNames(className, styles.outerContainer, {
      [styles.above]: location === "above",
      [styles.below]: location === "below",
      [styles.left]: location === "left",
      [styles.right]: location === "right",
    });
  }

  function getPlayListClass() {
    return classNames(styles.content, {
      [styles.contentOpen]: location !== "hidden",
    });
  }

  function getOuterOpenTransformations() {
    return [
      {
        duration: 20,
        transformations: getOuterTransformations(),
        transformOrigin: getTransformOrigin(),
      },
      {
        duration,
        transformations: [getRotateX("0.01deg"), getRotateY("0.01deg"), getOpacity(1)],
        transformOrigin: getTransformOrigin(),
      },
    ];
  }

  function getOuterTransformations() {
    return location === "above" || location === "below"
      ? [getRotateX("-20deg"), getRotateY("-3deg"), getOpacity(0)]
      : [getRotateX("-3deg"), getRotateY("-20deg"), getOpacity(0)];
  }

  function getOuterCloseTransformations() {
    return [
      {
        duration: 100,
        transformations: [getRotateX("0.01deg"), getRotateY("0.01deg"), getOpacity(0)],
        transformOrigin: getTransformOrigin(),
      },
    ];
  }

  function getTransformOrigin() {
    let transformOrigin;

    if (location === "above") {
      transformOrigin = "center-bottom";
    } else if (location === "below") {
      transformOrigin = "center-top";
    } else if (location === "left") {
      transformOrigin = "right-center";
    } else if (location === "right") {
      transformOrigin = "left-center";
    }

    return transformOrigin;
  }

  function getRotateX(amount) {
    return {
      action: "rotateX",
      amount: amount,
    };
  }

  function getRotateY(amount) {
    return {
      action: "rotateY",
      amount: amount,
    };
  }

  function getOpacity(amount) {
    return {
      action: "opacity",
      amount: `${amount}`,
    };
  }
});

Show.propTypes = {
  /** Content to be rendered. This is the content that Transform will act upon. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Length of animation for Show's render of its play list. */
  duration: PropTypes.number,

  /** Unique identifier which is passed to PlayList when it's necessary to use a new playList. */
  id: PropTypes.string,

  /** Required location where content is rendered once "opened". */
  location: adjoinPropTypes.location,

  /** Function called once Show's render animation is complete. */
  onTransformationEnd: PropTypes.func,
};

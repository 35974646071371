import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo, window } from "@swa-ui/browser";
import { createQueryParams, useDebounceEffect } from "@swa-ui/browser";
import {
  Autocomplete,
  autocompletePropTypes,
  Button,
  Caption,
  Input,
  keyCodes,
  TransitionBlock,
} from "@swa-ui/core";
import { isResponseOk, useFetch } from "@swa-ui/fetch";
import i18n from "@swa-ui/locale";
import { HighlightSubstring } from "@swa-ui/string";
import { classNames } from "@swa-ui/string";

import { VACATION } from "../defines/itineraryType";
import { getIndexLetters, shouldShowAutocomplete } from "../utilities/helpers/AutocompleteHelpers";
import { getDestinationName } from "../utilities/helpers/VacationDestinations/VacationDestinations";
import styles from "./AutocompleteVacationDestinations.module.scss";

const ALPHA_PATTERN = /^[a-zA-Z]+$/;
const DELAY_FOR_BROWSER = 16;
const DELAY_FOR_USER_SELECT_INPUT = 200;
const LOCATION_ENDPOINT = `/api/content-delivery/v1/content-delivery/query/ojt-locations-filter`;
const LOCATION_TYPE = {
  AIRPORT: "airport",
  CITY: "city",
  NEIGHBOURHOOD: "nbhood",
};
const MINIMUM_CHARS_TO_SEARCH = 3;
const NO_ANIMATION_DURATION = 0.001;

/**
 * AutocompleteVacationDestinations facilitates selecting a vacation destination by filter selectable destinations according to user entry
 * matches.
 *
 * Note that the calling application must set up bootstrap so destination data is available. Primarily this requires
 * setDataKeyPrefix be called with application's prefix key.
 */

export const AutocompleteVacationDestinations = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    disabled,
    error,
    id,
    labelText,
    maxItemsToDisplay,
    minimumListWidth,
    noMatchFoundText,
    onBlur,
    onChange,
    option,
    readOnly,
    required,
    searchResultsLimit,
    softEdge,
    suppressValidationTriggerOnChange,
    value,
  } = props;
  const { get, response } = useFetch();
  const { isTouchDevice } = useDeviceInfo();
  const { KEY_ENTER, KEY_ESCAPE } = keyCodes;
  const [currentList, setCurrentList] = useState([]);
  const [currentName, setCurrentName] = useState("");
  const [currentValue, setCurrentValue] = useState(value);
  const [location, setLocation] = useState("hidden");
  const [searchTerm, setSearchTerm] = useState(value);
  const containerRef = useRef();
  const countries = getBootstrapData("countries-data");
  const destinationCode = useRef("");
  const previousResultsLength = useRef(0);
  const searchInputRef = useRef();
  const states = getBootstrapData("states-data");

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const indexLetterRefs = useMemo(
    () =>
      getIndexLetters(VACATION, currentList).map((item) => ({
        key: item,
        ref: React.createRef(null),
      })),
    [currentList]
  );

  useDebounceEffect(
    () => {
      if (searchTerm) {
        (async () => {
          const list = await getList(searchTerm);

          setCurrentList(list);

          if (list.length !== currentList.length && !isTouchDevice) {
            refreshFlyout();
          }
        })();
      }
    },
    [searchTerm],
    100
  );

  return isTouchDevice ? renderFullScreen() : <Autocomplete {...getAutocompleteProps()} />;

  function renderFullScreen() {
    return (
      <Caption {...getCaptionProps()}>
        <Input {...getInputProps()} />
      </Caption>
    );
  }

  function renderFullScreenContent() {
    return (
      <>
        <div className={styles.mainContainer} onKeyDown={handleKeyDown} tabIndex={-1}>
          <div className={styles.headingOptions}>
            <div className={styles.labelText}>{labelText}</div>
            <Input {...getSearchInputProps()} />
          </div>
          <div className={styles.options} ref={containerRef}>
            <TransitionBlock {...getTransitionBlockProps()}>{renderList()}</TransitionBlock>
          </div>
        </div>
        {renderIndex()}
      </>
    );
  }

  function renderIndex() {
    const indexLetters = getIndexLetters(VACATION, currentList);

    return (
      <div className={styles.indexContainer}>
        <div className={getIndexClassNames()}>
          {indexLetters.map((indexLetter, index) => (
            <Button key={indexLetter} {...getIndexButtonProps(index)}>
              {indexLetter}
            </Button>
          ))}
        </div>
      </div>
    );
  }

  function renderList() {
    return indexLetterRefs.map((letter) => (
      <div key={letter.key} ref={letter.ref}>
        <div className={styles.letterSection}>
          <div>{letter.key}</div>
          <div className={styles.separator} />
        </div>
        {currentList?.map((destination) => {
          const destinationName = getDestinationName(destination);
          const firstLetter = destinationName.charAt(0).toUpperCase();
          return firstLetter &&
            letter.key === firstLetter &&
            !currentList.includes(destination.value) ? (
            <Button {...getButtonProps(destination.value)}>{destination.label}</Button>
          ) : null;
        })}
      </div>
    ));
  }

  function getAutocompleteProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-required": required,
      className,
      "data-test": props["data-test"],
      defaultValue,
      disabled,
      error,
      id,
      inputMode: "text",
      list: currentList,
      location,
      maxItemsToDisplay,
      minimumListWidth,
      noMatchFoundText,
      onBlur: handleBlur,
      onChange: handleChange,
      onInputChange: handleSearchInputChange,
      option,
      readOnly: false,
      ref,
      required,
      showArrow: false,
      softEdge,
      value: currentValue,
    };
  }

  function getButtonProps(destinationId) {
    return {
      className: styles.stationOption,
      key: destinationId,
      onClick: () => handleClick(destinationId),
      styleType: "no-style",
      tabIndex: -1,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: renderFullScreenContent(),
      "aria-label-close": i18n("AutocompleteVacationDestinations__CLOSE_ARIA_LABEL"),
      id,
      location,
      mainClassName: styles.mainContent,
      onClose: handleClose,
      showClose: true,
      showPointer: false,
      stackingContext: false,
    };
  }

  function getIndexButtonProps(index) {
    return {
      className: styles.indexButton,
      onClick: handleIndexSelection.bind(this, index),
      styleType: "no-style",
      tabIndex: -1,
    };
  }

  function getInputProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      disabled,
      error,
      id,
      inputMode: "none",
      onFocus: () => {
        !readOnly && setLocation("full-screen");
      },
      onInputChange: handleInputChange,
      option,
      readOnly: location === "full-screen",
      required,
      value: currentName,
    };
  }

  function getSearchInputProps() {
    return {
      className: styles.input,
      onBlur: handleBlur,
      onChange: handleSearchInputChange,
      ref: searchInputRef,
      value: searchTerm,
    };
  }

  function getTransitionBlockProps() {
    const noAnimation = { duration: NO_ANIMATION_DURATION, transformations: ["fadeReset"] };
    const resultsChanged = currentList?.length !== previousResultsLength.current;

    previousResultsLength.current = currentList.length;

    return {
      animationToken: searchTerm?.length >= MINIMUM_CHARS_TO_SEARCH ? currentList?.length : "none",
      transitionIn: !resultsChanged ? noAnimation : undefined,
      transitionOut: !resultsChanged ? noAnimation : undefined,
    };
  }

  function getIndexClassNames() {
    return classNames({
      [styles.container]: shouldShowAutocomplete(currentValue, currentList),
      [styles.indexes]: [styles.indexes],
    });
  }

  function handleBlur(event) {
    // TODO: PHX-1101 remove setTimeout once the core component is fixed
    window.setTimeout(() => {
      const destinationDetails = getDestinationDetails();

      !isTouchDevice && onBlur?.({ target: { ...event.target, ...destinationDetails } });
    }, DELAY_FOR_USER_SELECT_INPUT);
  }

  function handleChange(event) {
    const destinationDetails = getDestinationDetails(event.target.value);

    setCurrentValue(event.target.value);
    onChange?.({ target: { ...event.target, ...destinationDetails } });
  }

  async function handleClick(destinationId) {
    const destinationDetails = getDestinationDetails(destinationId);

    onChange?.({ target: { ...destinationDetails } });
    setCurrentList(await getList(destinationDetails.name));
    setCurrentName(destinationDetails.name);
    setCurrentValue(destinationId);
    setLocation("hidden");
    setSearchTerm(destinationDetails.name);
  }

  function handleClose() {
    if (currentValue !== null && currentValue !== undefined) {
      processValueOnExit();
    }
    setLocation("hidden");
  }

  function handleIndexSelection(index) {
    containerRef.current.scrollTo({
      behavior: "smooth",
      left: 0,
      top:
        indexLetterRefs[index].ref.current.getBoundingClientRect().y -
        containerRef.current.getBoundingClientRect().top +
        containerRef.current.scrollTop,
    });
  }

  function handleInputChange(event) {
    setCurrentValue(event.target.value);
  }

  function handleSearchInputChange(event) {
    setCurrentValue(event.target.value);
    setSearchTerm(event.target.value);
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === KEY_ENTER || key === KEY_ESCAPE) {
      processValueOnExit();
      setLocation("hidden");
    }
  }

  function getDestinationDetails(newId) {
    if (newId) {
      destinationCode.current = newId;
    }

    const destination = currentList?.find((item) => item.value === destinationCode.current);
    let result = { isValid: false, name: "" };

    if (destination) {
      result = {
        iataCode: destination.iataCode,
        isValid: true,
        name: destination.displayValue,
        value: destination.value,
      };
    }

    return result;
  }

  async function getDestinationsList(searchCriteria) {
    const resultList = await getLocationMatch(searchCriteria);

    return resultList.map((resultListItem) => ({
      displayValue: resultListItem.name,
      iataCode: resultListItem.iataCode,
      label: getListItemLabel(resultListItem, searchCriteria),
      value: resultListItem.id,
    }));
  }

  function getFullCountryName(countryCode) {
    return countries
      ?.find((country) => country.countryCode === countryCode)
      ?.fullName?.toUpperCase();
  }

  function getFullStateName(stateCode) {
    return states[0]?.statesData
      ?.find((stateData) => stateData?.code === stateCode)
      ?.stateName?.toUpperCase();
  }

  async function getList(newValue) {
    let list = [];

    if (newValue?.length >= MINIMUM_CHARS_TO_SEARCH && ALPHA_PATTERN.test(newValue)) {
      newValue = newValue.toUpperCase();
      list = await getDestinationsList(newValue);

      if (!list.length) {
        list = [
          {
            disabled: true,
            label: <i className={styles.noMatchFound}>{noMatchFoundText}</i>,
            value: noMatchFoundText,
          },
        ];
      }
    }

    return list;
  }

  function getListItemLabel(item, searchCriteria) {
    return (
      <div className={styles.listItemContainer}>
        {getListItemLabelTitle(item, searchCriteria)}
        {getListItemLabelDescription(item)}
      </div>
    );
  }

  function getListItemLabelTitle(item, searchCriteria) {
    const iataCodeLabel = item.type === LOCATION_TYPE.AIRPORT && ` (${item.iataCode})`;

    return (
      <div>
        <HighlightSubstring substring={searchCriteria} value={item.name} />
        <HighlightSubstring substring={searchCriteria} value={iataCodeLabel || ""} />
      </div>
    );
  }

  function getListItemLabelDescription(item) {
    const locationTypeLabel =
      item.type === LOCATION_TYPE.NEIGHBOURHOOD ? "NEIGHBOURHOOD" : item.type?.toUpperCase();
    const stateLabel = item.countryCode === "US" && `${getFullStateName(item.stateCode)}`;
    const countryLabel = getFullCountryName(item.countryCode);
    let descriptionLabel = "";

    if (!stateLabel) {
      descriptionLabel = `${locationTypeLabel} - ${countryLabel}`;
    } else {
      descriptionLabel = `${locationTypeLabel} - ${stateLabel}, ${countryLabel}`;
    }

    return <div className={styles.listItemDescription}>{descriptionLabel}</div>;
  }

  async function getLocationData(searchCriteria) {
    let data = [];
    const limit = isTouchDevice ? 9999 : searchResultsLimit;
    const params = createQueryParams({ filterStr: searchCriteria, limit });
    const body = await get(`${LOCATION_ENDPOINT}?${params}`);

    if (isResponseOk(response) && body?.success) {
      data = body.results ?? [];
    }

    return data;
  }

  async function getLocationMatch(searchCriteria) {
    const vacationLocations = await getLocationData(searchCriteria);

    return vacationLocations.filter((vacationLocation) =>
      vacationLocation.ajaxString.toUpperCase().includes(searchCriteria.toUpperCase())
    );
  }

  function processValueOnExit() {
    onChange?.(
      { target: { value: currentValue } },
      { suppressTrigger: suppressValidationTriggerOnChange }
    );
  }

  function refreshFlyout() {
    setLocation("hidden");
    setTimeout(() => setLocation("below"), DELAY_FOR_BROWSER);
  }
});

AutocompleteVacationDestinations.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** Additional class for the component to provide a background color. */
  className: PropTypes.string,

  /** Test ID for the autocomplete component. */
  "data-test": PropTypes.string,

  /**
   * Initial values to select in list. Each value should be separated by a pipe character. For example:
   * "option one|option three".
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: autocompletePropTypes.disabled,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence, Input will
   * provide error styling.
   */
  error: autocompletePropTypes.error,

  /** ID to be added to input element. */
  id: autocompletePropTypes.id,

  /**
   * Text to appear about the search field that's displayed in the mobile view. This label would
   * typically be the same text that appears above the autocomplete field such as 'Depart' or 'Return'.
   */
  labelText: PropTypes.string.isRequired,

  /** Total number of items visible. If more items available than can be displayed, they can be scrolled into view. */
  maxItemsToDisplay: autocompletePropTypes.maxItemsToDisplay,

  /** Width of dropdown. See autocompletePropTypes/DropDown for more info. */
  minimumListWidth: autocompletePropTypes.minimumListWidth,

  /** Required text to display when no match is found. */
  noMatchFoundText: PropTypes.string.isRequired,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: autocompletePropTypes.onBlur,

  /**
   * Optional callback to learn of selection changes. The values of the selected items will be returned as pipe-
   * separated strings. See defaultValue prop description for an example.
   */
  onChange: autocompletePropTypes.onChange,

  /** Option button to appear on left side of Input. See Input for more details. */
  option: autocompletePropTypes.option,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** To limit the number of search results retrieved from search API */
  searchResultsLimit: PropTypes.number,

  /** Indicates if the dropdown list requires soft edges */
  softEdge: PropTypes.bool,

  /**
   * Optional suppression of React-hook-form's trigger on-change. Thus preventing validation
   * check until the time specified by the form's mode.
   */
  suppressValidationTriggerOnChange: PropTypes.bool,

  /** To allow the application to "control" the Input's value, the value prop may be given. */
  value: PropTypes.string,
};

AutocompleteVacationDestinations.defaultProps = {
  minimumListWidth: "large",
  searchResultsLimit: 10,
  softEdge: false,
  suppressValidationTriggerOnChange: false,
};

import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  ApplicationProviders,
  BootstrapValidator,
  ContextVisualizer,
  ContextVisualProvider,
  TitleUpdater,
  UiMetadataUpdater,
} from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { CoreProviders } from "@swa-ui/core";
import { FetchErrorLogger } from "@swa-ui/fetch";
import { useHybrid } from "@swa-ui/hybrid";
import { logger } from "@swa-ui/log";
import { merge } from "@swa-ui/object";

import { dotcomDefaultAppSettings, nativeAppSwaUserAgentTokens } from "../defines";
import { DotcomAnalyticsDataUpdater } from "../DotcomAnalyticsDataUpdater";
import { DotcomProviders } from "../DotcomProviders";
import { DotcomRulesEngine } from "../DotcomRulesEngine";
import { FitState } from "../FitState";
import { usePersistedApplicationContext } from "../usePersistedApplicationContext";
import { getEnvironmentTier } from "./getEnvironmentTier";

/**
 * Wrapper component to instantiate all context objects for southwest.com, and provide other needed pieces like FIT
 * and analytics support.
 */

export const DotcomApp = (props) => {
  const {
    additionalContentParameters,
    appId,
    appPlacementComponents,
    basePath,
    bootstrapDependencies,
    cacheLife,
    cachePolicy,
    chapi,
    chapiVersion,
    children,
    codesToSuppressForLogging,
    defaultAppSettings,
    globalPlacementIds,
    hybridEnabled,
    maintenanceCodes,
    placementsConfig,
    waitingRoomCode,
  } = props;
  const site = process.env.SITE;
  const { apiKey, appVersion, channelId, deviceType, diagnostic, experienceId, returnToUrl } =
    usePersistedApplicationContext();
  const history = useHistory();

  hybridEnabled && useHybrid({ appId, history });

  return (
    <ContextVisualProvider>
      <BootstrapValidator bootstrapDependencies={bootstrapDependencies}>
        <FetchErrorLogger {...getFetchErrorLoggerProps()}>
          <CoreProviders {...getCoreProvidersProps()}>
            <ApplicationProviders {...getApplicationProvidersProps()}>
              <DotcomProviders {...getDotcomProvidersProps()}>
                <FitState>
                  <TitleUpdater announceTitle={true} appId={appId} />
                  <UiMetadataUpdater {...getUiMetadataUpdaterProps()} />
                  <DotcomAnalyticsDataUpdater />
                  <DotcomRulesEngine />
                  {children}
                </FitState>
                <ContextVisualizer />
              </DotcomProviders>
            </ApplicationProviders>
          </CoreProviders>
        </FetchErrorLogger>
      </BootstrapValidator>
    </ContextVisualProvider>
  );

  function getCoreProvidersProps() {
    return {
      configuration: getCoreConfiguration(),
      deviceInfoConfiguration: {
        userAgentTokens: nativeAppSwaUserAgentTokens,
      },
    };
  }

  function getApplicationProvidersProps() {
    return {
      appId,
      applicationContext: getApplicationContext(),
      defaultAppSettings: merge(dotcomDefaultAppSettings, defaultAppSettings),
    };
  }

  function getApplicationContext() {
    const environmentTier = getEnvironmentTier();

    return {
      apiKey,
      appId,
      appVersion,
      basePath,
      cacheLife,
      cachePolicy,
      channelId,
      chapi,
      chapiVersion,
      deviceType,
      diagnostic,
      environmentTier,
      experienceId,
      hybridEnabled,
      maintenanceCodes,
      returnToUrl,
      site,
      waitingRoomCode,
    };
  }

  function getFetchErrorLoggerProps() {
    return {
      logger,
      codesToSuppress: getCodesToSuppress(),
    };
  }

  function getUiMetadataUpdaterProps() {
    return {
      appId,
      channelId,
      chapi,
      chapiVersion,
    };
  }

  function getCodesToSuppress() {
    const apiGatewayErrors = getBootstrapData("api-gateway-errors") ?? {};
    const globalGatewayCodesToSuppress = Object.values(apiGatewayErrors).reduce(
      (reduced, errorCodes) => reduced.concat(errorCodes.map((errorCode) => errorCode.toString())),
      []
    );

    return {
      ...codesToSuppressForLogging,
      global: [...globalGatewayCodesToSuppress, ...codesToSuppressForLogging.global],
    };
  }

  function getCoreConfiguration() {
    const iconMapping =
      getBootstrapData("icon-mappings") ?? getBootstrapData("nextgen-icon-mappings") ?? {};

    Object.entries(iconMapping).forEach(([oldName, newName]) => {
      iconMapping[`swa-icon swa-icon_${oldName}`] = newName;
    });

    return {
      iconMapping,
    };
  }

  function getDotcomProvidersProps() {
    return {
      additionalContentParameters,
      appPlacementComponents,
      globalPlacementIds,
      placementsConfig,
    };
  }
};

DotcomApp.proptypes = {
  /** Additional content parameters to be passed. */
  additionalContentParameters: PropTypes.object,

  /**
   * String that identifies the app for when making requests. The value, something like: 'gift-card', is added to
   * request headers.
   */
  appId: PropTypes.string.isRequired,

  /** Application's base path like '/gift-card/'. */
  basePath: PropTypes.string.isRequired,

  /** Dependencies that need to be loaded before the app starts. */
  bootstrapDependencies: PropTypes.object,

  /** The duration in milliseconds that cached data will become stale in accordance with the use-http library */
  cacheLife: PropTypes.number,

  /**
   * The cache policy passed to the use-http library in a fetch call. Currently only supports 'cache-first' or
   * 'no-cache'. Other options may become supported, check docs for current support.
   */
  cachePolicy: PropTypes.string,

  /**
   * Api name to retrieve maintenance state. This name will be something like 'travel-funds'. This should be passed
   * down from the application.
   */
  chapi: PropTypes.string.isRequired,

  /**
   * Version number to locate specific chapi version and will be similar to: 'v1'. This should be passed down from
   * the application.
   */
  chapiVersion: PropTypes.string.isRequired,

  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,

  /** Object with an array of http status codes per request path that will not be logged.
   * A special 'global' path can be used in order to suppress error logging across all paths.
   *
   * Example:
   *     {
   *         "/api/travel-funds/v1/travel-funds/feature/gift-cards": ["404", "404622370", "404:404622370"],
   *         "/api/security/v4/security/token": ["400:400618202"],
   *         "global": ["404", "404622370", "404:404622370"],
   *     }
   * */
  codesToSuppressForLogging: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),

  /** Application defined configuration settings. */
  defaultAppSettings: PropTypes.object.isRequired,

  /** Indicates whether to enable hybrid webview capability. */
  hybridEnabled: PropTypes.bool,

  /** Error codes provided by app to indicate site is in maintenance mode. */
  maintenanceCodes: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Global placementIds that should be persisted between placement calls
   */
  globalPlacementIds: PropTypes.arrayOf(PropTypes.string),

  /**
   * Code, typically provided by an application layer, that defines the tracking code to take site to the waiting
   * room. The code is known by Akamai, when detected, will render a waiting page when site cannot be produced due to
   * server issues.
   */
  waitingRoomCode: PropTypes.string,
};

DotcomApp.defaultProps = {
  codesToSuppressForLogging: { global: [] },
  globalPlacementIds: [],
  waitingRoomCode: "503010100",
};

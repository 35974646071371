import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  getCumulativeOffset,
  isIntersectingRect,
  useDeviceInfo,
  useResizeObserver,
  window,
} from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { AriaLive } from "../AriaLive";
import { Button } from "../Button";
import { ConfigurationContext } from "../ConfigurationContext";
import { keyCodes } from "../defines/keyCodes";
import { FadingDot } from "../FadingDot";
import { HorizontalGestureScroller } from "../HorizontalGestureScroller";
import { Icon } from "../Icon";
import { List, listPropTypes } from "../List";
import { PositionIndicator } from "../PositionIndicator";
import styles from "./ListScroller.module.scss";

const EVENT_AGGREGATE_DURATION = 333;
const FOCUS_DELAY = 200;
const INITIALIZE_END = 2;
const INITIALIZE_INIT = 0;
const INITIALIZE_STEP = 1;
const ITEMS_ANIMATION_TOKEN_TOGGLE = [0, 1];
const NEXT = 1;
const OFF = 0;
const PREVIOUS = -1;
const TRANSITION_DURATION_DOT = 300;

/**
 * ListScroller provides a way to horizontally scroll through a list of items using gestures,
 * navigation keys like arrows, and/or left/right buttons.
 *
 * Note: ListScroller is intended to be rendered "full-bleed", and not in a grid with margins.
 */

export const ListScroller = React.memo((props) => {
  const {
    center,
    className,
    itemClassName,
    items,
    layout,
    numberOfColumns,
    onClick,
    showPositionIndicator,
    yArrowOffset,
  } = props;
  const { gridColumnSpacing, gridPadding, isTouchDevice, screenSize } = useDeviceInfo();
  const [containerWidth, setContainerWidth] = useState(0);
  const [fullyInitialized, setFullyInitialized] = useState(INITIALIZE_INIT);
  const [itemWidths, setItemWidths] = useState([]);
  const [on, setOn] = useState(OFF);
  const [open, setOpen] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [toggleItemsUpdated, setToggleItemsUpdated] = useState(ITEMS_ANIMATION_TOKEN_TOGGLE[0]);
  const configurationContext = useContext(ConfigurationContext);
  const containerRef = useRef();
  const itemRefs = useRef([]);
  const nextButton = useRef();
  const prevButton = useRef();
  let timer;

  useResizeObserver({ callback: updatePageInfo, element: containerRef });

  useEffect(() => {
    getItemWidths();
  }, [itemRefs.current.length]);

  useEffect(() => {
    setScrollPosition(0);
    setFullyInitialized(INITIALIZE_INIT);
  }, [containerWidth, items]);

  useEffect(() => {
    if (fullyInitialized === INITIALIZE_END) {
      getItemWidths();
    }
  }, [fullyInitialized]);

  useEffect(() => {
    setToggleItemsUpdated(
      toggleItemsUpdated === ITEMS_ANIMATION_TOKEN_TOGGLE[0]
        ? ITEMS_ANIMATION_TOKEN_TOGGLE[1]
        : ITEMS_ANIMATION_TOKEN_TOGGLE[0]
    );
    itemRefs.current.length = items.length;
  }, [items]);

  return (
    <div className={getClass()}>
      {renderButton(PREVIOUS)}
      <ConfigurationContext.Provider value={getContextValue()}>
        <div {...getScrollerContainerProps()}>
          <HorizontalGestureScroller {...getScrollerProps()}>
            {containerWidth !== 0 && <List {...getListProps()} />}
          </HorizontalGestureScroller>
          {renderLocationIndicator()}
        </div>
      </ConfigurationContext.Provider>
      {renderAriaLabel()}
      {renderButton(NEXT)}
    </div>
  );

  function renderAriaLabel() {
    return (
      <AriaLive>
        {`${props["aria-label-page-count"]} ${getPageCount()}. ${
          props["aria-label-current-page"]
        } ${getCurrentPageNumber() + 1}.`}
      </AriaLive>
    );
  }

  function renderButton(direction) {
    return isTouchDevice ? null : (
      <div {...getButtonContainerProps(direction)}>
        <Button {...getButtonProps(direction)}>
          {on === direction && <FadingDot {...getDotProps()} />}
          <Icon {...getIconProps(direction)} />
        </Button>
      </div>
    );
  }

  function renderLocationIndicator() {
    return (
      showPositionIndicator &&
      !isTouchDevice &&
      getPageCount() > 1 && <PositionIndicator {...getPositionIndicatorProps()} />
    );
  }

  function renderListOption(option, index) {
    return <div {...getItemProps(index)}>{option}</div>;
  }

  function getItemProps(index) {
    return {
      className: classNames(styles.option, {
        [styles.last]: index === items.length - 1,
      }),
      ref: (element) => (itemRefs.current[index] = element),
      style: layout === "grid" ? getStyle(index) : undefined,
    };
  }

  function getScrollerContainerProps() {
    return {
      className: styles.scroller,
      onKeyDown: handleKeyDown,
      ref: containerRef,
      tabIndex: -1,
    };
  }

  function getScrollerProps() {
    return {
      className: classNames(styles.horizontalGestureScroller, {
        [styles.center]: center,
      }),
      moreContentIndicator: !open,
      onFocus: handleFocus,
      onMove: handleMove,
      scrollPosition,
    };
  }

  function getListProps() {
    return {
      animateInitialRender: true,
      animationToken: `${itemWidths.length}-${containerWidth}-${toggleItemsUpdated}`,
      className: styles.list,
      itemClassName,
      items: items.map(renderListOption),
      maxItemsToDisplay: items.length,
      onTransformationEnd: () => {
        if (fullyInitialized < INITIALIZE_END) {
          setFullyInitialized(
            fullyInitialized === INITIALIZE_INIT ? INITIALIZE_STEP : INITIALIZE_END
          );
        }
      },
    };
  }

  function getPositionIndicatorProps() {
    return {
      className: styles.positionIndicator,
      position: getCurrentPageNumber(),
      positionCount: getPageCount(),
    };
  }

  function getButtonContainerProps(direction) {
    const style = yArrowOffset && { top: `${yArrowOffset}px` };

    return {
      className: getButtonContainerClass(direction),
      ref: direction === PREVIOUS ? prevButton : nextButton,
      style,
    };
  }

  function getButtonProps(direction) {
    const isDisabled = isButtonDisabled(direction);

    return {
      "aria-label": props[direction === PREVIOUS ? "aria-label-previous" : "aria-label-next"],
      className: getButtonClass(direction),
      clickFeedback: "none",
      disabled: isDisabled,
      onClick: () => handleClick(direction),
      styleType: "no-style",
    };
  }

  function getDotProps() {
    return {
      className: styles.dot,
      color: "list-scroller-feedback",
      duration: TRANSITION_DURATION_DOT,
      fullWidth: true,
      onTransformationEnd: handleTransformationEnd,
      to: "FADED-GROW",
    };
  }

  function getIconProps(direction) {
    const isDisabled = isButtonDisabled(direction);

    return {
      actions: [direction === PREVIOUS ? "rotate270" : "rotate90"],
      className: classNames(styles.icon, { [styles.disabled]: isDisabled }),
      color: isDisabled ? "body-disabled" : "list-scroller-button-content",
      name: "ArrowThin",
      size: "size20",
      transparentBorder: true,
    };
  }

  function getClass() {
    return classNames(className, styles.listScrollerContainer);
  }

  function getButtonClass(direction) {
    const isDisabled = isButtonDisabled(direction);

    return classNames(styles.listScrollerButton, {
      [styles.disabled]: isDisabled,
    });
  }

  function getButtonContainerClass(direction) {
    return classNames(styles.listScrollerButtonContainer, {
      [styles.captionOpen]:
        (direction === NEXT && isCaptionOpenOverNextButton()) ||
        (direction === PREVIOUS && isCaptionOpenOverPrevButton()),
      [styles.next]: direction === NEXT,
      [styles.arrowDisabled]: isButtonDisabled(direction),
      [styles.positionIndicatorAllowance]: showPositionIndicator,
    });
  }

  function getContextValue() {
    return {
      ...configurationContext,
      ...{ handleCaption: [...(configurationContext?.handleCaption ?? []), handleCaption] },
    };
  }

  function handleCaption(captionInfo) {
    const { adjoinedContent, open: opened } = captionInfo;

    window.setTimeout(() => {
      setOpen(opened ? adjoinedContent : undefined);
    }, FOCUS_DELAY);
  }

  function handleClick(direction) {
    onClick?.(direction);
    setScrollPosition(direction === PREVIOUS ? scrollRight() : scrollLeft());
    setOn(direction);
  }

  function handleFocus(event) {
    const indexListItem = getIndexOfItemContainingElement(event.target);
    const offsetListItem = getOffsetForItemIndex(indexListItem);

    if (offsetListItem + itemWidths[indexListItem] + scrollPosition > containerWidth) {
      const offset = offsetListItem + itemWidths[indexListItem];

      window.setTimeout(() => {
        setScrollPosition(containerWidth - offset);
      }, FOCUS_DELAY);
    } else if (
      indexListItem < getIndexFirstVisibleItem() ||
      (indexListItem === getIndexFirstVisibleItem() && offsetListItem < -scrollPosition)
    ) {
      window.setTimeout(() => {
        setScrollPosition(-offsetListItem);
      }, FOCUS_DELAY);
    }
  }

  function getIndexOfItemContainingElement(element) {
    const offsetElement = getCumulativeOffset(element, containerRef.current).left;

    return getIndexForOffset(offsetElement);
  }

  function handleKeyDown(event) {
    const { key } = event;
    let index;
    let offset;

    if (key === keyCodes.KEY_RIGHT) {
      index = getIndexFirstInvisibleItemOnRight();
      offset = getOffsetForItemIndex(index);
      setScrollPosition(-(offset + itemWidths[index] - containerWidth));
    } else if (key === keyCodes.KEY_LEFT) {
      index = Math.max(getIndexFirstVisibleItem() - 1, 0);
      offset = getOffsetForItemIndex(index);
      setScrollPosition(-offset);
    } else if (key === keyCodes.KEY_PAGE_DOWN) {
      index = getIndexFirstInvisibleItemOnRight();
      offset = getOffsetForItemIndex(index);
      setScrollPosition(-offset);
    } else if (key === keyCodes.KEY_PAGE_UP) {
      index = getIndexFirstVisibleItem();
      offset = getOffsetForItemIndex(Math.max(index - getNumberItemsPerPage(), 0));
      setScrollPosition(-offset);
    } else if (key === keyCodes.KEY_HOME) {
      setScrollPosition(0);
    } else if (key === keyCodes.KEY_END) {
      setScrollPosition(-(getTotalWidthAllItems() - containerWidth));
    }
  }

  function handleMove(scrollOffset) {
    setScrollPosition(scrollOffset);
  }

  function handleTransformationEnd() {
    setOn(OFF);
  }

  function isCaptionOpenOverPrevButton() {
    return open && isIntersectingRect(open, prevButton.current);
  }

  function isCaptionOpenOverNextButton() {
    return open && isIntersectingRect(open, nextButton.current);
  }

  function getStyle(index) {
    const columnsInRow = getnumberOfColumnsForDevice();
    const columnSpacing = getColumnSpacing();
    const spaceUsedByMargins = (columnsInRow - 1) * columnSpacing;
    let columnWidth = (containerWidth - spaceUsedByMargins) / columnsInRow;

    if (columnsInRow === 1) {
      columnWidth -= gridPadding;
    }

    return {
      ...(index !== items.length - 1 && { marginRight: `${columnSpacing}px` }),
      height: "100%",
      width: `${columnWidth}px`,
    };
  }

  function getItemWidths() {
    setItemWidths(itemRefs.current.map((item) => item.getBoundingClientRect().width));
  }

  function updatePageInfo() {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(() => {
      setContainerWidth(containerRef?.current?.getBoundingClientRect().width);
    }, EVENT_AGGREGATE_DURATION);
  }

  function getCurrentPageNumber() {
    let pageNumber = Math.min(
      Math.round(
        -scrollPosition / (getNumberItemsPerPage() * (itemWidths[0] + getColumnSpacing()))
      ),
      getPageCount() - 1
    );

    if (scrollPosition + getTotalWidthAllItems() === containerWidth) {
      pageNumber = getPageCount() - 1;
    }

    return pageNumber;
  }

  function getPageCount() {
    const totalWidthOfItems = getTotalWidthAllItems();
    let pageCount = 0;

    if (totalWidthOfItems && containerWidth) {
      pageCount = Math.ceil(totalWidthOfItems / containerWidth);
    }

    return pageCount;
  }

  function getNumberItemsPerPage() {
    let numberItemsPerPage = 0;

    if (containerWidth && itemWidths?.length) {
      numberItemsPerPage = itemWidths.length / getPageCount();
    }

    return numberItemsPerPage;
  }

  function scrollRight() {
    const indexFirstInvisibleItem = getIndexFirstVisibleItem();
    let position = scrollPosition;

    if (indexFirstInvisibleItem >= 0) {
      const offset = Math.max(0, getOffsetForItemIndex(indexFirstInvisibleItem) - containerWidth);

      position = -getOffsetForItemIndex(getIndexForOffset(offset));
    }

    return position;
  }

  function scrollLeft() {
    const firstInvisibleItem = getIndexFirstInvisibleItemOnRight();
    let position = scrollPosition;

    if (firstInvisibleItem < items.length) {
      position = -getOffsetForItemIndex(firstInvisibleItem);
    }

    return position;
  }

  function getIndexFirstVisibleItem() {
    const columnSpacing = getColumnSpacing();
    let sum = 0;

    return itemWidths.findIndex((width) => {
      sum += width + columnSpacing;

      return scrollPosition > -sum;
    });
  }

  function getIndexFirstInvisibleItemOnRight() {
    const columnSpacing = getColumnSpacing();
    let sum = 0;

    const index = itemWidths.findIndex((width) => {
      sum += width + columnSpacing;

      return -scrollPosition + containerWidth < sum;
    });

    return index > 0 ? index : items.length - 1;
  }

  function getOffsetForItemIndex(index) {
    const columnSpacing = getColumnSpacing();
    const widths = index > 0 ? itemWidths.slice(0, index) : [];

    return widths.reduce((partialSum, width) => partialSum + width + columnSpacing, 0);
  }

  function getIndexForOffset(offset, inclusive) {
    let width = 0;
    const index = itemWidths.findIndex((itemOffset) => {
      width += itemOffset + gridColumnSpacing;

      return inclusive ? width >= offset : width > offset;
    });

    return index === -1 ? (offset > getTotalWidthAllItems() ? items.length - 1 : 0) : index;
  }

  function isButtonDisabled(direction) {
    return (
      !containerWidth ||
      (direction === PREVIOUS && scrollPosition === 0) ||
      (direction === NEXT &&
        Math.ceil(containerWidth - scrollPosition) >= Math.floor(getTotalWidthAllItems()))
    );
  }

  function getTotalWidthAllItems() {
    const columnSpacing = getColumnSpacing();

    return (
      itemWidths.reduce((totalWidth, width) => totalWidth + width + columnSpacing, 0) -
      columnSpacing
    );
  }

  function getColumnSpacing() {
    return gridColumnSpacing;
  }

  function getnumberOfColumnsForDevice() {
    return screenSize === "small" ? 1 : numberOfColumns || (screenSize === "medium" ? 3 : 4);
  }
});

ListScroller.propTypes = {
  /** aria-label text to provide accessibility description for Banner component. */
  "aria-label": PropTypes.string,

  /**
   * Content to be displayed to indicate current page - will be followed by the current page number.
   * This should be something like "Current page is".
   */
  "aria-label-current-page": PropTypes.string.isRequired,

  /** aria-label text to provide accessibility description for previous page button. */
  "aria-label-next": PropTypes.string.isRequired,

  /**
   * Content to be displayed to indicate total number of pages - will be followed by the total
   * page count. This should be something like "Total number of pages".
   */
  "aria-label-page-count": PropTypes.string.isRequired,

  /** aria-label text to provide accessibility description for increment button. */
  "aria-label-previous": PropTypes.string.isRequired,

  /**
   * Explicitly center items in the window horizontally. Set this prop to true when the item(s) will
   * not fill the entire window's width.
   */
  center: PropTypes.bool,

  /**
   * Additional classes for positioning the component. Given classes may only position this
   * component for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Class to be added to each item's container. */
  itemClassName: listPropTypes.itemClassName,

  /** Content List to be displayed. See List component for more details. */
  items: listPropTypes.items,

  /**
   * When displaying items, the items can be laid out in a grid that's appropriately defined for
   * each device size. Only the values used for gaps between items will utilize grid values. The
   * item width will be determine by whatever space is left over. If the numberOfColumns specified
   * match the count of columns appropriate for the device, then the items will match standard grids.
   * If layout is none, all items sizes and horizontal gaps between items must be defined by the
   * calling component.
   */
  layout: PropTypes.oneOf(["grid", "none"]),

  /**
   * Number of columns. One will always be applied for small (mobile) devices. If numberOfColumns not
   * given, the number of columns will be deduced from the device size.
   */
  numberOfColumns: PropTypes.number,

  /** Callback that will be called when next or previous button is clicked. */
  onClick: PropTypes.func,

  /** Indicates if the position indicator key will be rendered. */
  showPositionIndicator: PropTypes.bool,

  /**
   * Allows the left and right buttons to be positioned manually from the top of the container.
   * if yArrowOffset is not given, the arrow buttons will be centered vertically.
   */
  yArrowOffset: PropTypes.number,
};

ListScroller.displayName = "ListScroller";
ListScroller.defaultProps = {
  center: false,
  layout: "grid",
  showPositionIndicator: true,
};

import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Button, CardGrid, Icon } from "@swa-ui/core";
import { sizes } from "@swa-ui/core/defines";
import i18n from "@swa-ui/locale";

import { MfaHeading } from "../MfaHeading";
import styles from "./MfaSuccess.module.scss";

/**
 * MfaSuccess renders a div with children.
 */
export const MfaSuccess = (props) => {
  const { name, onContinue } = props;
  const continueButtonRef = React.createRef();

  useEffect(() => {
    continueButtonRef.current.focus();
  }, []);

  return (
    <CardGrid className={styles.grid}>
      <MfaHeading
        heading={i18n("MfaSuccess__HEADING", name)}
        subheading={i18n("MfaSuccess__SUBHEADING")}
      />
      <Icon {...getSuccessIconProps()} />
      <Button {...getContinueButtonProps()}>{i18n("MfaSuccess__CONTINUE")}</Button>
    </CardGrid>
  );

  function getSuccessIconProps() {
    return {
      className: styles.field,
      color: "success-500",
      name: "Success",
      role: "presentation",
      size: sizes.size80,
    };
  }

  function getContinueButtonProps() {
    return {
      ["aria-label"]: `${i18n("MfaSuccess__HEADING", name)}
      ${i18n("MfaSuccess__SUBHEADING")}
      ${i18n("MfaSuccess__CONTINUE")}`,
      className: styles.field,
      fullWidth: true,
      onClick: onContinue,
      ref: continueButtonRef,
    };
  }
};

MfaSuccess.propTypes = {
  /**
   * The first name of the user to display in the success message.
   */
  name: PropTypes.string,

  /**
   * The function to call when the user clicks the continue button.
   */
  onContinue: PropTypes.func,
};

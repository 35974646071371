import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Dialog, Layout, useDialog } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import { MfaContext } from "../../MfaProvider";
import { MfaAuthorizer } from "../MfaAuthorizer";
import styles from "./MfaProtected.module.scss";

/**
 * MfaProtected will MFA verify the user before rendering the children.
 */
export const MfaProtected = (props) => {
  const { children, onAuthorized, onClose, restoreFocus } = props;
  const { mfaAuthenticated, mfaRequired } = useContext(MfaContext);

  const { hideDialog, isDialogVisible } = useDialog(
    "center",
    shouldShowDialog() ? "above" : "hidden",
    undefined,
    restoreFocus
  );

  return (
    <div>
      {isDialogVisible && (
        <Dialog {...getDialogProps()}>
          <Layout top="none" left="medium" right="medium">
            <MfaAuthorizer onAuthorized={handleAuthorized} onError={handleClose} />
          </Layout>
        </Dialog>
      )}
      {!isDialogVisible && children}
    </div>
  );

  function shouldShowDialog() {
    return !mfaAuthenticated && mfaRequired;
  }

  function getDialogProps() {
    return {
      "aria-label": i18n("MfaHeading__HEADING"),
      "aria-label-close": i18n("Accessibility__DIALOG_MODAL_CLOSE"),
      "aria-live": i18n("MfaHeading__SUBHEADING"),
      className: styles.dialog,
      onClose: handleClose,
      portal: false,
      revealed: isDialogVisible,
      showClose: true,
      width: "small",
    };
  }

  function handleClose() {
    hideDialog();
    onClose?.();
  }

  function handleAuthorized() {
    onAuthorized?.();
    hideDialog();
  }
};

MfaProtected.propTypes = {
  /** Content that will be rendered. */
  children: PropTypes.node.isRequired,
};

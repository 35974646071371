import { useContext, useRef } from "react";

import { isResponseOk, useFetch } from "@swa-ui/fetch";

import { AuthContext } from "../../AuthProvider";

// TODO: Replace these values with the actual values from WCM
const PING_ONE_ENVIRONMENT_ID = "b344fba3-aba7-4f8c-b78c-d74737fb1efa";
const PING_ONE_CLIENT_ID = "ec55d089-65d4-4993-b25d-dfc894370c33";

export const useMfaServices = () => {
  const { get, loading, post, response } = useFetch();
  const {
    auth: { idToken },
  } = useContext(AuthContext);
  const ids = useRef({});

  return {
    getMfaDevices,
    loading,
    resendMfaOtp,
    sendMfaOtp,
    validateMfaOtp,
  };

  async function getMfaDevices() {
    let devices = [];
    const initializeBody = await get(
      `/api/mfa/v1/mfa/${PING_ONE_ENVIRONMENT_ID}/as/authorize?client_id=${PING_ONE_CLIENT_ID}&response_type=code&response_mode=pi.flow&scope=openid`
    );

    if (isResponseOk(response)) {
      ids.current = { connectionId: initializeBody.connectionId, id: initializeBody.id };

      const devicesBody = await post(
        `/api/mfa/v1/mfa/${PING_ONE_ENVIRONMENT_ID}/davinci/connections/${ids.current.connectionId}/capabilities/customHTMLTemplate`,
        getRequestBody({ id_token: idToken })
      );

      if (isResponseOk(response)) {
        const { devices: responseDevices = [] } =
          devicesBody?.screen?.properties?.customHTML?.value?.deviceDetails;

        devices = responseDevices;
      }
    }

    return devices;
  }

  async function sendMfaOtp(deviceType) {
    await post(
      `/api/mfa/v1/mfa/${PING_ONE_ENVIRONMENT_ID}/davinci/connections/${ids.current.connectionId}/capabilities/customHTMLTemplate`,
      getRequestBody({ method: deviceType })
    );
  }

  async function resendMfaOtp() {
    await post(
      `/api/mfa/v1/mfa/${PING_ONE_ENVIRONMENT_ID}/davinci/connections/${ids.current.connectionId}/capabilities/customHTMLTemplate`,
      getRequestBody({ action: "resend" })
    );
  }

  async function validateMfaOtp(otp) {
    let isValidOtp = false;
    const validationBody = await post(
      `/api/mfa/v1/mfa/${PING_ONE_ENVIRONMENT_ID}/davinci/connections/${ids.current.connectionId}/capabilities/customHTMLTemplate`,
      getRequestBody({
        action: "verify",
        otp,
      })
    );

    if (isResponseOk(response)) {
      // This validation only works for some happy path.
      // Invalid or expired OTP responses have different response structure
      // Also, the response status code is always 200.
      // We need to look for the status code inside the response body
      isValidOtp = validationBody.httpStatusCode === 200;
    }

    return isValidOtp;
  }

  function getRequestBody(parameters) {
    return {
      eventName: "continue",
      id: ids.current.id,
      nextEvent: {
        constructType: "skEvent",
        eventName: "continue",
        eventType: "post",
        params: [],
        postProcess: {},
      },
      parameters: {
        buttonType: "form-submit",
        buttonValue: "submit",
        ...parameters,
      },
    };
  }
};

import React from "react";
import { createRoot } from "react-dom/client";
import "regenerator-runtime/runtime";

import { window } from "@swa-ui/browser";
import "@swa-ui/core/assets/styles/reset.scss";
import { ColorThemeProvider } from "@swa-ui/theme";

import { App } from "./components/App";
import "./configure/configureBootstrap";
import "./configure/configureI18n";
import "./configure/configureLogger";
import "./configure/configureTheme";

const DOCUMENT = window.document;
const header = DOCUMENT.getElementById("header") ?? DOCUMENT.getElementById("root");
const themeName = header?.attributes["data-theme"]?.value ?? "customer";
const placementParameters = header?.attributes["data-placement-params"]?.value;

createRoot(header).render(
  <ColorThemeProvider>
    <App themeName={themeName} placementParameters={placementParameters} />
  </ColorThemeProvider>
);

import PropTypes from "prop-types";
import React from "react";

import { Button, CardGrid, Icon } from "@swa-ui/core";
import { sizes } from "@swa-ui/core/defines";
import i18n from "@swa-ui/locale";

import { MfaHeading } from "../MfaHeading";
import styles from "./MfaSuccess.module.scss";

/**
 * MfaSuccess renders a div with children.
 */
export const MfaSuccess = (props) => {
  const { name, onContinue } = props;

  return (
    <CardGrid className={styles.grid}>
      <MfaHeading
        heading={i18n("MfaSuccess__HEADING", name)}
        subheading={i18n("MfaSuccess__SUBHEADING")}
      />
      <Icon className={styles.field} color="success-500" name="Success" size={sizes.size80} />
      <Button className={styles.field} fullWidth onClick={onContinue}>
        {i18n("MfaSuccess__CONTINUE")}
      </Button>
    </CardGrid>
  );
};

MfaSuccess.propTypes = {
  /**
   * The first name of the user to display in the success message.
   */
  name: PropTypes.string,

  /**
   * The function to call when the user clicks the continue button.
   */
  onContinue: PropTypes.func,
};

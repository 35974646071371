import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { fireTrack } from "@swa-ui/analytics";
import { ApplicationContext, useAppSetting } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo, window } from "@swa-ui/browser";
import { AriaLive } from "@swa-ui/core";
import { isResponseOk, useFetch } from "@swa-ui/fetch";
import { decodeMessage, isHybridEnabled, useHybrid, WEBVIEW_MESSAGE_KEYS } from "@swa-ui/hybrid";
import i18n from "@swa-ui/locale";
import { logger } from "@swa-ui/log";
import { usePersistedState } from "@swa-ui/persistence";
import { usePersonas } from "@swa-ui/personas";

import { decodeCookie, getCookie, setCookie } from "../cookie";
import { corporateRoles } from "../defines";
import {
  removeCookies,
  removeCorporateStores,
  removeMwebStores,
  removeRefreshToken,
  removeVisionStores,
} from "../logoutHandler";
import { getStoredLoginInfoSchema } from "../schema/loginSchema";

const availableLocales = ["en", "es"];

export const AuthContext = createContext();
export const AuthProvider = (props) => {
  const { children, environmentTier, site } = props;
  const {
    error: tokenExchangeError,
    loading: loadingTokenExchange,
    post: postTokenExchange,
    response: responseTokenExchange,
  } = useFetch("", (globalOptions) => {
    globalOptions.headers["Content-Type"] = "application/x-www-form-urlencoded";

    return globalOptions;
  });
  const { post: postLogin, response: responseLogin } = useFetch("", (globalOptions) => {
    globalOptions.headers["Content-Type"] = "application/x-www-form-urlencoded";

    return globalOptions;
  });
  const { isCorporatePersona } = usePersonas();
  const { post: postLogout } = useFetch();
  const { hybridEnabled, appId } = useContext(ApplicationContext);
  const { isSwaWebView } = useDeviceInfo();
  const [auth, setAuth] = usePersistedState({
    defaultValue: {},
    key: "AuthProvider-auth",
  });
  const [storedLoginInfo, setStoredLoginInfo] = usePersistedState({
    defaultValue: {
      rememberMe: false,
      username: "",
    },
    key: "storedLoginInfo",
    persistDefault: true,
    schema: getStoredLoginInfoSchema(),
    storageType: "local",
  });
  const [ariaLiveMessage, setAriaLiveMessage] = useState("");
  const logoutHandlersRef = useRef({});
  const nativeLoginCancelRef = useRef({});
  const nativeLoginHandlersRef = useRef({});
  const preLogoutHandlersRef = useRef({});
  const additionalMessagesFromNativeApp = {
    [WEBVIEW_MESSAGE_KEYS.AUTH_EVENT]: handleAuthEventWebViewMessage,
    [WEBVIEW_MESSAGE_KEYS.ADD_OAUTH]: handleAddOauthWebViewMessage,
    [WEBVIEW_MESSAGE_KEYS.REMOVE_OAUTH]: handleRemoveOauthWebViewMessage,
  };
  const history = useHistory();
  const isMobileSite = site === "mobile";
  const loginAvailable = useAppSetting("loginAvailable", false);
  const loginSettings = getBootstrapData("login-settings");
  const tokenExchangeInProgress = useRef(false);

  hybridEnabled &&
    isHybridEnabled() &&
    useHybrid({ additionalMessagesFromNativeApp, appId, history });

  useEffect(() => {
    const idToken = getCookie("id_token");
    const tokenCookie = getCookie("token");

    shouldHandleTokenExchange() && handleTokenExchangeAndMarkInProgress(tokenCookie);
    loginAvailable && !!auth.id_token && !getCookie("id_token") && cleanUpSession();
    shouldSetAuth() && setAuth({ idToken: idToken });
    setCookie("locale", getCurrentLocale());
  }, []);

  useEffect(() => {
    if (loadingTokenExchange && !tokenExchangeInProgress.current) {
      tokenExchangeInProgress.current = loadingTokenExchange;
    }
  }, [loadingTokenExchange]);

  return (
    <AuthContext.Provider value={getAuthContextValue()}>
      {ariaLiveMessage && <AriaLive>{ariaLiveMessage}</AriaLive>}
      {shouldRenderChildren() && children}
    </AuthContext.Provider>
  );

  function shouldRenderChildren() {
    return (!shouldExchangeToken() && !tokenExchangeInProgress.current) || tokenExchangeError;
  }

  function getAuthContextValue() {
    return {
      appendLogoutHandlers,
      appendNativeLoginCancelHandlers,
      appendNativeLoginHandlers,
      appendPreLogoutHandlers,
      auth,
      authPending: tokenExchangeInProgress.current,
      cleanUpSession,
      handleLogin: loginAvailable ? handleLogin : warn,
      handleLogout: loginAvailable ? handleLogout : warn,
      handleTokenExchange,
      isLimitedAccess,
      isLoggedIn,
      isLoggedInWithCorporateRole,
      storedLoginInfo,
    };
  }

  function handleAddOauthWebViewMessage() {
    const idToken = getCookie("id_token");

    if (idToken) {
      setAuth({ idToken });
      Object.values(nativeLoginHandlersRef.current).forEach((handleNativeLoginCallback) => {
        handleNativeLoginCallback();
      });
      nativeLoginHandlersRef.current = {};
    }
  }

  function handleAuthEventWebViewMessage(value) {
    const { type } = decodeMessage(value);

    if (type === "USER_CANCEL") {
      Object.values(nativeLoginCancelRef.current).forEach((handleNativeLoginCancelCallback) => {
        handleNativeLoginCancelCallback();
      });
      nativeLoginCancelRef.current = {};
    }
  }

  function handleRemoveOauthWebViewMessage() {
    cleanUpSession();
  }

  function warn() {
    logger.warn("should not use AuthContext when loginAvailable is false");
  }

  function appendLogoutHandlers(logoutHandler, logoutHandlerKey) {
    const key = logoutHandlerKey || `${logoutHandler.name}-${getRandomString()}`;

    logoutHandlersRef.current = { ...logoutHandlersRef.current, [key]: logoutHandler };
  }

  function appendNativeLoginHandlers(nativeLoginHandler, nativeLoginHandlerKey) {
    const key = nativeLoginHandlerKey || `${nativeLoginHandler.name}-${getRandomString()}`;

    nativeLoginHandlersRef.current = {
      ...nativeLoginHandlersRef.current,
      [key]: nativeLoginHandler,
    };
  }

  function appendNativeLoginCancelHandlers(nativeLoginCancelHandler, nativeLoginCancelHandlerKey) {
    const key =
      nativeLoginCancelHandlerKey || `${nativeLoginCancelHandler.name}-${getRandomString()}`;

    nativeLoginCancelRef.current = {
      ...nativeLoginCancelRef.current,
      [key]: nativeLoginCancelHandler,
    };
  }

  function appendPreLogoutHandlers(preLogoutHandler, preLogoutHandlerKey) {
    const key = preLogoutHandlerKey || `${preLogoutHandler.name}-${getRandomString()}`;

    preLogoutHandlersRef.current = { ...preLogoutHandlersRef.current, [key]: preLogoutHandler };
  }

  function getRandomString() {
    return Math.random().toString(36).substring(2, 7);
  }

  function handleTokenExchangeAndMarkInProgress(token) {
    tokenExchangeInProgress.current = true;
    handleTokenExchange(token);
  }

  async function handleTokenExchange(token) {
    const urlEncodedParams = new URLSearchParams();

    urlEncodedParams.append("assertion", token);
    urlEncodedParams.append("grant_type", loginSettings?.grantType);
    urlEncodedParams.append("scope", loginSettings?.scope);
    urlEncodedParams.append("client_id", loginSettings?.clientIds?.[environmentTier]);
    isCorporatePersona && urlEncodedParams.append("company_id", getCompanyIdFromToken());

    const body = await postTokenExchange("/api/security/v4/security/token", urlEncodedParams);

    if (isResponseOk(responseTokenExchange)) {
      removeStores();
      setAuth({ ...body, idToken: getCookie("id_token") });
    } else {
      cleanUpSession();
    }

    tokenExchangeInProgress.current = false;
  }

  function getCompanyIdFromToken() {
    const corporateTokenCookie = getCookie("corporateToken");
    const decodedToken = decodeCookie(corporateTokenCookie);

    return decodedToken?.companyId;
  }

  function getCorporateRole() {
    return getCorporateUserInformation()?.role;
  }

  function getCorporateUserInformation() {
    const idToken = getCookie("id_token");

    return decodeCookie(idToken)?.apiContext?.corporateUserInformation ?? {};
  }

  function getUrlEncodedFormData(formData, username, companyId) {
    const urlEncodedFormData = new URLSearchParams();

    urlEncodedFormData.append("username", username);
    urlEncodedFormData.append("password", formData?.password);
    urlEncodedFormData.append("scope", loginSettings?.scope);
    urlEncodedFormData.append("client_id", loginSettings?.clientIds?.[environmentTier]);
    urlEncodedFormData.append("response_type", loginSettings?.responseType);
    isCorporatePersona && urlEncodedFormData.append("company_id", companyId);

    return urlEncodedFormData;
  }

  async function handleLogin(formData, defaultUsername, defaultCompanyId) {
    const { companyId = defaultCompanyId, username = defaultUsername, rememberMe } = formData;
    const urlEncodedFormData = getUrlEncodedFormData(formData, username, companyId);
    const body = await postLogin("/api/security/v4/security/token", urlEncodedFormData);

    if (isResponseOk(responseLogin)) {
      const hasLimitedAccessToken =
        body?.["customers.userInformation.missingInformation"]?.length > 0 ||
        body?.["corporate.corporateUserInformation.missingInformation"]?.length > 0;

      setAuth({ ...body, idToken: getCookie("id_token") });
      setAriaLiveMessage(i18n("AuthProvider__LOGIN_SUCCESSFUL_ARIA"));
      fireTrack("squid", { page_description: "button:login" });
      setStoredLoginInfo({
        rememberMe,
        username: rememberMe ? username : "",
      });
      hasLimitedAccessToken && handleLoginLimitedAccessToken(body);
    }

    return { body, responseLogin };
  }

  function handleLoginLimitedAccessToken(body) {
    setAuth({ ...body, limitedAccess: true });
    removeCookies(isCorporatePersona);
    removeRefreshToken();
  }

  async function handleLogout() {
    handlePreLogoutCalls();
    await postLogout("/api/security/v4/security/logout");
    cleanUpSession();
    setAriaLiveMessage(i18n("AuthProvider__LOGOUT_SUCCESSFUL_ARIA"));
  }

  function handlePreLogoutCalls() {
    Object.values(preLogoutHandlersRef.current).forEach((handlePreLogoutCallback) => {
      handlePreLogoutCallback();
    });
  }

  function cleanUpSession() {
    setAuth({});
    Object.values(logoutHandlersRef.current).forEach((handleLogoutCallback) => {
      handleLogoutCallback();
    });
    removeStores();
    removeCookies(isCorporatePersona);
  }

  function removeStores() {
    if (isCorporatePersona) {
      removeCorporateStores();
    } else if (isMobileSite) {
      removeMwebStores();
    } else {
      removeVisionStores();
    }
  }

  function shouldHandleTokenExchange() {
    return shouldExchangeToken() && !tokenExchangeInProgress.current;
  }

  function shouldExchangeToken() {
    const leapfrogToken = getCookie("token");

    return !isLoggedIn() && !!leapfrogToken && hasLocaleChanged() && !isHybridEnabled();
  }

  // Is a Siebel account logged in (Rapid Rewards member, account or Swabiz traveler)
  function isLoggedIn() {
    return isCorporatePersona
      ? isLoggedInWithCorporateRole(corporateRoles.TRAVELER)
      : isAuthorized();
  }

  function isLoggedInWithCorporateRole(role) {
    return isAuthorized() && (isCorporatePersona ? getCorporateRole() === role : false);
  }

  function isAuthorized() {
    return (
      loginAvailable &&
      !!getCookie("id_token") &&
      !!auth.idToken &&
      (!isSwaWebView || isHybridEnabled())
    );
  }

  function isLimitedAccess() {
    return loginAvailable && !!auth.limitedAccess;
  }

  function shouldSetAuth() {
    return !!getCookie("id_token") && !auth.idToken;
  }

  function hasLocaleChanged() {
    const previousLocale = getCookie("locale");

    return previousLocale && previousLocale !== getCurrentLocale();
  }

  function getCurrentLocale() {
    const currentLocale = window.MP?.UrlLang;

    return availableLocales.includes(currentLocale) ? currentLocale : availableLocales[0];
  }
};

AuthContext.displayName = "AuthContext";
AuthProvider.propTypes = {
  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,
};
